import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import { ITheme } from "../../theme"
import NewsArticle from "./NewsArticle"
import NewsView from "./NewsView"
import CLOW from "./CLOW"


const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const News: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <NewsArticle />
        </Grid>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          <NewsView />
        </Grid>
      </Grid>
    </div>
  )
}

export default News
