import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import { CSVLink } from 'react-csv'
import SearchBar from 'material-ui-search-bar'
import TextField from '@material-ui/core/TextField'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  exportArray: any[]
  refresh: number
  Total: number
  leaderItem: any[]
  displayLeader: boolean
  starttime: string
  endtime: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const AllTransactionsTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    exportArray: [],
    refresh: 0,
    Total: 0,
    leaderItem: [],
    displayLeader: false,
    starttime: '',
    endtime: ''
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  const yesterdate = new Date()

  yesterdate.setDate(yesterdate.getDate() - 3)

  const threeDaysAgo =
    yesterdate.getFullYear() +
    '-' +
    (yesterdate.getMonth() + 1 > 9
      ? yesterdate.getMonth() + 1
      : '0' + (yesterdate.getMonth() + 1)) +
    '-' +
    (yesterdate.getDate() > 9
      ? yesterdate.getDate()
      : '0' + yesterdate.getDate()) +
    ' T ' +
    (yesterdate.getHours() > 9
      ? yesterdate.getHours()
      : '0' + yesterdate.getHours()) +
    ':' +
    (yesterdate.getMinutes() > 9
      ? yesterdate.getMinutes()
      : '0' + yesterdate.getMinutes())

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('Ledger')
        .where('Date', '<', threeDaysAgo)
        .where('Date', '>=', formState.starttime)
        .where('Date', '<=', formState.endtime)
        .where('Paid', '==', false)
        .orderBy('Date', 'asc')
        .get()

      const list: any = []

      let total = 0

      data.forEach(doc => {
        const { CustomerID, Date, LeaderID, OrderID, Paid, Total } = doc.data()
        list.push({
          key: doc.id,
          CustomerID: CustomerID,
          Date: Date,
          LeaderID: LeaderID,
          OrderID: OrderID,
          Paid: Paid,
          Total: Total,
        })

        total = total + Total
      })

      setFormState({
        ...formState,
        array: list,
        Total: total,
      })
      setIsloading(false)
    }

    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let leaderIdSearch: any = row.LeaderID
        let customerIdSearch: any = row.CustomerID
        let orderIdSearch: any = row.OrderID
        let clearedDateSearch: any = row.date

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(leaderIdSearch) ||
          expression.test(customerIdSearch) ||
          expression.test(orderIdSearch) ||
          expression.test(clearedDateSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleDisplayLeader = (e, UID) => {
    let list: any = []
    firestore
      .collection('CommunityLeaders')
      .doc(UID)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          const { Name, PhoneNumber } = doc.data()

          list.push({
            UID: UID,
            Name: Name,
            PhoneNumber: PhoneNumber,
          })
        }
      })
      .then(() => {
        setFormState({
          ...formState,
          leaderItem: list,
          displayLeader: true,
        })
      })
  }

  const DialogLeader = () => {
    const handleCloseCustomer = () => {
      setFormState({
        ...formState,
        leaderItem: [],
        displayLeader: false,
      })
    }

    return (
      <Dialog
        open={formState.displayLeader}
        onClose={handleCloseCustomer}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <h1>Customer Info</h1>
        </DialogTitle>
        <DialogContent>
          {formState.leaderItem.map(data => (
            <ul>
              <li>
                <b>CLID: </b> {data.UID}
              </li>
              <li>
                <b>Name: </b> {data.Name}
              </li>
              <li>
                <b>Phone Number: </b> {data.PhoneNumber}
              </li>
            </ul>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomer} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'starttime') {
      // setFormState({
      //   ...formState,
      //   starttime: e.target.value
      // })

      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const headers = [
    { label: 'LedgerID', key: 'key' },
    { label: 'CustomerID', key: 'CustomerID' },
    { label: 'LeaderID', key: 'LeaderID' },
    { label: 'OrderID', key: 'OrderID' },
    { label: 'Amount', key: 'Total' },
    { label: 'Cleared', key: 'Cleared' },
    { label: 'Date', key: 'Date' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'DefaultLedgers(' + today + ').csv',
  }

  return (
    <div className={classes.root}>
      {DialogLeader()}
      <Card className={classes.box}>
        <CardContent>
          <h1 className={classes.h1}>
            DEFAULT LEDGERS: {formState.array.length}
          </h1>
          <div className={classes.row}>
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='starttime'
              label='Start Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='endtime'
              label='End Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>
          <br />
          <hr />
          <br />
          <div className={classes.row}>
            <h1 className={classes.h1}>TOTAL: {formState.Total}</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <div style={{ float: 'right' }}>
            <Button
              color='primary'
              variant='contained'
              className={classes.refresh}
            >
              <CSVLink onClick={handleRefresh} {...csvReport}>
                Export to CSV
              </CSVLink>
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '3em' }}
          />

          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Ledger ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Order ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Amount</b>
                  </TableCell>
                  <TableCell>
                    <b>Paid</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.CustomerID}</TableCell>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e => handleDisplayLeader(e, data.LeaderID)}
                          >
                            {data.LeaderID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.OrderID}</TableCell>
                        <TableCell>{data.Total}</TableCell>
                        <TableCell>
                          <Switch checked={data.Paid} color='primary' />
                        </TableCell>
                        <TableCell>{data.Date}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default AllTransactionsTable
