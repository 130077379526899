import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import { CSVLink } from 'react-csv'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  cartID: string
  totalItems: number
  cartItems: any[]
  displayCart: boolean
  grandTotal: string
  CodeIDSearch: string
  starttime: string
  endtime: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginLeft: 'auto',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const AllPayOutsTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [isLoading, setIsloading] = useState<boolean>(true)

  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    totalItems: 0,
    cartID: '',
    cartItems: [],
    displayCart: false,
    grandTotal: '',
    CodeIDSearch: '',
    starttime: '',
    endtime: '',
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('PayOuts')
        .where('DateCreated', '>=', formState.starttime)
        .where('DateCreated', '<=', formState.endtime)
        .orderBy('DateCreated', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          OrderID,
          CartID,
          LeaderID,
          LeaderName,
          TotalAmount,
          TotalCommission,
          Type,
          POD,
          Bonus,
          Processed,
          DateCreated,
          DateUpdated
        } = doc.data()

        list.push({
          key: doc.id,
          OrderID,
          CartID,
          LeaderID,
          LeaderName,
          TotalAmount,
          TotalCommission,
          Type,
          POD: POD ? "True" : "False",
          Bonus: Bonus ? "True" : "False",
          Processed: Processed ? "True" : "False",
          DateCreated,
          DateUpdated
        })
      })

      setFormState({
        ...formState,
        array: list,
        totalItems: list.length,
      })
      setIsloading(false)
    }
    fetchData()
  }, [formState.refresh])

  const handleRefresh = () => {
    setFormState({
      ...formState,
      // totalItems: 0,
      refresh: formState.refresh + 1,
    })
  }

  const handleChangeProcessed = e => {
    e.preventDefault()

    const vatRef = firestore.collection('VAT').doc(e.target.name)

    vatRef
      .update({
        Processed: e.target.checked,
      })
      .then(() => {
        alert('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const handleChange = (e: any) => {
    const date = new Date(e.target.value)

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    if (e.target.name === 'starttime') {
      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const headers = [
    { label: 'PayOut ID', key: 'key' },
    { label: 'Order ID', key: 'OrderID' },
    { label: 'Cart ID', key: 'CartID' },
    { label: 'Leader ID', key: 'LeaderID' },
    { label: 'LeaderName', key: 'LeaderName' },
    { label: 'TotalAmount', key: 'TotalAmount' },
    { label: 'TotalCommission', key: 'TotalCommission' },
    { label: 'Type', key: 'Type' },
    { label: 'POD', key: 'POD' },
    { label: 'Bonus', key: 'Bonus' },
    { label: 'Processed', key: 'Processed' },
    { label: 'DateCreated', key: 'DateCreated' },
    { label: 'DateUpdated', key: 'DateUpdated' },

  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'PayOutList(' + formState.starttime + ' - ' + formState.endtime + ').csv',
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>PayOuts</h1>
          </div>
          <div className={classes.row}>
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='starttime'
              label='Start Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='endtime'
              label='End Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>
          <div className={classes.row}>
            <Button
              color='primary'
              variant='contained'
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>PayOut ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Order ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Cart ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Total Amount</b>
                  </TableCell>
                  <TableCell>
                    <b>Total Commission</b>
                  </TableCell>
                  <TableCell>
                    <b>Type</b>
                  </TableCell>
                  <TableCell>
                    <b>POD</b>
                  </TableCell>
                  <TableCell>
                    <b>Bonus</b>
                  </TableCell>
                  <TableCell>
                    <b>Processed</b>
                  </TableCell>
                  <TableCell>
                    <b>DateCreated</b>
                  </TableCell>
                  <TableCell>
                    <b>DateUpdated</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.OrderID}</TableCell>
                        <TableCell>{data.CartID}</TableCell>
                        <TableCell>{data.LeaderID}</TableCell>
                        <TableCell>{data.LeaderName}</TableCell>
                        <TableCell>{data.TotalAmount}</TableCell>
                        <TableCell>{data.TotalCommission}</TableCell>
                        <TableCell>{data.Type}</TableCell>
                        <TableCell>{data.POD}</TableCell>
                        <TableCell>{data.Bonus}</TableCell>
                        <TableCell>{data.Processed}</TableCell>
                        <TableCell>{data.DateCreated}</TableCell>
                        <TableCell>{data.DateUpdated}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default AllPayOutsTable
