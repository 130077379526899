import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ITheme } from '../../theme'
import TextField from '@material-ui/core/TextField'
import FirebaseApp from '../../firebase/firebase'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'

interface IProps {
  className?: string
}

export interface IFormState {
  message: string
  phoneNumbersArray: any[]
  price: number
  maxOrders: number
  starttime: string
  endtime: string
  loading: boolean
  totalCustomers: number,
  totalCustomersArray: any[]
  zoneDropDown: any[]
  zoneIDSelect: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  details: {
    display: 'flex'
  },
  refresh: {
    marginLeft: 'auto',
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textarea: {
    width: '100%',
  },
  spacer: {
    flexGrow: 1,
  },
  button: {
    padding: '20px',
  },
}))

const CLOW: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles()

  const initState = {
    message: "",
    phoneNumbersArray: [],
    price: 0,
    starttime: '',
    endtime: '',
    maxOrders: 0,
    loading: false,
    totalCustomers: 0,
    totalCustomersArray: [],
    zoneDropDown: [],
    zoneIDSelect: "Z0"

  }

  const firebase = FirebaseApp
  const firestore = firebase.firestore()

  const [url, setUrl] = useState<string>('')
  const [btnMessage, setBtnMessage] = useState('UPLOAD CSV')
  const [randomId, setRandomId] = useState<string>('CSV')
  const [message, setMessage] = useState<string>('')
  const [pending, setPending] = useState<boolean>(false)
  const [values, setValues] = useState<IFormState>(initState)

  const storage = firebase.storage()
  let image: File

  useEffect(() => {

    const fetchZoneDropDown = async () => {

      const data = await firestore.collection("Zones").get()

      const dropdownList: any = []

      dropdownList.push(<MenuItem value={"Z0"}>ALL ZONES</MenuItem>)


      data.forEach((doc) => {

        const { Areas } = doc.data()

        dropdownList.push(<MenuItem value={doc.id}>{doc.id} : {Areas}</MenuItem>)


      })

      setValues({
        ...values,
        zoneDropDown: dropdownList
      })

    }

    fetchZoneDropDown()


  }, [])

  const handleUploadCsv = () => {
    setBtnMessage('UPLOADING, KINDLY WAIT ....')
    setRandomId(`CSV${Math.floor(Math.random() * 10000)}`)
    const Reference = storage.ref(`csv/${randomId}/${image.name}`)

    let csvUrl = ""
    Reference.put(image)
      .then(snapshot => {
        Reference.getDownloadURL().then(url => {
          const urlString = url.toString()
          setUrl(urlString)
          csvUrl = urlString
        })
          .then(() => {

            try {


              console.log('you clicked me')

              const url = 'https://api.tushop.io/api/v1/sms/readCsv'

              fetch(url, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  url: csvUrl
                }),
              })
                .then((response: any) => {
                  if (response.status === 200 || response.status === 500) {
                    return response.json()
                  } else {
                    alert(response.message)
                    throw new Error('Something went wrong on api server!')
                  }
                })

                .then(data => {
                  if (data.status === 'SUCCESS') {
                    setPending(false)

                    setValues({
                      ...values,
                      phoneNumbersArray: data.customers,
                      totalCustomers: data.customers.length
                    })
                    alert(`${data.customers.length} customers numbers uploaded`)
                    setBtnMessage(`(${data.customers.length}) UPLOAD SUCCESSFUL`)
                    return
                  } else {
                    setPending(false)
                    alert(data.message ?? data.error)
                  }
                })


            } catch (error) {
              if (error instanceof Error) {
                setPending(false)
                console.log(error.message)
                alert(error.message)
              } else {
                console.log('Unexpected erroror', error)
                setPending(false)
                alert('Unexpected erroror')
              }
            }
          }).catch(error => {
            setBtnMessage('CSV UPLOAD FAILED')
          })
      })

  }

  const handleSendSMS = () => {

    setPending(true)

    const url = 'https://api.tushop.io/api/v1/sms/Send'

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: values.phoneNumbersArray,
        message: values.message
      }),
    })
      .then((response: any) => {
        if (response.status === 201 || response.status === 500) {
          return response.json()
        } else {
          alert(response.message)
          throw new Error('Something went wrong on api server!')
        }
      })

      .then(response => {

        setPending(false)
        if (response.status === "Success") {
          alert("Messages sent successfully")
          setValues(initState)
        }
      }).catch((error) => {

        setPending(false)
        console.log(error)
      })

  }




  const handleChange = (e: any) => {

    const date = new Date(e.target.value)

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    console.log("object", e.target.name)

    if (e.target.name === "messageTemplate") {

      let messages = 0

      let result = 0

      if (e.target.value.length > 160) {



        messages = Math.floor(e.target.value.length / 160)

        const totalMessages = messages + 1

        console.log(totalMessages)
        result = totalMessages * 0.8

      } else {
        result = 0.8
      }

      setValues({
        ...values,
        message: e.target.value,
        price: result
      })

    } else if (e.target.name === 'starttime') {
      setValues({
        ...values,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      setValues({
        ...values,
        endtime: today,
      })
    } else if (e.target.name === "maxOrders") {
      setValues({
        ...values,
        maxOrders: parseInt(e.target.value),
      })
    } else if (e.target.name === "zone") {

      setValues({
        ...values,
        zoneIDSelect: e.target.value
      })

    }

  }

  const handleCheck = (e: any) => {

    let array: any = []
    let array2: any = []

    setValues({
      ...values,
      loading: true
    })

    firestore.collection("Orders")
      .where("Delivered", "==", true)
      .where('DateOfOrder', ">=", values.starttime)
      .where('DateOfOrder', "<", values.endtime)
      .get()
      .then((data) => {

        data.forEach((doc) => {
          const { CustomerPNo, Zone } = doc.data()

          if (values.zoneIDSelect === "Z0") {

            if (array[CustomerPNo] === undefined) {
              array[CustomerPNo] = 1
            } else {
              array[CustomerPNo] = array[CustomerPNo] + 1
            }

          } else {
            if (Zone === values.zoneIDSelect) {

              if (array[CustomerPNo] === undefined) {
                array[CustomerPNo] = 1
              } else {
                array[CustomerPNo] = array[CustomerPNo] + 1
              }

            }
          }


        })
      }).then(() => {

        console.log("values.zoneID", values.zoneIDSelect)


        for (var key in array) {

          if (array[key] <= values.maxOrders) {

            array2.push(`+${key}`)
          }

        }

      }).then(() => {

        setValues({
          ...values,
          totalCustomersArray: array2,
          totalCustomers: array2.length,
          loading: false
        })


      })

  }

  const handleSend = (e) => {

    const url = 'https://api.tushop.io/api/v1/sms/Send'

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: values.totalCustomersArray,
        message: values.message
      }),
    })
      .then((response: any) => {
        if (response.status === 201 || response.status === 500) {
          return response.json()
        } else {
          alert(response.message)
          throw new Error('Something went wrong on api server!')
        }
      })

      .then(response => {
        alert(response.message)

        if (response.status === "SUCCESS") {
          setValues(initState)
        }
      })

  }


  return (
    <>
      <Card style={{ marginBottom: '1em' }}>
        <CardContent>
          <h1>Burst Out SMSs</h1>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <h3>Burst Out SMSs with CSV upload</h3>
          <p style={{ fontSize: '12px' }}><span style={{ color: 'red' }}>*Upload should be in csv format and contain phone numbers only</span></p>
          <p style={{ fontSize: '12px' }}><span style={{ color: 'red' }}>* Numbers should start in 254 format</span></p>

          <input
            type="file"
            className={classes.button}
            onChange={e => {
              if (!e.target.files) return
              image = e.target.files[0]

            }}
            name="url"

          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadCsv}
          >
            <span> {btnMessage}</span>
          </Button>
          <h2>Please enter message template u wish to send out</h2>


          <TextareaAutosize
            aria-label='empty textarea'
            name='messageTemplate'
            id='description'
            className={classes.textarea}
            rowsMin={6}
            onChange={(e) => handleChange(e)}
            placeholder='Input SMS template'
            value={values.message}
          />
        </CardContent>
        <Divider />
        {/* <div className={classes.row}>
          <TextField
            id='datetime-local'
            onChange={handleChange}
            name='starttime'
            label='Start Date/Time'
            type='datetime-local'
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={classes.spacer} />
          <TextField
            id='datetime-local'
            onChange={handleChange}
            name='endtime'
            label='End Date/Time'
            type='datetime-local'
            InputLabelProps={{
              shrink: true,
            }}
          />
          <span className={classes.spacer} />
          <TextField
            autoFocus
            margin='dense'
            name='maxOrders'
            id='maxOrder'
            label='Max Orders'
            type='number'
            onChange={handleChange}
            value={values.maxOrders}
          />
          <span className={classes.spacer} />
          <FormControl>
            <InputLabel id="Zonedropdown">Zone</InputLabel>
            <Select
              labelId="Zonedropdown"
              name="zone"
              onChange={handleChange}
              value={values.zoneIDSelect}
            >
              {values.zoneDropDown}
            </Select>
          </FormControl>
          <span className={classes.spacer} />
          <Button
            color='primary'
            variant='contained'
            onClick={e => handleCheck(e)}
            className={classes.refresh}
          >
            FILTER
          </Button>
        </div> */}
        <Divider />
        {/* <p><b>Price per SMS: </b> {values.price}</p>
        {values.loading ?
          <CircularProgress />
          :
          <>
            <p><b>Total Number of customers: </b>{values.totalCustomers}</p>
            <p><b>Total cost: </b> KES {Math.round(values.totalCustomers * values.price)} </p>
          </>
        }
        <Divider /> */}
        <CardActions>
          <span className={classes.spacer} />
          <Button
            onClick={() => handleSendSMS()}
            variant="contained"
            color="primary"
            disabled={pending}
          >
            Send to all customers
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default CLOW
