import { AppBar, Button, Theme, Toolbar, withStyles } from '@material-ui/core'
import TimelineIcon from '@material-ui/icons/Timeline'
import { makeStyles } from '@material-ui/styles'
import { Link as RouterLink } from '@reach/router'
import clsx from 'clsx'
import React from 'react'
import { Auth } from '../../App'
import { ITheme } from '../../theme'

interface IProps {
  className?: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    boxShadow: 'none',
  },
  button: {
    margin: theme.spacing(1),
  },
}))

const Logo = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
    },
  },
}))(Button)

const Topbar: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color='primary'
      position='fixed'
    >
      <Toolbar>
        <RouterLink to='/'>
          ={' '}
          <img
            src='https://firebasestorage.googleapis.com/v0/b/tushop-shopping-app.appspot.com/o/logo.png?alt=media&token=09e40cd0-0d70-4bad-8b21-6d80e8afa83d'
            style={{ width: '10%' }}
          />
          ={' '}
        </RouterLink>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
