import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Switch from '@material-ui/core/Switch'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { CSVLink } from 'react-csv'
import usePlacesAutocomplete from 'use-places-autocomplete'

import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  starttime: string
  endtime: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const DashboardTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete()

  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    starttime: '',
    endtime: '',
  })

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const array: any = []

      const url = 'https://api.tushop.io/api/v1/orderSummary'

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startTime: formState.starttime,
          endTime: formState.endtime,
        }),
      })
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            console.log('first')
          }
        })
        .then(response => {
          console.debug(response.data)


          response.data.forEach((data) => {

            const total = data.data.Ecommerce + data.data.USSD + data.data.Whatsapp
            array.push({
              week: data.week,
              Ecommerce: data.data.Ecommerce,
              USSD: data.data.USSD,
              WhatsApp: data.data.Whatsapp,
              EcommercePerc: Math.round(((data.data.Ecommerce / total) * 100) *100) / 100,
              USSDPerc: Math.round(((data.data.USSD / total) * 100) *100) / 100,
              WhatsAppPerc: Math.round(((data.data.Whatsapp / total) * 100) * 100) / 100
            })
          })

        })
        .then(() => {
          setFormState({
            ...formState,
            array: array,
          })
          setIsloading(false)
        })
        .catch(error => {
          console.error(error)
          setIsloading(false)
        })
    }

    //call the fetching functions
    if (formState.starttime === '' && formState.endtime === '') {
      setIsloading(false)
    } else {
      fetchData()
    }
  }, [formState.refresh])

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const handleChange = (e: any) => {
    const date = new Date(e.target.value)

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    if (e.target.name === 'starttime') {
      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }
  const headers = [
    { label: 'Week No', key: 'key' },
    { label: 'Email', key: 'email' },
    { label: 'Name', key: 'name' },
    { label: 'PhoneNumber', key: 'phoneNumber' },
    { label: 'Active', key: 'active' },
    { label: 'Url', key: 'url' },
    { label: 'Zone', key: 'zone' },
    { label: 'Signed up by?', key: 'signed' },
    { label: 'Date', key: 'date' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'CommunityLeaders.csv',
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>Order Platforms Summary</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            {/* <Button
              color='primary'
              variant='contained'
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button> */}
          </div>
          <div className={classes.row}>
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='starttime'
              label='Start Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='endtime'
              label='End Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>
          <br/>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Week</b>
                  </TableCell>
                  <TableCell>
                    <b>Ecommerce</b>
                  </TableCell>
                  <TableCell>
                    <b>WhatsApp</b>
                  </TableCell>
                  <TableCell>
                    <b>USSD</b>
                  </TableCell>
                  <TableCell>
                    <b>Ecommerce(%)</b>
                  </TableCell>
                  <TableCell>
                    <b>Whatsapp(%)</b>
                  </TableCell>
                  <TableCell>
                    <b>USSD(%)</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.week}>
                        <TableCell>{data.week}</TableCell>
                        <TableCell>{data.Ecommerce}</TableCell>
                        <TableCell>{data.WhatsApp}</TableCell>
                        <TableCell>{data.USSD}</TableCell>
                        <TableCell>{data.EcommercePerc}</TableCell>
                        <TableCell>{data.WhatsAppPerc}</TableCell>
                        <TableCell>{data.USSDPerc}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default DashboardTable
