import { ThemeProvider } from '@material-ui/styles'
import { Redirect, Router } from '@reach/router'
import React, { ReactNode } from 'react'
import { createContainer } from 'unstated-next'
import validate from 'validate.js'
import validators from '../utils/validators'
import './App.css'
import {
  News,
  Dashboard,
  Orders,
  CompletedOrders,
  CancelledOrders,
  NotFound,
  ProductList,
  RouteWithLayout,
  Settings,
  SignIn,
  SignOut,
  SignUp,
  CommunityLeaders,
  AllTransactions,
  UserList,
  ReferralList,
  CLReferrals,
  LeaderBoard,
  bidRequest,
  Ledger,
  AllLedgers,
  DefaultLedgers,
  Feedback,
  CLFeedback,
  CLOFeedback,
  SuccessfulTransactions,
  FailedTransactions,
  CreateCart,
  ProcessedInvoice,
  UnprocessedInvoice,
  GroupBuy,
  GroupBuyQueue,
  Farmers,
  Commissions,
  AllCommissions,
  SourcingList,
  VAT,
  NewActiveUsers,
  StockOuts,
  StockControl,
  AllPayOuts,
  BurstSMS,
  PopUp,
  Bundles,
} from './components'
import useAuth from './hooks/useAuth'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'
import theme from './theme'
import { PATHS } from './paths'
import AdminLogs from './components/AdminLogs'
import Blogs from './components/Blogs'

export const Auth = createContainer(useAuth)

// Chart.controllers.bar = Chart.controllers.bar.extend(chartjs);

validate.validators = {
  ...validate.validators,
  ...validators,
}

const RouterComponent = ({ children }: React.PropsWithChildren<ReactNode>) => {
  return <>{children}</>
}

const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <Auth.Provider>
        <Router primary={false} component={RouterComponent}>
          <Redirect from={PATHS.ROOT} to={PATHS.DASHBOARD} noThrow />
          <RouteWithLayout
            path={PATHS.DASHBOARD}
            layout={MainLayout}
            component={Dashboard}
          />
          <RouteWithLayout
            path={PATHS.USERS}
            layout={MainLayout}
            component={UserList}
          />
          <RouteWithLayout
            path={PATHS.REFERRALS}
            layout={MainLayout}
            component={ReferralList}
          />
          <RouteWithLayout
            path={PATHS.CLREFERRALS}
            layout={MainLayout}
            component={CLReferrals}
          />
          <RouteWithLayout
            path={PATHS.NEWACTIVE}
            layout={MainLayout}
            component={NewActiveUsers}
          />
          <RouteWithLayout
            path={PATHS.PRODUCTS}
            layout={MainLayout}
            component={ProductList}
          />
          <RouteWithLayout
            path={PATHS.BUNDLES}
            layout={MainLayout}
            component={Bundles}
          />
          <RouteWithLayout
            path={PATHS.GROUPBUY}
            layout={MainLayout}
            component={GroupBuy}
          />
          <RouteWithLayout
            path={PATHS.GROUPBUYQUEUE}
            layout={MainLayout}
            component={GroupBuyQueue}
          />
          <RouteWithLayout
            path={PATHS.COMMUNITYLEADERS}
            layout={MainLayout}
            component={CommunityLeaders}
          />
          <RouteWithLayout
            path={PATHS.FARMERS}
            layout={MainLayout}
            component={Farmers}
          />
          <RouteWithLayout
            path={PATHS.CANCELLEDORDERS}
            layout={MainLayout}
            component={CancelledOrders}
          />
          <RouteWithLayout
            path={PATHS.SOURCINGLIST}
            layout={MainLayout}
            component={SourcingList}
          />
          <RouteWithLayout
            path={PATHS.ORDERS}
            layout={MainLayout}
            component={Orders}
          />
          <RouteWithLayout
            path={PATHS.ALLTRANSACTIONS}
            layout={MainLayout}
            component={AllTransactions}
          />
          <RouteWithLayout
            path={PATHS.COMPLETEDORDERS}
            layout={MainLayout}
            component={CompletedOrders}
          />
          <RouteWithLayout
            path={PATHS.SUCCSESSFULTRANSACTIONS}
            layout={MainLayout}
            component={SuccessfulTransactions}
          />
          <RouteWithLayout
            path={PATHS.FAILEDTRANSACTIONS}
            layout={MainLayout}
            component={FailedTransactions}
          />
          <RouteWithLayout
            path={PATHS.PROCESSEDINVOICE}
            layout={MainLayout}
            component={ProcessedInvoice}
          />
          <RouteWithLayout
            path={PATHS.UNPROCESSEDINVOICE}
            layout={MainLayout}
            component={UnprocessedInvoice}
          />
          <RouteWithLayout
            path={PATHS.CART}
            layout={MainLayout}
            component={CreateCart}
          />
          <RouteWithLayout
            path={PATHS.LEADERBOARD}
            layout={MainLayout}
            component={LeaderBoard}
          />
          <RouteWithLayout
            path={PATHS.LEDGER}
            layout={MainLayout}
            component={Ledger}
          />
          <RouteWithLayout
            path={PATHS.STOCKOUTS}
            layout={MainLayout}
            component={StockOuts}
          />
          <RouteWithLayout
            path={PATHS.STOCKCONTROL}
            layout={MainLayout}
            component={StockControl}
          />
          <RouteWithLayout
            path={PATHS.ALLLEDGER}
            layout={MainLayout}
            component={AllLedgers}
          />
          <RouteWithLayout
            path={PATHS.DEFAULTLEDGER}
            layout={MainLayout}
            component={DefaultLedgers}
          />
          <RouteWithLayout
            path={PATHS.COMMISSIONS}
            layout={MainLayout}
            component={Commissions}
          />
          <RouteWithLayout
            path={PATHS.ALLCOMMISSIONS}
            layout={MainLayout}
            component={AllCommissions}
          />
          <RouteWithLayout
            path={PATHS.ALLPAYOUTS}
            layout={MainLayout}
            component={AllPayOuts}
          />
          <RouteWithLayout
            path={PATHS.BURSTSMS}
            layout={MainLayout}
            component={BurstSMS}
          />
          <RouteWithLayout
            path={PATHS.FEEDBACK}
            layout={MainLayout}
            component={Feedback}
          />
          <RouteWithLayout
            path={PATHS.CLFEEDBACK}
            layout={MainLayout}
            component={CLFeedback}
          />
          <RouteWithLayout
            path={PATHS.CLOFEEDBACK}
            layout={MainLayout}
            component={CLOFeedback}
          />
          <RouteWithLayout
            path={PATHS.NEWS}
            layout={MainLayout}
            component={News}
          />
          <RouteWithLayout
            path={PATHS.POPUP}
            layout={MainLayout}
            component={PopUp}
          />
          <RouteWithLayout
            path={PATHS.BIDREQUEST}
            layout={MainLayout}
            component={bidRequest}
          />
          <RouteWithLayout
            path={PATHS.VAT}
            layout={MainLayout}
            component={VAT}
          />
          <RouteWithLayout
            path={PATHS.SETTINGS}
            layout={MainLayout}
            component={Settings}
          />
          <RouteWithLayout
            path={PATHS.ADMINLOGS}
            layout={MainLayout}
            component={AdminLogs}
          />
          <RouteWithLayout
            path={PATHS.BLOGS}
            layout={MainLayout}
            component={Blogs}
          />
          <RouteWithLayout
            path={PATHS.SIGN_UP}
            layout={MinimalLayout}
            component={SignUp}
            publicPath
          />
          <RouteWithLayout
            path={PATHS.SIGN_IN}
            layout={MinimalLayout}
            component={SignIn}
            publicPath
          />
          <RouteWithLayout
            path={PATHS.NOT_FOUND}
            layout={MinimalLayout}
            component={NotFound}
            publicPath
          />
          <RouteWithLayout
            path={PATHS.SIGN_OUT}
            layout={MinimalLayout}
            component={SignOut}
            publicPath
          />
        </Router>
      </Auth.Provider>
    </ThemeProvider>
  )
}

export default App
