import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CSVLink } from 'react-csv'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  exportArray: any[]
  refresh: number
  cartID: string
  starttime: string
  endtime: string
  totalItems: number
  cartItems: any[]
  arrayReferrer: any[]
  displayCart: boolean
  displayReferrer: boolean
  grandTotal: string
  leaderIDSearch: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginLeft: 'auto',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const OrdersTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const [isLoading, setIsloading] = useState<boolean>(true)
  const [searched, setSearched] = useState<any>('')
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    exportArray: [],
    refresh: 0,
    totalItems: 0,
    cartID: '',
    starttime: '',
    endtime: '',
    cartItems: [],
    arrayReferrer: [],
    displayCart: false,
    displayReferrer: false,
    grandTotal: '',
    leaderIDSearch: '',
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  useEffect(() => {
    setIsloading(true)
    const fetchData = async () => {
      const data = await firestore
        .collection('Orders')
        .where('Delivered', '==', true)
        .where('Cancelled', '==', false)
        .where('DateOfOrder', '>=', formState.starttime)
        .where('DateOfOrder', '<', formState.endtime)
        .orderBy('DateOfOrder', 'desc')
        .get()

      const list: any = []
      const cartList: any = []

      data.forEach(doc => {
        const {
          CustomerID,
          CustomerName,
          CartID,
          CustomerPNo,
          DeliveryAddress,
          AddressUrl,
          LeaderID,
          LeaderName,
          TotalPrice,
          DateOfOrder,
          Delivered,
          POD,
          Return,
          Cancelled,
          DateofDispatch,
          DeliveryTime,
          InvoiceNo,
          Hold,
        } = doc.data()

        if (formState.leaderIDSearch === '') {
          list.push({
            key: doc.id,
            customerID: CustomerID,
            customerName: CustomerName,
            cartID: CartID,
            pNo: CustomerPNo,
            address: DeliveryAddress,
            leaderID: LeaderID,
            leaderName: LeaderName,
            Total: TotalPrice,
            date: DateOfOrder,
            delivered: Delivered,
            pod: POD,
            cancelled: Cancelled,
            hold: Hold,
            return: Return,
          })

          const shoppingCartRef = firebase
            .database()
            .ref('shoppingCarts/' + CartID)
          shoppingCartRef.on('value', snapshot => {
            if (snapshot.exists()) {
              snapshot.forEach(function (childSnapshot) {
                if (childSnapshot.key !== 'grandTotal') {
                  const data = childSnapshot.val()

                  cartList.push({
                    OrderID: doc.id,
                    customerID: CustomerID,
                    customerName: CustomerName,
                    cartID: CartID,
                    pNo: CustomerPNo,
                    address: DeliveryAddress,
                    leaderID: LeaderID,
                    leaderName: LeaderName,
                    productDesc: data.productDesc,
                    productPrice: data.productPrice,
                    quantity: data.quantity,
                    total: data.total,
                    return: Return ? 'True' : 'False',
                    status: POD ? 'POD' : 'Paid',
                    DateOfOrder: DateOfOrder,
                    DateofDispatch,
                    DeliveryTime: DeliveryTime,
                    addressUrl: AddressUrl,
                    InvoiceNo,
                  })
                }
              })

              // Code for adding grandtotal to cart record
            } else {
              console.log('No data available')
              // this.setState({
              //   emptyCart: true
              // })
            }
          })
        } else if (formState.leaderIDSearch === LeaderID) {
          list.push({
            key: doc.id,
            customerID: CustomerID,
            customerName: CustomerName,
            cartID: CartID,
            pNo: CustomerPNo,
            address: DeliveryAddress,
            leaderID: LeaderID,
            leaderName: LeaderName,
            Total: TotalPrice,
            date: DateOfOrder,
            delivered: Delivered,
            pod: POD,
            cancelled: Cancelled,
            hold: Hold,
            return: Return,
            InvoiceNo,
          })

          const shoppingCartRef = firebase
            .database()
            .ref('shoppingCarts/' + CartID)
          shoppingCartRef.on('value', snapshot => {
            if (snapshot.exists()) {
              const shoppingCartRef = firebase
                .database()
                .ref('shoppingCarts/' + CartID)
              shoppingCartRef.on('value', snapshot => {
                if (snapshot.exists()) {
                  snapshot.forEach(function (childSnapshot) {
                    if (childSnapshot.key !== 'grandTotal') {
                      const data = childSnapshot.val()

                      if (!Hold) {
                        cartList.push({
                          OrderID: doc.id,
                          customerID: CustomerID,
                          customerName: CustomerName,
                          cartID: CartID,
                          pNo: CustomerPNo,
                          address: DeliveryAddress,
                          leaderID: LeaderID,
                          leaderName: LeaderName,
                          productDesc: data.productDesc,
                          productPrice: data.productPrice,
                          quantity: data.quantity,
                          total: data.total,
                          return: Return ? 'True' : 'False',
                          status: POD ? 'POD' : 'Paid',
                          DateOfOrder: DateOfOrder,
                          DateofDispatch,
                          DeliveryTime: DeliveryTime,
                          addressUrl: AddressUrl,
                          InvoiceNo,
                        })
                      }
                    }
                  })

                  // Code for adding grandtotal to cart record
                } else {
                  console.log('No data available')
                  // this.setState({
                  //   emptyCart: true
                  // })
                }
              })

              // Code for adding grandtotal to cart record
            } else {
              console.log('No data available')
              // this.setState({
              //   emptyCart: true
              // })
            }
          })
        }
      })

      setFormState({
        ...formState,
        array: list,
        exportArray: cartList,
        totalItems: list.length,
      })
      setIsloading(false)
    }

    fetchData()
  }, [formState.refresh])

  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let customerIdSearch: any = row.customerID
        let customernameSearch: any = row.customerName
        let leadernameSearch: any = row.leaderName
        let phoneNumberSearch: any = row.pNo
        let cartIdSearch: any = row.cartID
        let dateSearch: any = row.date
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(customerIdSearch) ||
          expression.test(customernameSearch) ||
          expression.test(leadernameSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(cartIdSearch) ||
          expression.test(dateSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    console.log('ARRRAAYYYYY')

    console.log(formState.exportArray)
    setFormState({
      ...formState,
      // totalItems: 0,
      refresh: formState.refresh + 1,
    })
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const handleChange = (e: any) => {
    const date = new Date(e.target.value)

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    if (e.target.name === 'starttime') {
      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const handleDisplayCart = (e, cartID, Total) => {
    const firestore = firebase.firestore()

    const listCart: any = []

    const fetchData = async () => {
      const shoppingCartRef = await firebase
        .database()
        .ref('shoppingCarts/' + cartID)

      shoppingCartRef.once('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          const data = childSnapshot.val()

          if (childSnapshot.key !== 'grandTotal') {
            listCart.push({
              key: data.productID,
              desc: data.productDesc,
              price: data.productPrice,
              quantity: data.quantity,
              total: data.total,
            })
          }
        })
      })

      setFormState({
        ...formState,
        displayCart: true!,
        cartItems: listCart,
        cartID: cartID,
        grandTotal: Total,
      })
    }
    fetchData()
  }

  const handleDisplayReferral = async (e, key) => {
    const orders = await firestore.collection('Orders').doc(key)

    orders.get().then(doc => {
      // const { Referrer } = doc.data()
      if (doc.exists) {
        const data = doc.data()

        const data2 = JSON.stringify(data)

        const data3 = JSON.parse(data2)

        const referrer = data3.Referrer

        let list: any = []

        firestore
          .collection('Customers')
          .doc(referrer)
          .get()
          .then(doc2 => {
            const data4 = doc2.data()

            const data5 = JSON.stringify(data4)

            const data6 = JSON.parse(data5)

            firestore
              .collection('CommunityLeaders')
              .doc(data6.LeaderID)
              .get()
              .then(doc3 => {
                const data7 = doc3.data()

                const data8 = JSON.stringify(data7)

                const data9 = JSON.parse(data8)

                list.push({
                  UID: doc2.id,
                  Name: data6.Name,
                  LeaderID: data6.LeaderID,
                  LeaderName: data9.Name,
                  Address: data6.Address,
                  Neighborhood: data6.Neighborhood,
                  City: data6.City,
                  PhoneNumber: data6.PhoneNumber,
                  Email: data6.Email,
                  ReferralLink: data6.ReferralLink,
                })
              })
              .then(() => {
                setFormState({
                  ...formState,
                  arrayReferrer: list,
                  displayReferrer: true,
                })
              })
          })
      }
    })
  }

  const handleCloseCart = () => {
    setFormState({
      ...formState,
      displayCart: false!,
      cartItems: [],
      cartID: '',
      grandTotal: '',
    })
  }

  const handleCloseReferrer = () => {
    setFormState({
      ...formState,
      displayReferrer: false!,
      arrayReferrer: [],
    })
  }

  const handleCancelOrder = (e, orderID) => {
    const { authUser } = Auth.useContainer()

    const userEmail = authUser?.email

    if (userEmail === 'malon@tushop.io') {
      const url = 'https://api.tushop.io/api/v1/cancelOrder'

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderID: orderID,
          checked: e.target.checked,
        }),
      })
        .then((response: any) => {
          if (response.status === 201) {
            return response.json()
          } else {
            alert(response.message)
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(response => {
          console.debug(response)
          alert(response.message)
          handleRefresh()
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      alert('User not authorized to cancel orders')
    }
  }

  const handleChangeActivity = (e, orderID) => {
    const url = 'https://api.tushop.io/api/v1/deliverOrder'

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderID: orderID,
        checked: e.target.checked,
      }),
    })
      .then((response: any) => {
        if (response.status === 201) {
          return response.json()
        } else {
          alert(response.message)
          throw new Error('Something went wrong on api server!')
        }
      })
      .then(response => {
        console.debug(response)
        alert(response.message)
        handleRefresh()
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleHoldOrder = (e, orderID) => {
    const url = 'https://api.tushop.io/api/v1/holdOrder'

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderID: orderID,
        checked: e.target.checked,
      }),
    })
      .then((response: any) => {
        if (response.status === 201) {
          return response.json()
        } else {
          alert(response.message)
          throw new Error('Something went wrong on api server!')
        }
      })
      .then(response => {
        console.debug(response)
        alert(response.message)
        handleRefresh()
      })
      .catch(error => {
        console.error(error)
      })
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const headers = [
    { label: 'OrderID', key: 'OrderID' },
    { label: 'customerID', key: 'customerID' },
    { label: 'customerName', key: 'customerName' },
    { label: 'cartID', key: 'cartID' },
    { label: 'pNo', key: 'pNo' },
    { label: 'address', key: 'address' },
    { label: 'leaderID', key: 'leaderID' },
    { label: 'leaderName', key: 'leaderName' },
    { label: 'productDesc', key: 'productDesc' },
    { label: 'productPrice', key: 'productPrice' },
    { label: 'quantity', key: 'quantity' },
    { label: 'total', key: 'total' },
    { label: 'return', key: 'return' },
    { label: 'status', key: 'status' },
    { label: 'DateOfOrder', key: 'DateOfOrder' },
    { label: 'DeliveryTime', key: 'DeliveryTime' },
    { label: 'AddressURL', key: 'addressUrl' },
    { label: 'DateOfDispatch', key: 'DateofDispatch' },
    { label: 'InvoiceID', key: 'InvoiceNo' },
  ]

  const csvReport = {
    data: formState.exportArray,
    headers: headers,
    filename: 'CompletedOrders(' + today + ').csv',
  }

  const DialogReferrer = () => {
    console.log('WUEWUEH', formState.arrayReferrer)

    return (
      <Dialog
        open={formState.displayReferrer}
        onClose={handleCloseReferrer}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1>Referrer Info</h1>
        </DialogTitle>
        <DialogContent>
          {formState.arrayReferrer.map(data => (
            <ul>
              <li>
                <b>UID: </b> {data.UID}
              </li>
              <li>
                <b>Name: </b> {data.Name}
              </li>
              <li>
                <b>Leader ID: </b> {data.LeaderID}
              </li>
              <li>
                <b>Leader Name: </b> {data.LeaderName}
              </li>
              <li>
                <b>Address: </b> {data.Address}
              </li>
              <li>
                <b>Neighborhood: </b> {data.Neighborhood}
              </li>
              <li>
                <b>City: </b> {data.City}
              </li>
              <li>
                <b>Phone Number: </b> {data.PhoneNumber}
              </li>
              <li>
                <b>Email: </b> {data.Email}
              </li>
              <li>
                <b>Referral Link Used: </b> {data.ReferralLink}
              </li>
            </ul>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReferrer} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>
              Completed Orders: {formState.totalItems}
            </h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink onClick={handleRefresh} {...csvReport}>
                Export to CSV
              </CSVLink>
            </Button>
          </div>
          <div className={classes.row}>
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="starttime"
              label="Start Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="endtime"
              label="End Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />

          {DialogReferrer()}
          <Dialog
            open={formState.displayCart}
            onClose={handleCloseCart}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              CartID: {formState.cartID}
            </DialogTitle>

            <DialogContent>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{
                      height: '5px',
                      width: '100%',
                      backgroundColor: '#d3d3d3',
                    }}
                  >
                    <TableCell>
                      <b>
                        <h2>Description</h2>
                      </b>
                    </TableCell>
                    <TableCell>
                      <b>
                        <h2>Qty</h2>
                      </b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <b>
                        <h2>Price</h2>
                      </b>
                    </TableCell>
                    <TableCell>
                      <b>
                        <h2>Total</h2>
                      </b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formState.cartItems.map(data => (
                    <TableRow key="row1">
                      <TableCell>{data.desc}</TableCell>
                      <TableCell>{data.quantity}</TableCell>
                      <TableCell>X</TableCell>
                      <TableCell>{data.price}</TableCell>
                      <TableCell>
                        <b>{data.total}</b>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow key="row2">
                    <TableCell colSpan={3}>
                      <h1>GrandTotal</h1>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <h1>{formState.grandTotal}</h1>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCart} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Order ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Cart ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Delivery Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Return?</b>
                  </TableCell>
                  <TableCell>
                    <b>Cancel</b>
                  </TableCell>
                  <TableCell>
                    <b>Hold</b>
                  </TableCell>
                  <TableCell>
                    <b>Delivered?</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.customerID}</TableCell>
                        <TableCell>{data.customerName}</TableCell>
                        <TableCell>{data.pNo}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e =>
                              handleDisplayCart(e, data.cartID, data.Total)
                            }
                          >
                            {data.cartID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.address}</TableCell>
                        {data.leaderName === 'Referrals' ? (
                          <TableCell>
                            <Button
                              onClick={e => handleDisplayReferral(e, data.key)}
                            >
                              {data.leaderName}
                            </Button>
                          </TableCell>
                        ) : (
                          <TableCell>{data.leaderName}</TableCell>
                        )}
                        <TableCell>{data.date}</TableCell>
                        {data.pod ? (
                          <TableCell>POD</TableCell>
                        ) : (
                          <TableCell>Paid</TableCell>
                        )}
                        {data.return ? (
                          <TableCell>True</TableCell>
                        ) : (
                          <TableCell>False</TableCell>
                        )}
                        <TableCell>
                          <Switch
                            checked={data.cancelled}
                            onChange={e => handleCancelOrder(e, data.key)}
                            name={data.key}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.hold}
                            onChange={e => handleHoldOrder(e, data.key)}
                            name={data.key}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.delivered}
                            onChange={e => handleChangeActivity(e, data.key)}
                            name={data.key}
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default OrdersTable
