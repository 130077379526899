import { makeStyles } from '@material-ui/styles'
import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { ITheme } from '../../theme'
import BurstSMS from "./BurstSMS"

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const BurstSMSList: React.FC<RouteComponentProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <BurstSMS />
      </div>
    </div>
  )
}

export default BurstSMSList
