import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button, MenuItem } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import { CSVLink } from 'react-csv'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'
import { convertDate } from '../../../utils/date_convert'
import sha1 from 'sha1'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  open: boolean
  userID: string
  leaderID: string
  name: any
  address: string
  neighborhood: string
  city: string
  pNo: string
  email: string
  kiosqCustomerId: string
  type: string
  dropdown: any[]
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const UsersTable: React.FC<IProps> = () => {
  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  console.log('user email from dashboard side', userEmail)
  const classes = useStyles()
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [searched, setSearched] = useState<any>('')
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    open: false,
    userID: 'CS' + Math.floor(100000000 + Math.random() * 900000000),
    leaderID: '',
    name: '',
    address: '',
    neighborhood: '',
    city: '',
    pNo: '',
    email: '',
    kiosqCustomerId: '',
    type: '',
    dropdown: [],
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination
  useEffect(() => {
    //function to fecth data
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('Customers')
        .orderBy('CreatedAt', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          Address,
          City,
          Email,
          Name,
          Neighborhood,
          LeaderID,
          PhoneNumber,
          ReferralLink,
          CreatedAt,
          Type,
        } = doc.data()

        list.push({
          UID: doc.id,
          Name: Name,
          Address: Address,
          Neighborhood: Neighborhood,
          City: City,
          LeaderID: LeaderID,
          PhoneNumber: PhoneNumber,
          Email: Email,
          ReferralLink: ReferralLink,
          Date: CreatedAt,
          Type: Type,
        })
      })

      setFormState({
        ...formState,
        array: list,
      })
      setIsloading(false)
    }

    //function to fecth drop down data
    const fetchDropdownData = async () => {
      const data = await firestore.collection('CommunityLeaders').get()

      data.forEach(doc => {
        const { Name } = doc.data()

        formState.dropdown.push(<MenuItem value={doc.id}>{Name}</MenuItem>)
      })
    }

    //calling the functions
    fetchData()
    fetchDropdownData()
  }, [formState.refresh])

  const handleClickOpen = () => {
    setFormState({
      ...formState,
      open: true!,
    })
  }

  // const createNewID = () => {
  //   setFormState({
  //     ...formState,
  //     userID: "CS"+Math.floor(Math.random()*(999-100+1)+100)
  //   })
  // }

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let uidSearch: any = row.UID
        let nameSearch: any = row.Name
        let leaderIdSearch: any = row.LeaderId
        let addressSearch: any = row.Address
        let phoneNumberSearch: any = row.PhoneNumber
        let emailSearch: any = row.Email
        let neighbourhoodSearch: any = row.Neighborhood
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(uidSearch) ||
          expression.test(leaderIdSearch) ||
          expression.test(addressSearch) ||
          expression.test(nameSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(emailSearch) ||
          expression.test(neighbourhoodSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleClose = () => {
    setFormState({
      ...formState,
      open: false!,
      userID: 'CS' + Math.floor(100000000 + Math.random() * 900000000),
      refresh: formState.refresh + 1,
      leaderID: '',
      name: '',
      address: '',
      neighborhood: '',
      city: '',
      pNo: '',
      email: '',
      kiosqCustomerId: '',
      type: '',
    })
  }

  const handleChange = (e: any) => {
    if (e.target.id === 'name') {
      setFormState({
        ...formState,
        name: e.target.value,
      })
    } else if (e.target.id === 'address') {
      setFormState({
        ...formState,
        address: e.target.value,
      })
    } else if (e.target.id === 'neighborhood') {
      setFormState({
        ...formState,
        neighborhood: e.target.value,
      })
    } else if (e.target.id === 'city') {
      setFormState({
        ...formState,
        city: e.target.value,
      })
    } else if (e.target.id === 'pNo') {
      const number = e.target.value
      const fullNo = '254' + number.substring(1)
      setFormState({
        ...formState,
        pNo: fullNo,
      })
    } else if (e.target.id === 'email') {
      setFormState({
        ...formState,
        email: e.target.value,
      })
    } else if (e.target.name === 'communityLeader') {
      setFormState({
        ...formState,
        leaderID: e.target.value,
      })
    } else if (e.target.name === 'customerType') {
      setFormState({
        ...formState,
        type: e.target.value,
      })
    }
  }

  const handleAddCustomer = () => {
    if (formState.name === '') {
      // return(
      // <Alert severity="error">Input Name</Alert>
      // )
    } else if (formState.address === '') {
      // return(
      // <Alert severity="error">Input Address</Alert>
      // )
    } else if (formState.city === '') {
      // return(
      // <Alert severity="error">Input City</Alert>
      // )
    } else if (formState.neighborhood === '') {
      // return(
      // <Alert severity="error">Input Neighborhood</Alert>
      // )
    } else if (formState.pNo === '') {
      // return(
      // <Alert severity="error">Input Phone Number</Alert>
      // )
    } else if (formState.email === '') {
      // return(
      // // <Alert severity="error">Input Email</Alert>
      // )
    } else {
      const url = 'https://api.kiosq.io/v1/customers'

      const apiKey =
        'aBVhLp7bZpDeyBg92baAU8FYr6wn8D2wv7fTdMjsFRBCHKNdV4eF2TWebwydv73a'

      fetch(url + '?access_token=' + apiKey, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone_number: formState.pNo,
          name: formState.name,
          email: formState.email,
        }),
      })
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(response => {
          console.debug(response)

          setFormState({
            ...formState,
            kiosqCustomerId: response.id,
          })

          firestore
            .collection('Customers')
            .doc(formState.userID)
            .set({
              UserID: formState.userID,
              CustomerID: response.id,
              Name: formState.name,
              Address: formState.address,
              City: formState.city,
              Neighborhood: formState.neighborhood,
              PhoneNumber: formState.pNo,
              EncryptPhoneNumber: sha1(formState.pNo),
              Email: formState.email,
              LeaderID: formState.leaderID,
              Referrals: 0,
              ReferredIDs: [],
              ReferralLink:
                'https://shop.tushop.io/referral/CL735461/' + formState.userID,
              Type: formState.type,
            })
            .then(() => {
              const logRef =
                'LOG' + Math.floor(100000000 + Math.random() * 900000000)
              firestore
                .collection('AdminLogs')
                .doc(logRef)
                .set({
                  LogId: logRef,
                  userEmail: userEmail,
                  activity: 'Added a new customer',
                  date_created: convertDate(new Date()),
                  type: 'Create',
                  record: {
                    UserID: formState.userID,
                    CustomerID: response.id,
                    Name: formState.name,
                    Address: formState.address,
                    City: formState.city,
                    Neighborhood: formState.neighborhood,
                    PhoneNumber: formState.pNo,
                    Email: formState.email,
                    LeaderID: formState.leaderID,
                    Referrals: 0,
                    ReferredIDs: [],
                    EncryptPhoneNumber: sha1(formState.pNo),
                    ReferralLink:
                      'https://shop.tushop.io/referral/CL735461/' +
                      formState.userID,
                    Type: formState.type,
                  },
                })
                .catch(err => console.log(err.message))
            })

          handleClose()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const headers = [
    { label: 'UID', key: 'UID' },
    { label: 'Name', key: 'Name' },
    { label: 'Address', key: 'Address' },
    { label: 'Neighborhood', key: 'Neighborhood' },
    { label: 'City', key: 'City' },
    { label: 'LeaderID', key: 'LeaderID' },
    { label: 'PhoneNumber', key: 'PhoneNumber' },
    { label: 'Date', key: 'Date' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'CustomerList.csv',
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleDelete = (e, id) => {
    console.log('ID: ' + id)
    const deletedUserRecord = id
    firestore
      .collection('Customers')
      .doc(id)
      .delete()
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Deleted a customer',
            date_created: convertDate(new Date()),
            type: 'Delete',
            record: { CL: deletedUserRecord },
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Customer Deleted')
        handleRefresh()
      })
  }

  const handleEdit = (
    e,
    UID,
    Name,
    Address,
    Neighborhood,
    City,
    PhoneNumber,
    Email,
    Type
  ) => {
    setFormState({
      ...formState,
      open: true!,
      userID: UID,
      name: Name,
      address: Address,
      neighborhood: Neighborhood,
      city: City,
      pNo: PhoneNumber,
      email: Email,
      type: Type,
    })
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>Customers</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            <Button
              color='primary'
              variant='contained'
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={handleClickOpen}
            >
              Add user
            </Button>
          </div>
          <Dialog
            open={formState.open}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>Add User Datails</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add New Customer Data below. (ID = {formState.userID})
              </DialogContentText>
              <TextField
                id='name'
                margin='dense'
                label='Customer Name'
                fullWidth
                placeholder='Names'
                onChange={handleChange}
              ></TextField>
              {/* <TextField autoFocus margin="dense" id="name" label="Name" fullWidth onChange={} /> */}
              <TextField
                autoFocus
                margin='dense'
                id='address'
                label='Address'
                fullWidth
                onChange={handleChange}
                value={formState.address}
              />
              <TextField
                autoFocus
                margin='dense'
                id='neighborhood'
                label='Neighborhood'
                fullWidth
                onChange={handleChange}
                value={formState.neighborhood}
              />
              <TextField
                autoFocus
                margin='dense'
                id='city'
                label='City'
                fullWidth
                onChange={handleChange}
                value={formState.city}
              />
              <TextField
                autoFocus
                margin='dense'
                id='pNo'
                label='Phone Number'
                fullWidth
                onChange={handleChange}
                value={formState.pNo}
              />
              <TextField
                autoFocus
                margin='dense'
                id='email'
                label='Email'
                fullWidth
                onChange={handleChange}
                value={formState.email}
              />
              <FormControl fullWidth>
                <InputLabel id='CLdropdown'>Community Leader</InputLabel>
                <Select
                  labelId='CLdropdown'
                  name='communityLeader'
                  onChange={handleChange}
                  value={formState.leaderID}
                >
                  <MenuItem>--------Select Community Leader--------</MenuItem>
                  {formState.dropdown}
                  {/* <MenuItem>Test Item1</MenuItem>
                      <MenuItem>Test Item2</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id='typeDropdown'>Customer Type</InputLabel>
                <Select
                  labelId='typeDropdown'
                  name='customerType'
                  onChange={handleChange}
                  value={formState.type}
                >
                  <MenuItem>--------Select Customer Type--------</MenuItem>
                  <MenuItem value='End-Customer'>End-Customer</MenuItem>
                  <MenuItem value='Duka'>Duka</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
                Cancel
              </Button>
              <Button onClick={handleAddCustomer} color='primary'>
                Add Customer
              </Button>
            </DialogActions>
          </Dialog>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />

          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>UID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Neighborhood</b>
                  </TableCell>
                  <TableCell>
                    <b>City</b>
                  </TableCell>
                  <TableCell>
                    <b>LeaderID</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.UID}>
                        <TableCell>{data.UID}</TableCell>
                        <TableCell>{data.Name}</TableCell>
                        <TableCell>{data.Address}</TableCell>
                        <TableCell>{data.Neighborhood}</TableCell>
                        <TableCell>{data.City}</TableCell>
                        <TableCell>{data.LeaderID}</TableCell>
                        <TableCell>{data.PhoneNumber}</TableCell>
                        <TableCell>{data.Email}</TableCell>
                        <TableCell>{data.Type}</TableCell>
                        <TableCell>{data.Date}</TableCell>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e =>
                              handleEdit(
                                e,
                                data.UID,
                                data.Name,
                                data.Address,
                                data.Neighborhood,
                                data.City,
                                data.PhoneNumber,
                                data.Email,
                                data.Type
                              )
                            }
                          >
                            <EditIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default UsersTable
