import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ITheme } from "../../theme";
import Processed from "./Processed";
import Unprocessed from "./Unprocessed";
import Registered from "./Registered"


const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const News: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Unprocessed />
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Processed />
        </Grid>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <Registered />
        </Grid>
      </Grid>
    </div>
  );
};

export default News;
