import { makeStyles } from '@material-ui/styles';
import { RouteComponentProps } from '@reach/router';
import React, { useState } from 'react';
import { ITheme } from '../../theme';
import AllTransactionsTable from './AllLedgersTable';

const useStyles = makeStyles((theme:ITheme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AllTransactionsList: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AllTransactionsTable/>
      </div>
    </div>
  );
};

export default AllTransactionsList;
