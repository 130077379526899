import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Button, MenuItem } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import { CSVLink } from 'react-csv'

import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'
import { convertDate } from '../../../utils/date_convert'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  exportArray: any[]
  table: any[]
  productDropdown: any[]
  refresh: number
  order: number
  open: boolean
  loading: boolean
  openCart: boolean
  Selectcustomer: any[]
  displayCart: boolean
  cartItems: any[]
  cartID: string
  ProductID: string
  targetQty: number
  soldQty: number
  customers: any[]
  qty: string
  imgUrl: string
  name: string
  starttime: string
  endtime: string
  desc: string
  price: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '50%',
  },
  imageCell: {
    width: '25%',
  },
  textarea: {
    width: '100%',
  },
  button: {
    padding: '20px',
  },

  imgStyle: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
  },

  price: {
    textAlign: 'right',
    width: '1%',
    color: 'green',
    height: '5px',
    padding: '0px',
  },

  rowStyle: {
    textAlign: 'center',
    width: '100%',
    marginTop: 'auto',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const ProductTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const [searched, setSearched] = useState<any>('')
  const [isLoadingTable, setIsloadingTable] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    exportArray: [],
    table: [],
    productDropdown: [],
    refresh: 0,
    order: 0,
    targetQty: 0,
    soldQty: 0,
    open: false,
    openCart: false,
    loading: false,
    displayCart: false,
    cartItems: [],
    cartID: '',
    Selectcustomer: [],
    customers: [],
    qty: '0',
    ProductID: '',
    imgUrl: '',
    name: '',
    starttime: '',
    endtime: '',
    desc: '',
    price: 0,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  const storage = firebase.storage()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.table.length / PER_PAGE)
  const _DATA = usePagination(formState.table, PER_PAGE)
  //console.log('entire group by', _DATA)
  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }
  //end of handling page pagination

  // let image: File;

  useEffect(() => {
    const fetchTableData = async () => {
      const date = new Date()

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        'T' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      setIsloadingTable(true)
      const data = await firestore
        .collection('GroupBuy')
        .orderBy('CreatedAt', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          ProductID,
          TargetQty,
          SoldQty,
          StartDate,
          EndDate,
          Leaders,
          CreatedAt,
        } = doc.data()

        list.push({
          key: doc.id,
          ProductID: ProductID,
          TargetQty: TargetQty,
          SoldQty: SoldQty,
          StartDate: StartDate,
          EndDate: EndDate,
          Leaders: Leaders[0],
          CreatedAt: CreatedAt,
          Color:
            EndDate < today
              ? SoldQty >= parseInt(TargetQty)
                ? 'green'
                : 'red'
              : 'yellow',
        })
      })

      setFormState({
        ...formState,
        table: list,
      })
      setIsloadingTable(false)
      // console.log('loading state after fetching data', isLoadingTable)
    }

    const fetchProductData = async () => {
      const data = await firestore
        .collection('Products')
        .where("GroupBuy", "==", true)
        .get()

      const list: any = []

      data.forEach(doc => {
        const { Description } = doc.data()

        formState.productDropdown.push(
          <MenuItem value={doc.id}>
            {' '}
            {doc.id}: {Description}
          </MenuItem>
        )
      })
    }

    fetchTableData()

    fetchProductData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.table.filter(row => {
        let keySearch: any = row.key
        let productIdSearch: any = row.ProductID
        let startDateSearch: any = row.StartDate
        let endDateSearch: any = row.EndDate
        let leaderSearch: any = row.Leaders
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(productIdSearch) ||
          expression.test(startDateSearch) ||
          expression.test(endDateSearch) ||
          expression.test(leaderSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, table: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  const fetchDropdownData = async () => {
    const data = await firestore.collection('CommunityLeaders').where("HVA", "==", true).get()

    data.forEach(doc => {
      const { Address, Name, PhoneNumber, Type } = doc.data()

      formState.customers.push({
        UID: doc.id,
        Name: Name,
        Address: Address,
        PhoneNumber: PhoneNumber,
      })
    })
  }

  const fetchProductData = async () => {
    const data = await firestore
      .collection('Products')
      .orderBy('Order', 'asc')
      .get()

    const list: any = []

    data.forEach(doc => {
      const { Name, Description, ImgUrl, Price, QtySold, Active, Special } =
        doc.data()

      formState.array.push({
        key: doc.id,
        name: Name,
        desc: Description,
        imgUrl: ImgUrl,
        qtySold: QtySold,
        price: Price,
        array: [1, 2, 3, 4, 5, 10],
      })
    })
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = async () => {
    setFormState({
      ...formState,
      loading: true,
    })

    await fetchDropdownData()

    setFormState({
      ...formState,
      open: true!,
      loading: false,
    })
  }

  const handleCloseCart = () => {
    setFormState({
      ...formState,
      openCart: false!,
      customers: [],
      array: [],
      Selectcustomer: [],
      loading: false,
      refresh: formState.refresh + 1,
    })
    // handleRefresh()
  }

  const DialogCartAssigner = () => {
    const handleAddCustomer = (e, UID) => {
      if (e.target.checked) {
        if (UID === 'ALL' && formState.Selectcustomer.length === 0) {
          firestore
            .collection('CommunityLeaders')
            .get()
            .then(doc => {
              doc.forEach(doc1 => {
                formState.Selectcustomer.push(doc1.id)

                console.log('CUSTOMA', formState.Selectcustomer)
              })
            })
        } else if (UID === 'ALL' && formState.Selectcustomer.length > 0) {
          firestore
            .collection('CommunityLeaders')
            .get()
            .then(doc => {
              doc.forEach(doc1 => {
                formState.Selectcustomer.push(doc1.id)

                console.log('CUSTOMA', formState.Selectcustomer)
              })
            })
        } else {
          formState.Selectcustomer.push(UID)

          console.log('CUSTOMA', formState.Selectcustomer)
        }
      } else {
        if (UID === 'ALL') {
          firestore
            .collection('CommunityLeaders')
            .get()
            .then(doc => {
              doc.forEach(doc1 => {
                const pos = formState.Selectcustomer.indexOf(doc1.id)

                formState.Selectcustomer.splice(pos, 1)

                console.log('CUSTOMB', formState.Selectcustomer)
              })
            })
        } else {
          const pos = formState.Selectcustomer.indexOf(UID)

          formState.Selectcustomer.splice(pos, 1)

          console.log('CUSTOMB', formState.Selectcustomer)
        }
      }
    }

    const handleProceedToCart = async () => {
      setFormState({
        ...formState,
        loading: true,
      })

      await fetchProductData()

      setFormState({
        ...formState,
        open: false!,
        openCart: true!,
        loading: false,
      })
    }

    const handleClose = () => {
      setFormState({
        ...formState,
        open: false!,
        customers: [],
        Selectcustomer: [],
      })
      // handleRefresh()
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Assign Leader to Groupbuy
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the community Leader you wish to assign the offer to
          </DialogContentText>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>Mark</TableCell>
                  <TableCell>
                    <b>UID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="all">
                  <TableCell>
                    <FormControlLabel
                      label="ALL"
                      control={
                        <Checkbox onChange={e => handleAddCustomer(e, 'ALL')} />
                      }
                    />
                  </TableCell>
                </TableRow>
                {formState.customers.map(data => (
                  <TableRow key={data.UID}>
                    <TableCell>
                      <FormControlLabel
                        label=""
                        control={
                          <Checkbox
                            onChange={e => handleAddCustomer(e, data.UID)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>{data.UID}</TableCell>
                    <TableCell>{data.Name}</TableCell>
                    <TableCell>{data.Address}</TableCell>
                    <TableCell>{data.PhoneNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleProceedToCart} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const loader = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        loading: false,
      })
      // handleRefresh()
    }

    return (
      <Dialog
        open={formState.loading}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <LoadingSpinner />
          <p style={{ textAlign: 'center' }}>Please wait</p>
        </DialogContent>
      </Dialog>
    )
  }

  const DialogCart = () => {
    const handleChange = (e: any) => {
      console.log('CHANGE')

      if (e.target.name === 'productID') {
        setFormState({
          ...formState,
          ProductID: e.target.value,
        })
      } else if (e.target.name === 'starttime') {
        setFormState({
          ...formState,
          starttime: e.target.value,
        })
      } else if (e.target.name === 'endtime') {
        setFormState({
          ...formState,
          endtime: e.target.value,
        })
      } else if (e.target.id === 'targetQty') {
        setFormState({
          ...formState,
          targetQty: e.target.value,
        })
      } else if (e.target.id === 'price') {
        setFormState({
          ...formState,
          price: e.target.value,
        })
      }
    }

    const handleCreateCart = () => {
      const date = new Date()

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
        ':' +
        (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

      // ProductID, TargetQty, SoldQty, StartDate, EndDate, Leaders

      // const data = await firestore.collection("GroupBuy").orderBy("CreatedAt", "desc").get()

      formState.Selectcustomer.forEach(CLID => {
        const GBID = 'GB' + Math.floor(100000000 + Math.random() * 900000000)

        // ProductID: formState.ProductID,
        //   TargetQty: formState.targetQty,
        //     SoldQty: formState.soldQty,
        //       StartDate: formState.starttime,
        //         EndDate: formState.endtime,
        //           Leaders: formState.Selectcustomer,
        //             CreatedAt: today,
        //               Price: formState.price,
        //                 POD: false,
        firestore
          .collection('GroupBuy')
          .doc(GBID)
          .set({
            ProductID: formState.ProductID,
            TargetQty: formState.targetQty,
            SoldQty: formState.soldQty,
            StartDate: formState.starttime,
            EndDate: formState.endtime,
            Leaders: [CLID],
            Price: formState.price,
            CreatedAt: today,
          })
          .then(() => {
            console.log('GroupBuyID: ', GBID)
            console.log('Customers: ', CLID)
            console.log('ProductID: ', formState.ProductID)
            console.log('Target QTY: ', formState.targetQty)
            console.log('Start Time: ', formState.starttime)
            console.log('End Time: ', formState.endtime)
          })

        handleCloseCart()
        handleRefresh()
      })
    }

    return (
      <Dialog
        open={formState.openCart}
        onClose={handleCloseCart}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Items to Cart</DialogTitle>
        <DialogContent>
          <DialogContentText>Select parameters for offer</DialogContentText>

          <FormControl fullWidth>
            <InputLabel id="CLdropdown">Product</InputLabel>
            <Select
              labelId="CLdropdown"
              name="productID"
              onChange={handleChange}
              value={formState.ProductID}
            >
              <MenuItem>--------Select Products--------</MenuItem>
              {formState.productDropdown}
              {/* <MenuItem>Test Item1</MenuItem>
                <MenuItem>Test Item2</MenuItem> */}
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="targetQty"
            label="Target Quantity"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formState.targetQty}
          />
          <TextField
            autoFocus
            margin="dense"
            id="price"
            label="Price"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formState.price}
          />
          <TextField
            id="datetime-local"
            onChange={handleChange}
            name="starttime"
            label="Start Date/Time"
            type="datetime-local"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          <TextField
            id="datetime-local"
            onChange={handleChange}
            name="endtime"
            label="End Date/Time"
            type="datetime-local"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateCart} color="primary">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleDisplayCart = async (e, id) => {
    const firestore = firebase.firestore()

    const listCart: any = []

    const leader = await firestore.collection('CommunityLeaders').doc(id)

    leader.get().then(doc => {
      if (doc.exists) {
        const data = JSON.parse(JSON.stringify(doc.data()))

        listCart.push({
          id: id,
          name: data.Name,
        })

        setFormState({
          ...formState,
          cartItems: listCart,
          displayCart: true!,
        })
      }
    })
  }

  const handleDelete = (e, id) => {
    console.log('ID: ' + id)
    const deletedUserRecord = id
    firestore
      .collection('GroupBuy')
      .doc(id)
      .delete()
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Deleted item from groupby',
            date_created: convertDate(new Date()),
            type: 'Delete',
            record: { GROUP_BY_ID: deletedUserRecord },
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Item Deleted')
        handleRefresh()
      })
  }

  const displayCartDialog = () => {
    const handleCloseDisplayCart = () => {
      setFormState({
        ...formState,
        displayCart: false!,
        cartItems: [],
        cartID: '',
      })
    }

    return (
      <Dialog
        open={formState.displayCart}
        onClose={handleCloseDisplayCart}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          CartID: {formState.cartID}
        </DialogTitle>

        <DialogContent>
          {formState.cartItems[0] === 'ALL' ? (
            <h1>Offer to all Community Leaders</h1>
          ) : (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  style={{
                    height: '5px',
                    width: '100%',
                    backgroundColor: '#d3d3d3',
                  }}
                >
                  <TableCell>
                    <b>
                      <h2>ID</h2>
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      <h2>Name</h2>
                    </b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.cartItems.map(data => (
                  <TableRow key="row1">
                    <TableCell>{data.id}</TableCell>
                    <TableCell>{data.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisplayCart} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const headers = [
    { label: 'GroupBuyID', key: 'key' },
    { label: 'ProductID', key: 'ProductID' },
    { label: 'TargetQty', key: 'TargetQty' },
    { label: 'SoldQty', key: 'SoldQty' },
    { label: 'StartDate', key: 'StartDate' },
    { label: 'EndDate', key: 'EndDate' },
    { label: 'AssignedTo', key: 'Leaders' },
    { label: 'CreatedAt', key: 'CreatedAt' },
  ]
  console.log(formState.table)
  const csvReport = {
    data: formState.table,
    headers: headers,
    filename: 'Groupbuys.csv',
  }

  return (
    <div className={classes.root}>
      {loader()}
      {displayCartDialog()}
      {DialogCartAssigner()}
      {DialogCart()}
      <Card className={classes.box}>
        <CardContent>
          <Button color="primary" variant="contained" onClick={handleClickOpen}>
            Add Offer
          </Button>
          <div className={classes.row}>
            <h1 className={classes.h1}>Group Buy: {formState.table.length}</h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink onClick={handleRefresh} {...csvReport}>
                Export to CSV
              </CSVLink>
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Product ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Target Qty</b>
                  </TableCell>
                  <TableCell>
                    <b>Sold Qty</b>
                  </TableCell>
                  <TableCell>
                    <b>Start Date</b>
                  </TableCell>
                  <TableCell>
                    <b>End Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Assigned to?</b>
                  </TableCell>
                  <TableCell>
                    <b>Created At</b>
                  </TableCell>
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingTable === false ? (
                  _DATA.currentData().map(data => {
                    console.log('group by', data)
                    return (
                      <TableRow
                        key={data.key}
                        style={{ backgroundColor: data.Color }}
                      >
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.ProductID}</TableCell>
                        <TableCell>{data.TargetQty}</TableCell>
                        <TableCell>{data.SoldQty}</TableCell>
                        <TableCell>{data.StartDate}</TableCell>
                        <TableCell>{data.EndDate}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e => handleDisplayCart(e, data.Leaders)}
                          >
                            {data.Leaders}
                          </Button>
                        </TableCell>
                        <TableCell>{data.CreatedAt}</TableCell>
                        <TableCell>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={e => handleDelete(e, data.key)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default ProductTable
