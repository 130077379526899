import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useEffect, useState, useRef } from 'react'
import { Auth } from '../../App'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FirebaseApp from '../../firebase/firebase'
import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

interface IProps {
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {},
  imageEdit: {
    display: 'block',
    maxWidth: '100%',
  },
  button: {
    padding: '20px',
  },
  textarea: {
    width: '100%',
  },
}))

const AddBlog: React.FC<IProps> = ({ className, ...rest }) => {
  const firebase = FirebaseApp
  const classes = useStyles()

  const firestore = firebase.firestore()

  const storage = firebase.storage()
  let image: File

  const { user, authUser } = Auth.useContainer()
  const userEmail = authUser?.email

  const [pending, setPending] = useState(false)
  const [btnMessage, setBtnMessage] = useState('UPLOAD IMAGE')
  const [btnMessageVideo, setBtnMessageVideo] = useState('UPLOAD VIDEO')
  const [imgUploading, setImageUploading] = useState(false)
  const [values, setValues] = useState({
    randomId: 'BLG' + Math.floor(Math.random() * 10000),
    title: '',
    video_url: '',
    img_urls: '',
  })
  const editorRef = useRef<TinyMCEEditor | null>(null)
  const handleChange = (e: any) => {
    if (e.target.id === 'title') {
      setValues({
        ...values,
        title: e.target.value,
      })
    } else if (e.target.id === 'video_url') {
      setValues({
        ...values,
        video_url: e.target.value,
      })
    } else if (e.target.files[0]) {
      image = e.target.files[0]
    }
  }

  const handleUploadImage = () => {
    setBtnMessage('PROCESSING')
    const Reference = storage.ref(`BlogImages/${values.randomId}/${image.name}`)
    Reference.put(image)
      .then(snapshot => {
        Reference.getDownloadURL().then(url => {
          const urlString = url.toString()

          setValues({
            ...values,
            img_urls: urlString,
          })
        })
      })
      .then(() => setBtnMessage('UPLOAD SUCCESSFULL'))
  }

  const handleUploadVideo = () => {
    setBtnMessageVideo('PROCESSING')
    const Reference = storage.ref(`BlogVideos/${values.randomId}/${image.name}`)
    Reference.put(image)
      .then(snapshot => {
        Reference.getDownloadURL().then(url => {
          const urlString = url.toString()

          setValues({
            ...values,
            video_url: urlString,
          })
        })
      })
      .then(() => setBtnMessageVideo('UPLOAD SUCCESSFULL'))
  }
  const handleSave = async () => {
    //upload of video is optional
    setPending(true)
    if (values.title === '') {
      alert('Please enter title')
    } else if (values.img_urls === '') {
      alert('Please upload image')
    } else {
      //save to database here
      const toSaveBlog = {
        title: values.title,
        body: editorRef.current?.getContent(),
        img_urls: values.img_urls,
        video_url: values.video_url ?? null,
      }
      console.log('shakabuyaa', toSaveBlog.img_urls)
      try {
        const config = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(toSaveBlog),
        }
        const response = await fetch(
          'https://staging.apis.tushop.io/api/v1/blogs',
          config
        )
        const data = await response.json()
        console.log(data)
        setPending(false)
        if (data.status == 'SUCCESS') {
          setPending(false)
          alert(data.message)
          return
        }
      } catch (error) {
        if (error instanceof Error) {
          setPending(false)
          console.log(error.message)
          alert(error.message)
        } else {
          console.log('Unexpected erroror', error)
          setPending(false)
          alert('Unexpected erroror')
        }
      }
    }
  }
  return (
    <>
      <Card
        style={{ marginBottom: 10 }}
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <h2>Blogs</h2>
          <p>Publish Blog Article here</p>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardContent>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Blog title"
              type="text"
              fullWidth
              onChange={handleChange}
              value={values.title}
              style={{ marginBottom: '2em' }}
            />

            <br />
            <Editor
              apiKey="v584hww3w8wptbmaeut4jfadamlqep3ckrn09rek5vmm5mhq"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue="<p>Type here....</p>"
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  // 'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  // 'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  // 'code',
                  'help',
                  // 'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            <div style={{ marginTop: '1em' }}>
              <p>
                <b>Select Article Image:</b>
              </p>
              <input
                type="file"
                className={classes.button}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadImage}
              >
                <span> {btnMessage}</span>
              </Button>
            </div>

            <div style={{ marginTop: '1em' }}>
              <p>
                <b>Select Article Video:</b>
              </p>
              <input
                type="file"
                className={classes.button}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadVideo}
              >
                <span> {btnMessageVideo}</span>
              </Button>
            </div>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" onClick={handleSave}>
              {pending ? <span>Processing </span> : <span> Publish blog </span>}
            </Button>
          </CardActions>
        </form>
      </Card>
    </>
  )
}

export default AddBlog
