import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import TextField from '@material-ui/core/TextField'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import { CSVLink } from 'react-csv'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  starttime: string
  endtime: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const AllTransactionsTable: React.FC<IProps> = (className, ...rest) => {
  const classes = useStyles()

  const date = new Date()

  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const yesterdate = new Date()

  yesterdate.setDate(yesterdate.getDate() - 1)

  const yesterday = yesterdate.getFullYear() +
    '-' +
    ((yesterdate.getMonth() + 1) > 9 ? (yesterdate.getMonth() + 1) : '0' + (yesterdate.getMonth() + 1)) +
    '-' +
    (yesterdate.getDate() > 9 ? (yesterdate.getDate()) : '0' + (yesterdate.getDate())) +
    ' T ' +
    (yesterdate.getHours() > 9 ? yesterdate.getHours() : '0' + yesterdate.getHours()) +
    ':' +
    (yesterdate.getMinutes() > 9 ? yesterdate.getMinutes() : '0' + yesterdate.getMinutes())


  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    starttime: yesterday,
    endtime: today,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination
  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('QuikkTransactions')
        .where('Date', '>=', formState.starttime)
        .where('Date', '<=', formState.endtime)
        .orderBy('Date', 'desc')

        .get()

      const list: any = []

      data.forEach(doc => {
        const { Amount, Date, Sender_no, Type, Processed, Hide } = doc.data()

        if (Hide) {
          console.log('Ignore')
        } else {
          list.push({
            key: doc.id,
            Amount: Amount,
            Date: Date,
            Sender_no: Sender_no,
            Type: Type,
            Processed: Processed,
          })
        }
      })

      setFormState({
        ...formState,
        array: list,
      })
      setIsloading(false)
    }

    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let customerIdSearch: any = row.CustomerId
        let phoneNumberSearch: any = row.Sender_no
        let dateSearch: any = row.Date
        let typeSearch: any = row.Type

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(customerIdSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(dateSearch) ||
          expression.test(typeSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleChangeProcessed = e => {
    e.preventDefault()

    firestore
      .collection('QuikkTransactions')
      .doc(e.target.name)
      .update({
        Processed: e.target.checked,
      })
      .then(() => {
        alert('Transaction Processed')
      })
      .then(() => {
        handleRefresh()
      })
  }

  const handleChange = (e: any) => {
    const date = new Date(e.target.value)

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    if (e.target.name === 'starttime') {
      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const headers = [
    { label: 'M-PESA Transaction code', key: 'key' },
    { label: 'Sender_no', key: 'Sender_no' },
    { label: 'Amount', key: 'Amount' },
    { label: 'Type', key: 'Type' },
    { label: 'Date', key: 'Date' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'AllTransactions(' + today + ').csv',
  }

  return (
    <>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>
              ALL TRANSACTIONS: {formState.array.length}
            </h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink onClick={handleRefresh} {...csvReport}>
                Export to CSV
              </CSVLink>
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <div className={classes.row}>
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="starttime"
              label="Start Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="endtime"
              label="End Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card className={classes.box}>
        <CardContent>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>M-PESA TRANSACTION ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Amount</b>
                  </TableCell>
                  <TableCell>
                    <b>Sender No.</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Processed</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.Amount}</TableCell>
                        <TableCell>{data.Sender_no}</TableCell>
                        <TableCell>{data.Date}</TableCell>
                        <TableCell>{data.Type}</TableCell>
                        <TableCell>
                          <Switch
                            disabled
                            checked={data.Processed}
                            onChange={e => handleChangeProcessed(e)}
                            name={data.key}
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default AllTransactionsTable
