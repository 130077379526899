import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { CardContent, Button, MenuItem } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'
import { convertDate } from '../../../utils/date_convert'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  productDropdown: any[]
  categoryDropdown: any[]
  imgUrls: any[]
  GB: boolean
  refresh: number
  order: number
  open: boolean
  displayImages: boolean
  displayParams: boolean
  Tab: string
  productID: string
  category: string
  qtySold: number
  HVAPrice: string
  GBProdID: string
  imgUrl: string
  GBLimit: number
  GBDailyLimit: number
  ussdOrder: string
  name: string
  quantities: string
  desc: string
  price: number
  GBPrice: number
  originalPrice: number
  targetQty: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '50%',
  },
  imageCell: {
    width: '25%',
  },
  textarea: {
    width: '100%',
  },
  button: {
    padding: '20px',
  },

  imageEdit: {
    display: 'block',
    maxWidth: '100%',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const StockControlTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    productDropdown: [],
    categoryDropdown: [],
    imgUrls: [],
    GB: false,
    displayImages: false,
    displayParams: false,
    Tab: 'ALL',
    refresh: 0,
    order: 0,
    open: false,
    productID: 'T' + Math.floor(100000000 + Math.random() * 900000000),
    GBLimit: 0,
    GBDailyLimit: 0,
    ussdOrder: '',
    qtySold: 0,
    HVAPrice: '',
    category: '',
    GBProdID: '',
    imgUrl: '',
    name: '',
    desc: '',
    quantities: '',
    price: 0,
    GBPrice: 0,
    originalPrice: 0,
    targetQty: 0,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 30

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  const storage = firebase.storage()

  let image: File

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const list: any = []

      const productArray: any = []

      const tabList: any = []

      firestore
        .collection('Products')
        .orderBy('Order', 'asc')
        .get()
        .then(data => {
          data.forEach(doc => {
            const {
              Name,
              Description,
              ImgUrl,
              Order,
              OriginalPrice,
              Price,
              Active,
              QtySold,
              USSD,
              USSDOrder,
              WhatsApp,
              Special,
              SpecialOffer,
              GBProdID,
              GBLimit,
              Quantities,
              GroupBuy,
              GBPOD,
              TargetQty,
              LimitedQty,
              Bulk,
              Category,
              VAT,
              ZeroVAT,
              GBPrice,
              GBDailyLimit,
            } = doc.data()

            const fields = () => {
              list.push({
                key: doc.id,
                name: Name,
                desc: Description,
                imgUrl: ImgUrl,
                order: Order,
                price: Price,
                originalPrice: OriginalPrice,
                qtySold: QtySold,
                active: Active,
                groupBuy: GroupBuy,
                gbpod: GBPOD,
                gbProdID: GBProdID,
                gbPrice: GBPrice,
                GBLimit: GBLimit,
                GBDailyLimit,
                quantities: Quantities,
                USSD: USSD,
                VAT: VAT,
                ZeroVAT: ZeroVAT,
                WhatsApp,
                USSDOrder: USSDOrder,
                bulk: Bulk,
                category: Category,
                targetQty: TargetQty,
                LimitedQty: LimitedQty,
                special: Special,
                SpecialOffer: SpecialOffer,
                color:
                  parseInt(TargetQty) > QtySold
                    ? 'green'
                    : GroupBuy
                    ? parseInt(GBLimit) > QtySold
                      ? 'green'
                      : 'red'
                    : 'red',
              })

              if (GroupBuy) {
                productArray.push(
                  <MenuItem id={Price} value={doc.id}>
                    {' '}
                    {doc.id}: {Description} @ {Price}
                  </MenuItem>
                )
              }
            }

            if (formState.Tab === 'ALL') {
              fields()
            } else if (formState.Tab === 'GroupBuy' && GroupBuy) {
              fields()
            } else if (formState.Tab === 'Active' && Active) {
              fields()
            } else if (formState.Tab === 'BulkBuy' && Bulk) {
              fields()
            } else if (formState.Tab === 'Special' && Special) {
              fields()
            } else if (formState.Tab === 'USSD' && USSD) {
              fields()
            } else if (formState.Tab === 'WhatsApp' && WhatsApp) {
              fields()
            }
          })
        })
        .then(() => {
          firestore
            .collection('SKUCategories')
            .get()
            .then(data => {
              data.forEach(doc => {
                const { Name } = doc.data()

                console.log('YOOWW ', Name, doc.id)

                tabList.push(<MenuItem value={doc.id}>{Name}</MenuItem>)
              })
            })
        })
        .then(() => {
          console.log(tabList)

          setFormState({
            ...formState,
            categoryDropdown: tabList,
            array: list,
            productDropdown: productArray,
          })
          setIsloading(false)
        })

      //
    }

    fetchData()
  }, [formState.refresh, formState.Tab])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let nameSearch: any = row.name
        let descSearch: any = row.desc

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(nameSearch) ||
          expression.test(descSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = () => {
    setFormState({
      ...formState,
      open: true!,
    })
  }

  const handleEdit = (e, id, name, desc, targetQty, gbLimit, qtySold, GB) => {
    console.log(GB)
    setFormState({
      ...formState,
      open: true!,
      productID: id,
      name: name,
      desc: desc,
      GBLimit: gbLimit === undefined ? 0 : gbLimit,
      targetQty: targetQty,
      qtySold: qtySold,
      GB: GB,
    })
  }

  const addProduct = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        open: false!,
        productID: '',
        name: '',
        desc: '',
        GBLimit: 0,
        targetQty: 0,
        qtySold: 0,
        GB: false,
      })
      // handleRefresh()
    }

    const handleChange = (e: any) => {
      if (e.target.id === 'targetQty') {
        setFormState({
          ...formState,
          targetQty: e.target.value,
        })
      } else if (e.target.id === 'gbLimit') {
        setFormState({
          ...formState,
          GBLimit: e.target.value,
        })
      }
    }

    const handleAddProduct = (e, sendWA) => {
      if (formState.targetQty === 0) {
        return alert('Input Total Stock Limit')
      } else {
        const data = firestore.collection('Products').doc(formState.productID)

        data
          .get()
          .then(doc => {
            if (doc.exists) {
              if (formState.GB) {
                data
                  .update({
                    GBLimit: formState.GBLimit,
                    TargetQty: formState.targetQty,
                  })
                  .then(() => {
                    const date = new Date()

                    const today =
                      date.getFullYear() +
                      '-' +
                      (date.getMonth() + 1 > 9
                        ? date.getMonth() + 1
                        : '0' + (date.getMonth() + 1)) +
                      '-' +
                      (date.getDate() > 9
                        ? date.getDate()
                        : '0' + date.getDate()) +
                      ' T ' +
                      (date.getHours() > 9
                        ? date.getHours()
                        : '0' + date.getHours()) +
                      ':' +
                      (date.getMinutes() > 9
                        ? date.getMinutes()
                        : '0' + date.getMinutes())

                    firestore
                      .collection('StockOut')
                      .where('Restocked', '==', false)
                      .where('ProductID', '==', formState.productID)
                      .get()
                      .then(data => {
                        data.forEach(doc => {
                          firestore
                            .collection('StockOut')
                            .doc(doc.id)
                            .update({
                              Restocked: true,
                              RestockDate: today,
                              RestockQty: formState.GBLimit - formState.qtySold,
                            })
                        })
                      })
                  })
                  .then(() => {
                    const logRef =
                      'LOG' + Math.floor(100000000 + Math.random() * 900000000)

                    firestore
                      .collection('AdminLogs')
                      .doc(logRef)
                      .set({
                        LogId: logRef,
                        userEmail: userEmail,
                        activity:
                          'Updated stockout out GB limit and tartget quantity',
                        date_created: convertDate(new Date()),
                        type: 'Update',
                        record: {
                          Restocked: true,
                          RestockDate: convertDate(new Date()),
                          RestockQty: formState.GBLimit - formState.qtySold,
                        },
                      })
                      .catch(err => console.log(err.message))
                  })
                  .then(() => {
                    handleClose()
                  })
              } else {
                data
                  .update({
                    TargetQty: formState.targetQty,
                  })
                  .then(() => {
                    const date = new Date()

                    const today =
                      date.getFullYear() +
                      '-' +
                      (date.getMonth() + 1 > 9
                        ? date.getMonth() + 1
                        : '0' + (date.getMonth() + 1)) +
                      '-' +
                      (date.getDate() > 9
                        ? date.getDate()
                        : '0' + date.getDate()) +
                      ' T ' +
                      (date.getHours() > 9
                        ? date.getHours()
                        : '0' + date.getHours()) +
                      ':' +
                      (date.getMinutes() > 9
                        ? date.getMinutes()
                        : '0' + date.getMinutes())

                    firestore
                      .collection('StockOut')
                      .where('Restocked', '==', false)
                      .where('ProductID', '==', formState.productID)
                      .get()
                      .then(data => {
                        data.forEach(doc => {
                          firestore
                            .collection('StockOut')
                            .doc(doc.id)
                            .update({
                              Restocked: true,
                              RestockDate: today,
                              RestockQty:
                                formState.targetQty - formState.qtySold,
                            })
                        })
                      })
                      .then(() => {
                        const logRef =
                          'LOG' +
                          Math.floor(100000000 + Math.random() * 900000000)

                        firestore
                          .collection('AdminLogs')
                          .doc(logRef)
                          .set({
                            LogId: logRef,
                            userEmail: userEmail,
                            activity: 'Updated stockout target quantity limit',
                            date_created: convertDate(new Date()),
                            type: 'Update',
                            record: {
                              Restocked: true,
                              RestockDate: convertDate(new Date()),
                              RestockQty:
                                formState.targetQty - formState.qtySold,
                            },
                          })
                          .catch(err => console.log(err.message))
                      })
                  })
                  .then(() => {
                    handleClose()
                  })
              }
            }
          })
          .then(() => {
            if (sendWA) {
              firestore
                .collection('Products')
                .doc(formState.productID)
                .get()
                .then((doc: any) => {
                  const { Description } = doc.data()

                  const desc = [
                    {
                      type: 'body',
                      parameters: [
                        {
                          type: 'text',
                          text: Description,
                        },
                      ],
                    },
                  ]

                  firestore
                    .collection('CommunityLeaders')
                    .get()
                    .then(dataLeaders => {
                      dataLeaders.forEach((docLeaders: any) => {
                        const { PhoneNumber } = docLeaders.data()

                        const fullNo = '254' + PhoneNumber.substring(1)

                        const url =
                          'https://api.tushop.io/api/v1/whatsApp/SendWhatsApp'

                        const reqBody = JSON.stringify({
                          pNo: fullNo,
                          templateId: 'restock_alert',
                          parameters: desc,
                        })

                        fetch(url, {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                          body: reqBody,
                        })
                          .then(response => {
                            if (response.status === 201) {
                              return response.json()
                            } else {
                              console.log('Response Status', response.status)
                              console.log('Response', JSON.stringify(response))
                            }
                          })
                          .then(response => {
                            console.debug(response)
                          })
                          .catch(error => {
                            console.error(error)
                          })
                      })
                    })
                })
            }
          })
      }
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Product Stock</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Product Datails below (ID: {formState.productID})
          </DialogContentText>
          <h1>{formState.name}</h1>
          <h2>{formState.desc}</h2>
          <h2> Total Sold Qty: {formState.qtySold}</h2>

          {formState.GB ? (
            <>
              <TextField
                autoFocus
                margin='dense'
                id='targetQty'
                label='Limit quantity per group buy (LVAs)'
                type='number'
                fullWidth
                onChange={handleChange}
                value={formState.targetQty}
              />
              <TextField
                autoFocus
                margin='dense'
                id='gbLimit'
                label='Total Stock Limit'
                type='number'
                fullWidth
                onChange={handleChange}
                value={formState.GBLimit}
              />
            </>
          ) : (
            <TextField
              autoFocus
              margin='dense'
              id='targetQty'
              label='Total Stock Limit'
              type='number'
              fullWidth
              onChange={handleChange}
              value={formState.targetQty}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={e => handleAddProduct(e, false)} color='primary'>
            Update Stock
          </Button>
          <Button onClick={e => handleAddProduct(e, true)} color='primary'>
            Update Stock & Notify CLs
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const tabs = () => {
    return (
      <Card
        className={classes.box}
        style={{ alignContent: 'center', alignItems: 'center' }}
      >
        <Tabs
          onChange={(e, newVal) => {
            setFormState({ ...formState, Tab: newVal })
          }}
          value={formState.Tab}
          variant='fullWidth'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab label={'ALL'} value={'ALL'} />
          <Tab label={'Group Buy'} value={'GroupBuy'} />
          <Tab label={'Active'} value={'Active'} />
          <Tab label={'Bulk Buy'} value={'BulkBuy'} />
          <Tab label={'Special'} value={'Special'} />
          <Tab label={'USSD'} value={'USSD'} />
          <Tab label={'WhatsApp'} value={'WhatsApp'} />
        </Tabs>
      </Card>
    )
  }

  return (
    <div className={classes.root}>
      {addProduct()}
      {tabs()}
      <br />
      <Card className={classes.box}>
        <CardContent>
          <Button color='primary' variant='contained' onClick={handleClickOpen}>
            Add Product
          </Button>
          <div className={classes.row}>
            <h1 className={classes.h1}>Products Stock Control</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Product ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                  <TableCell>
                    <b>Qty Sold</b>
                  </TableCell>
                  <TableCell>
                    <b>Target Qty</b>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow
                        key={data.key}
                        style={{ backgroundColor: data.color }}
                      >
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{data.desc}</TableCell>
                        <TableCell>{data.qtySold}</TableCell>
                        <TableCell>
                          {data.groupBuy ? data.GBLimit : data.targetQty}
                        </TableCell>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e =>
                              handleEdit(
                                e,
                                data.key,
                                data.name,
                                data.desc,
                                data.targetQty,
                                data.GBLimit,
                                data.qtySold,
                                data.groupBuy
                              )
                            }
                          >
                            <EditIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default StockControlTable
