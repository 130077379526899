import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'
import { convertDate } from '../../../utils/date_convert'

interface IProps {
  className?: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
}))

export interface IFormState {
  bidRequestID: string
  array: any[]
  arrayBids: any[]
  dropdownArray: any[]
  prodID: string
  refresh: number
  open: boolean
  openBids: boolean
  unit: string
  moq: number
}

const CommunityTable: React.FC<IProps> = () => {
  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')
  const [formState, setFormState] = useState<IFormState>({
    bidRequestID: 'BRQ' + Math.floor(100000000 + Math.random() * 900000000),
    array: [],
    arrayBids: [],
    dropdownArray: [],
    prodID: '',
    refresh: 0,
    open: false,
    openBids: false,
    unit: '',
    moq: 0,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  useEffect(() => {
    const fetchData = async () => {
      const data = await firestore
        .collection('BidRequest')
        .orderBy('DateCreated', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          Unit,
          Processed,
          ProductTypeName,
          ApprovedQty,
          ProductTypeID,
          Quantity,
          DateCreated,
        } = doc.data()
        list.push({
          key: doc.id,
          Unit: Unit,
          date: DateCreated,
          processed: Processed ? true : false,
          ProductTypeID: ProductTypeID,
          ProductTypeName: ProductTypeName,
          ApprovedQty: ApprovedQty,
          MOQ: Quantity,
        })
      })

      setFormState({
        ...formState,
        array: list,
      })
    }

    const fetchZoneDropDown = async () => {
      const data = await firestore.collection('ProdType').get()

      data.forEach(doc => {
        const { Type } = doc.data()

        formState.dropdownArray.push(<MenuItem value={doc.id}>{Type}</MenuItem>)
      })
    }

    fetchData()
    fetchZoneDropDown()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let productSearch: any = row.ProductTypeName

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(productSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = () => {
    setFormState({
      ...formState,
      open: true!,
    })
  }

  const handleEdit = (e, key, Unit, ProductTypeID, MOQ) => {
    setFormState({
      ...formState,
      open: true!,
      bidRequestID: key,
      unit: Unit,
      prodID: ProductTypeID,
      moq: MOQ,
    })
  }

  const handleDisplayBids = (e, brqID) => {
    firestore
      .collection('Bids')
      .where('RequestID', '==', brqID)
      .where('Processed', '==', false)
      .orderBy('Price', 'asc')
      .get()
      .then(data => {
        const list: any = []

        if (data.empty) {
          alert('No bids under this request yet')
        } else {
          data.forEach(doc => {
            const {
              RequestID,
              FarmerID,
              FarmerName,
              FarmerPNo,
              ProductID,
              ProductName,
              Quantity,
              Price,
              Accepted,
              Processed,
              SupplyDate,
              DateCreated,
            } = doc.data()

            list.push({
              key: doc.id,
              FarmerID: FarmerID,
              FarmerName: FarmerName,
              FarmerPNo: FarmerPNo,
              ProductID: ProductID,
              ProductName: ProductName,
              Quantity: Quantity,
              Price: Price,
              Accepted: Accepted,
              Processed: Processed,
              RequestID: RequestID,
              SupplyDate: SupplyDate,
              DateCreated: DateCreated,
            })
          })

          setFormState({
            ...formState,
            arrayBids: list,
            openBids: true,
          })
        }
      })
  }

  const handleDelete = (e, id) => {
    console.log('ID: ' + id)
    const deletedUserRecord = id
    firestore
      .collection('BidRequest')
      .doc(id)
      .delete()
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Deleted a Bid request',
            date_created: convertDate(new Date()),
            type: 'Delete',
            record: { BID: deletedUserRecord },
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Request  Deleted')
        handleRefresh()
      })
  }

  const addField = () => {
    const handleClose = () => {
      console.log('CLOSE')

      setFormState({
        ...formState,
        open: false!,
        bidRequestID: 'BRQ' + Math.floor(100000000 + Math.random() * 900000000),
        prodID: '',
        unit: '',
        moq: 0,
        refresh: formState.refresh + 1,
      })
    }

    const handleChange = (e: any) => {
      if (e.target.id === 'unit') {
        setFormState({
          ...formState,
          unit: e.target.value,
        })
      } else if (e.target.name === 'product') {
        setFormState({
          ...formState,
          prodID: e.target.value,
        })
      } else if (e.target.id === 'moq') {
        setFormState({
          ...formState,
          moq: e.target.value,
        })
      }

      console.log('CHANGED!')
    }

    const handleAddFarmer = () => {
      const date = new Date()

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
        ':' +
        (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

      if (formState.unit === '') {
        alert('Please enter unit of product required')
      } else if (formState.prodID === '') {
        alert('Please select farmer product')
      } else if (formState.moq === 0) {
        alert('Please enter product quantity required')
      } else {
        firestore
          .collection('BidRequest')
          .where('ProductTypeID', '==', formState.prodID)
          .where('Processed', '==', false)
          .get()
          .then(docCheck => {
            if (docCheck.empty) {
              firestore
                .collection('ProdType')
                .doc(formState.prodID)
                .get()
                .then((doc: any) => {
                  const { Type } = doc.data()

                  firestore
                    .collection('BidRequest')
                    .doc(formState.bidRequestID)
                    .set({
                      Unit: formState.unit,
                      Processed: false,
                      ProductTypeName: Type,
                      ProductTypeID: formState.prodID,
                      Quantity: formState.moq,
                      ApprovedQty: 0,
                      DateCreated: today,
                    })
                    .then(() => {
                      const logRef =
                        'LOG' +
                        Math.floor(100000000 + Math.random() * 900000000)

                      firestore
                        .collection('AdminLogs')
                        .doc(logRef)
                        .set({
                          LogId: logRef,
                          userEmail: userEmail,
                          activity: 'Created a bid request',
                          date_created: convertDate(new Date()),
                          type: 'Create',
                          record: {
                            Unit: formState.unit,
                            Processed: false,
                            ProductTypeName: Type,
                            ProductTypeID: formState.prodID,
                            Quantity: formState.moq,
                            ApprovedQty: 0,
                            DateCreated: today,
                          },
                        })
                        .catch(err => console.log(err.message))
                    })
                    .then(() => {
                      alert('Request Added')

                      handleClose()
                    })
                })
            } else {
              alert('An active bid request already exists.')
            }
          })
      }

      console.log('ADD CL')
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Add Bid Request Datails
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add New Farmer Bid Request Data below (ID: {formState.bidRequestID})
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id='Productdropdown'>Product</InputLabel>
            <Select
              labelId='Productdropdown'
              name='product'
              onChange={handleChange}
              value={formState.prodID}
            >
              {formState.dropdownArray}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin='dense'
            id='unit'
            label='Unit'
            type='text'
            fullWidth
            onChange={handleChange}
            value={formState.unit}
          />
          <TextField
            autoFocus
            margin='dense'
            id='moq'
            label='Quantity'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.moq}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleAddFarmer} color='primary'>
            Add Request
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const displayBids = () => {
    const handleClose = () => {
      console.log('CLOSE')

      setFormState({
        ...formState,
        openBids: false!,
        arrayBids: [],
        refresh: formState.refresh + 1,
      })
    }

    const handleChangeActivity = e => {
      e.preventDefault()

      const url = 'https://api.tushop.io/api/v1/bids/processBid'

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          checked: e.target.checked,
          bidID: e.target.name,
        }),
      })
        .then(response => {
          if (response.status === 201 || response.status === 500) {
            return response.json()
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(response => {
          console.debug(response)

          alert(response.message)

          console.log(response)

          handleClose()

          handleRefresh()
        })
        .catch(error => {
          console.error(error)
          console.log(error)
        })
    }

    const handleAcceptBid = (e, reqID, qty, farmerID) => {
      e.preventDefault()

      const url = 'https://api.tushop.io/api/v1/bids/acceptBid'

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          checked: e.target.checked,
          reqID: reqID,
          qty: qty,
          bidID: e.target.name,
          farmerID: farmerID,
        }),
      })
        .then(response => {
          if (response.status === 201 || response.status === 500) {
            return response.json()
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(response => {
          console.debug(response)

          alert(response.message)

          handleClose()

          handleRefresh()
        })
        .catch(error => {
          console.error(error)
        })
    }

    return (
      <Dialog
        fullScreen={true}
        open={formState.openBids}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          All Bids details for Bid Request: {formState.bidRequestID}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Bid ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Farmer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Product</b>
                  </TableCell>
                  <TableCell>
                    <b>Quantity</b>
                  </TableCell>
                  <TableCell>
                    <b>Price</b>
                  </TableCell>
                  <TableCell>
                    <b>Processed</b>
                  </TableCell>
                  <TableCell>
                    <b>Approved</b>
                  </TableCell>
                  <TableCell>
                    <b>Supply Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.arrayBids.map(data => (
                  <TableRow key={data.key}>
                    <TableCell>{data.key}</TableCell>
                    <TableCell>{data.FarmerID}</TableCell>
                    <TableCell>{data.FarmerName}</TableCell>
                    <TableCell>{data.FarmerPNo}</TableCell>
                    <TableCell>{data.ProductName}</TableCell>
                    <TableCell>{data.Quantity}</TableCell>
                    <TableCell>{data.Price}</TableCell>

                    <TableCell>
                      <Switch
                        checked={data.Processed}
                        onChange={e => handleChangeActivity(e)}
                        name={data.key}
                        color='primary'
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={data.Accepted}
                        onChange={e =>
                          handleAcceptBid(
                            e,
                            data.RequestID,
                            data.Quantity,
                            data.FarmerID
                          )
                        }
                        name={data.key}
                        color='primary'
                      />
                    </TableCell>
                    <TableCell>{data.SupplyDate}</TableCell>
                    <TableCell>{data.DateCreated}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained' color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      {addField()}
      {displayBids()}
      <Card className={classes.box}>
        <CardContent>
          <Button color='primary' variant='contained' onClick={handleClickOpen}>
            Add Bid Request
          </Button>
          <div className={classes.row}>
            <h1 className={classes.h1}>Bid Request</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />

          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Bid Request ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Product</b>
                  </TableCell>
                  <TableCell>
                    <b>Unit</b>
                  </TableCell>
                  <TableCell>
                    <b>MOQ</b>
                  </TableCell>
                  <TableCell>
                    <b>Approved Quantity</b>
                  </TableCell>
                  <TableCell>
                    <b>Processed</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.array.map(data => (
                  <TableRow key={data.key}>
                    <TableCell>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={e => handleDisplayBids(e, data.key)}
                      >
                        {data.key}
                      </Button>
                    </TableCell>
                    <TableCell>{data.ProductTypeName}</TableCell>
                    <TableCell>{data.Unit}</TableCell>
                    <TableCell>{data.MOQ}</TableCell>
                    <TableCell>{data.ApprovedQty}</TableCell>
                    <TableCell>
                      <Switch
                        checked={data.processed}
                        // onChange={e => handleChangeActivity(e)}
                        name={data.key}
                        color='primary'
                      />
                    </TableCell>
                    <TableCell>{data.date}</TableCell>
                    <TableCell>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={e =>
                          handleEdit(
                            e,
                            data.key,
                            data.Unit,
                            data.ProductTypeID,
                            data.MOQ
                          )
                        }
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color='secondary'
                        variant='contained'
                        onClick={e => handleDelete(e, data.key)}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  )
}

export default CommunityTable
