import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  arrayReferred: any[]
  refresh: number
  displayCart: boolean
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const ReferralTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')

  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    arrayReferred: [],
    refresh: 0,
    displayCart: false,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('Customers')
        .orderBy('Referrals', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const { Name, Referrals, ReferredIDs, ReferralLink } = doc.data()

        console.log(doc.id, 'PIMBIIIIIIIIIIIIIIIIIIIIS', ReferredIDs)

        list.push({
          UID: doc.id,
          Name: Name,
          Referrals: Referrals,
          ReferralIDs: ReferredIDs,
          ReferralLink: ReferralLink,
        })
      })

      setFormState({
        ...formState,
        array: list,
      })
      setIsloading(false)
    }
    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let uidSearch: any = row.UID
        let nameSearch: any = row.Name
        let referralSearch: any = row.Referrals
        let refLinkSearch: any = row.ReferralLink
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(uidSearch) ||
          expression.test(nameSearch) ||
          expression.test(referralSearch) ||
          expression.test(refLinkSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleDisplayCart = (e, referralIDs) => {
    const firestore = firebase.firestore()

    const list: any = []

    const fetchData = async cusID => {
      const data = await firestore.collection('Customers').get()

      data.forEach(doc => {
        if (doc.id === cusID) {
          const {
            Address,
            City,
            Email,
            Name,
            Neighborhood,
            PhoneNumber,
            ReferralLink,
          } = doc.data()

          list.push({
            UID: doc.id,
            Name: Name,
            Address: Address,
            Neighborhood: Neighborhood,
            City: City,
            PhoneNumber: PhoneNumber,
            Email: Email,
            ReferralLink: ReferralLink,
          })
        }
      })

      setFormState({
        ...formState,
        arrayReferred: list,
        displayCart: true,
      })
    }

    referralIDs.forEach(cusID => {
      try {
        const obj = JSON.parse(cusID)

        fetchData(obj.CustomerID)
      } catch (error) {
        fetchData(cusID)
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }

      // fetchData(cusID.CustomerID);
      // fetchData(cusID);

      // }
    })
  }

  const handleCloseCart = () => {
    setFormState({
      ...formState,
      displayCart: false,
      arrayReferred: [],
    })
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <Dialog
            open={formState.displayCart}
            onClose={handleCloseCart}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>
              Customers Who used the referral Link
            </DialogTitle>

            <DialogContent>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow
                    style={{
                      height: '5px',
                      width: '100%',
                      backgroundColor: '#d3d3d3',
                    }}
                  >
                    <TableCell>
                      <b>UID</b>
                    </TableCell>
                    <TableCell>
                      <b>Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Address</b>
                    </TableCell>
                    <TableCell>
                      <b>Neighborhood</b>
                    </TableCell>
                    <TableCell>
                      <b>City</b>
                    </TableCell>
                    <TableCell>
                      <b>Phone Number</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formState.arrayReferred.map(data => (
                    <TableRow key='row1'>
                      <TableCell>{data.UID}</TableCell>
                      <TableCell>{data.Name}</TableCell>
                      <TableCell>{data.Address}</TableCell>
                      <TableCell>{data.Neighborhood}</TableCell>
                      <TableCell>{data.City}</TableCell>
                      <TableCell>{data.PhoneNumber}</TableCell>
                      <TableCell>{data.Email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCart} color='primary'>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <div className={classes.row}>
            <h1 className={classes.h1}>Referrals</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>UID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Referrals</b>
                  </TableCell>
                  <TableCell>
                    <b>Who?</b>
                  </TableCell>
                  <TableCell>
                    <b>Referral Link</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.UID}>
                        <TableCell>{data.UID}</TableCell>
                        <TableCell>{data.Name}</TableCell>
                        <TableCell>{data.Referrals}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e =>
                              handleDisplayCart(e, data.ReferralIDs)
                            }
                          >
                            Referred Details
                          </Button>
                        </TableCell>
                        <TableCell>
                          <a href={data.ReferralLink} target='_blank'>
                            {data.ReferralLink}
                          </a>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default ReferralTable
