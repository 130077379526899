import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Button, MenuItem } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  table: any[]
  productDropdown: any[]
  refresh: number
  order: number
  open: boolean
  loading: boolean
  displayCustomer: boolean
  openCart: boolean
  Selectcustomer: any[]
  displayCart: boolean
  cartItems: any[]
  cartID: string
  ProductID: string
  targetQty: number
  soldQty: number
  customers: any[]
  GroupBuyID: string
  qty: string
  imgUrl: string
  name: string
  starttime: string
  endtime: string
  desc: string
  price: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '50%',
  },
  imageCell: {
    width: '25%',
  },
  textarea: {
    width: '100%',
  },
  button: {
    padding: '20px',
  },

  imgStyle: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
  },

  price: {
    textAlign: 'right',
    width: '1%',
    color: 'green',
    height: '5px',
    padding: '0px',
  },

  rowStyle: {
    textAlign: 'center',
    width: '100%',
    marginTop: 'auto',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const ProductTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')
  const [isLoadingTable, setIsloadingTable] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    table: [],
    productDropdown: [],
    refresh: 0,
    order: 0,
    targetQty: 0,
    soldQty: 0,
    open: false,
    openCart: false,
    loading: false,
    displayCart: false,
    displayCustomer: false,
    cartItems: [],
    cartID: '',
    Selectcustomer: [],
    customers: [],
    qty: '0',
    GroupBuyID: 'GB' + Math.floor(100000000 + Math.random() * 900000000),
    ProductID: '',
    imgUrl: '',
    name: '',
    starttime: '',
    endtime: '',
    desc: '',
    price: 0,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.table.length / PER_PAGE)
  const _DATA = usePagination(formState.table, PER_PAGE)
  //console.log('entire group by', _DATA)
  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }
  //end of handling page pagination

  const storage = firebase.storage()

  // let image: File;

  useEffect(() => {
    setIsloadingTable(true)
    const fetchTableData = async () => {
      const data = await firestore
        .collection('GBQueue')
        .orderBy('CreatedAt', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          CustomerID,
          GBID,
          ProductID,
          Quantity,
          CreatedAt,
          Refund,
          Processed,
        } = doc.data()

        list.push({
          key: doc.id,
          ProductID: ProductID,
          CustomerID: CustomerID,
          GBID: GBID,
          Quantity: Quantity,
          Refund,
          CreatedAt: CreatedAt,
          Processed: Processed,
        })
      })

      setFormState({
        ...formState,
        table: list,
      })
      setIsloadingTable(false)
    }

    fetchTableData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.table.filter(row => {
        let keySearch: any = row.key
        let productIdSearch: any = row.ProductID
        let customerIdSearch: any = row.CustomerID
        let groupIdSearch: any = row.GBID
        let startDateSearch: any = row.CreatedAt
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(productIdSearch) ||
          expression.test(customerIdSearch) ||
          expression.test(groupIdSearch) ||
          expression.test(startDateSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, table: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const fetchDropdownData = async () => {
    const data = await firestore.collection('CommunityLeaders').where("HVA", "==", true).get()

    data.forEach(doc => {
      const { Address, Name, PhoneNumber, Type } = doc.data()

      formState.customers.push({
        UID: doc.id,
        Name: Name,
        Address: Address,
        PhoneNumber: PhoneNumber,
      })
    })
  }

  const fetchProductData = async () => {
    const data = await firestore
      .collection('Products')
      .orderBy('Order', 'asc')
      .get()

    const list: any = []

    data.forEach(doc => {
      const { Name, Description, ImgUrl, Price, QtySold, Active, Special } =
        doc.data()

      formState.array.push({
        key: doc.id,
        name: Name,
        desc: Description,
        imgUrl: ImgUrl,
        qtySold: QtySold,
        price: Price,
        array: [1, 2, 3, 4, 5, 10],
      })
    })
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = async () => {
    setFormState({
      ...formState,
      loading: true,
    })

    await fetchDropdownData()

    setFormState({
      ...formState,
      open: true!,
      loading: false,
    })
  }

  const handleCloseCart = () => {
    setFormState({
      ...formState,
      openCart: false!,
      GroupBuyID: 'GB' + Math.floor(100000000 + Math.random() * 900000000),
      customers: [],
      array: [],
      Selectcustomer: [],
      loading: false,
      refresh: formState.refresh + 1,
    })
    // handleRefresh()
  }

  const DialogCartAssigner = () => {
    const handleAddCustomer = (e, UID) => {
      if (e.target.checked) {
        formState.Selectcustomer.push(UID)

        console.log('CUSTOMA', formState.Selectcustomer)
      } else {
        const pos = formState.Selectcustomer.indexOf(UID)

        formState.Selectcustomer.splice(pos, 1)

        console.log('CUSTOMB', formState.Selectcustomer)
      }
    }

    const handleProceedToCart = async () => {
      setFormState({
        ...formState,
        loading: true,
      })

      await fetchProductData()

      setFormState({
        ...formState,
        open: false!,
        openCart: true!,
        loading: false,
      })
    }

    const handleClose = () => {
      setFormState({
        ...formState,
        open: false!,
        customers: [],
        Selectcustomer: [],
      })
      // handleRefresh()
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Assign Customers to Cart
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the community Leader you wish to assign the offer to
          </DialogContentText>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>Mark</TableCell>
                  <TableCell>
                    <b>UID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="all">
                  <TableCell>
                    <FormControlLabel
                      label="ALL"
                      control={
                        <Checkbox onChange={e => handleAddCustomer(e, 'ALL')} />
                      }
                    />
                  </TableCell>
                </TableRow>
                {formState.customers.map(data => (
                  <TableRow key={data.UID}>
                    <TableCell>
                      <FormControlLabel
                        label=""
                        control={
                          <Checkbox
                            onChange={e => handleAddCustomer(e, data.UID)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>{data.UID}</TableCell>
                    <TableCell>{data.Name}</TableCell>
                    <TableCell>{data.Address}</TableCell>
                    <TableCell>{data.PhoneNumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleProceedToCart} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const loader = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        loading: false,
      })
      // handleRefresh()
    }

    return (
      <Dialog
        open={formState.loading}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <LoadingSpinner />
          <p style={{ textAlign: 'center' }}>Please wait</p>
        </DialogContent>
      </Dialog>
    )
  }

  const DialogCart = () => {
    const handleChange = (e: any) => {
      console.log('CHANGE')

      if (e.target.name === 'productID') {
        setFormState({
          ...formState,
          ProductID: e.target.value,
        })
      } else if (e.target.name === 'starttime') {
        setFormState({
          ...formState,
          starttime: e.target.value,
        })
      } else if (e.target.name === 'endtime') {
        setFormState({
          ...formState,
          endtime: e.target.value,
        })
      } else if (e.target.id === 'targetQty') {
        setFormState({
          ...formState,
          targetQty: e.target.value,
        })
      } else if (e.target.id === 'price') {
        setFormState({
          ...formState,
          price: e.target.value,
        })
      }
    }

    const handleCreateCart = () => {
      const date = new Date()

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
        ':' +
        (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

      // ProductID, TargetQty, SoldQty, StartDate, EndDate, Leaders

      // const data = await firestore.collection("GroupBuy").orderBy("CreatedAt", "desc").get()

      firestore
        .collection('GroupBuy')
        .doc(formState.GroupBuyID)
        .set({
          ProductID: formState.ProductID,
          TargetQty: formState.targetQty,
          SoldQty: formState.soldQty,
          StartDate: formState.starttime,
          EndDate: formState.endtime,
          Leaders: formState.Selectcustomer,
          CreatedAt: today,
          Price: formState.price,
          POD: false,
        })
        .then(() => {
          console.log('GroupBuyID: ', formState.GroupBuyID)
          console.log('Customers: ', formState.Selectcustomer)
          console.log('ProductID: ', formState.ProductID)
          console.log('Target QTY: ', formState.targetQty)
          console.log('Start Time: ', formState.starttime)
          console.log('Price: ', formState.price)
          console.log('End Time: ', formState.endtime)

          handleCloseCart()
          handleRefresh()
        })
    }

    return (
      <Dialog
        open={formState.openCart}
        onClose={handleCloseCart}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Items to Cart</DialogTitle>
        <DialogContent>
          <DialogContentText>Select parameters for offer</DialogContentText>

          <FormControl fullWidth>
            <InputLabel id="CLdropdown">Product</InputLabel>
            <Select
              labelId="CLdropdown"
              name="productID"
              onChange={handleChange}
              value={formState.ProductID}
            >
              <MenuItem>--------Select Community Leader--------</MenuItem>
              {formState.productDropdown}
              {/* <MenuItem>Test Item1</MenuItem>
                <MenuItem>Test Item2</MenuItem> */}
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin="dense"
            id="targetQty"
            label="Target Quantity"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formState.targetQty}
          />

          <TextField
            autoFocus
            margin="dense"
            id="price"
            label="Price"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formState.price}
          />

          <TextField
            id="datetime-local"
            onChange={handleChange}
            name="starttime"
            label="Start Date/Time"
            type="datetime-local"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          <TextField
            id="datetime-local"
            onChange={handleChange}
            name="endtime"
            label="End Date/Time"
            type="datetime-local"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateCart} color="primary">
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleDisplayCustomer = (e, UID) => {
    let list: any = []
    firestore
      .collection('Customers')
      .doc(UID)
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = JSON.parse(JSON.stringify(doc.data()))

          list.push({
            UID: UID,
            Name: data.Name,
            LeaderID: data.LeaderID,
            Address: data.Address,
            Neighborhood: data.Neighborhood,
            City: data.City,
            PhoneNumber: data.PhoneNumber,
            Email: data.Email,
            ReferralLink: data.ReferralLink,
          })
        }
      })
      .then(() => {
        setFormState({
          ...formState,
          cartItems: list,
          displayCustomer: true,
        })
      })
  }

  const handleCloseCustomer = () => {
    setFormState({
      ...formState,
      cartItems: [],
      displayCustomer: false,
    })
  }

  const DialogCustomer = () => {
    return (
      <Dialog
        open={formState.displayCustomer}
        onClose={handleCloseCustomer}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1>Customer Info</h1>
        </DialogTitle>
        <DialogContent>
          {formState.cartItems.map(data => (
            <ul>
              <li>
                <b>UID: </b> {data.UID}
              </li>
              <li>
                <b>Name: </b> {data.Name}
              </li>
              <li>
                <b>Leader ID: </b> {data.LeaderID}
              </li>
              <li>
                <b>Address: </b> {data.Address}
              </li>
              <li>
                <b>Neighborhood: </b> {data.Neighborhood}
              </li>
              <li>
                <b>City: </b> {data.City}
              </li>
              <li>
                <b>Phone Number: </b> {data.PhoneNumber}
              </li>
              <li>
                <b>Email: </b> {data.Email}
              </li>
            </ul>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomer} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleRefund = (e, key) => {
    if (e.target.checked) {
      firestore
        .collection('GBQueue')
        .doc(key)
        .get()
        .then((doc: any) => {
          const { transactionID } = doc.data()

          firestore
            .collection('Transactions')
            .doc(transactionID)
            .get()
            .then((docTR: any) => {
              const { MPesaCode } = docTR.data()

              const url = 'https://api.tushop.io/api/v1/sendQuikkRefund'

              fetch(url, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  GBQID: key,
                  TRID: MPesaCode,
                }),
              })
                .then(response => {
                  if (response.status === 201 || response.status === 501) {
                    return response.json()
                  } else {
                    throw new Error('Something went wrong on api server!')
                  }
                })
                .then(response => {
                  alert(response.message)
                })
                .then(() => {
                  setFormState({
                    ...formState,
                    refresh: ++formState.refresh,
                  })
                })
                .catch(error => {
                  console.error(error)
                })
            })
        })
    } else {
      alert("Sorry... can't unprocess a refund")
    }

    console.log(e.target.checked)
    console.log(e.target.name)
  }

  return (
    <div className={classes.root}>
      {loader()}
      {DialogCustomer()}
      {DialogCartAssigner()}
      {DialogCart()}
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>
              Group Buy Queue: {formState.table.length}
            </h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />

          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Product ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Quantity</b>
                  </TableCell>
                  <TableCell>
                    <b>Group Buy ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Processed</b>
                  </TableCell>
                  <TableCell>
                    <b>Refunded</b>
                  </TableCell>
                  <TableCell>
                    <b>Created At</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingTable === false ? (
                  _DATA.currentData().map(data => {
                    console.log('group by', data)
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.ProductID}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e =>
                              handleDisplayCustomer(e, data.CustomerID)
                            }
                          >
                            {data.CustomerID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.Quantity}</TableCell>
                        <TableCell>{data.GBID}</TableCell>
                        <TableCell>
                          {data.Processed ? 'True' : 'False'}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.Refund}
                            onChange={e => handleRefund(e, data.key)}
                            name={data.key}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>{data.CreatedAt}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default ProductTable
