import Card from "@material-ui/core/Card"
import { CardContent } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/styles"
import Switch from "@material-ui/core/Switch"
import React, { useState, useEffect } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Button } from "@material-ui/core"
import RefreshIcon from "@material-ui/icons/Refresh"
import { ITheme } from "../../theme"
import FirebaseApp from "../../firebase/firebase"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import { CSVLink } from "react-csv"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CircularProgress from "@material-ui/core/CircularProgress"
import Pagination from "@material-ui/lab/Pagination"
import usePagination from "../../../utils/Pagination"
import SearchBar from "material-ui-search-bar"
interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  cartID: string
  ImgUrl: string
  totalItems: number
  cartItems: any[]
  displayCart: boolean
  displayCustomer: boolean
  displayImage: boolean
  grandTotal: string
  CodeIDSearch: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  box: {
    padding: "16px",
    justifyContent: "center",
  },
  tr: {
    background: "#D3D3D3",
  },
  h1: {
    textAlign: "center",
  },
  refresh: {
    marginLeft: "auto",
  },
  pager: {
    width: "100%",
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: "center",
  },
}))

const FailedTransactionsTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [searched, setSearched] = useState<any>("")

  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    totalItems: 0,
    cartID: "",
    ImgUrl: "",
    cartItems: [],
    displayCart: false,
    displayCustomer: false,
    displayImage: false,
    grandTotal: "",
    CodeIDSearch: "",
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  // firestore.collection("Customers").doc(UserID).get().then((doc) => {

  //   if(doc.exists){

  //     const data = JSON.parse(JSON.stringify(doc.data()))

  //   }
  // })

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection("CLOFeedback")
        .orderBy("DateOfFeedback", "desc")
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          DateOfFeedback,
          Feedback,
          CartID,
          CustomerID,
          CustomerName,
          ImgUrl,
          LeaderID,
          OrderID,
          Resolved,
        } = doc.data()

        list.push({
          FID: doc.id,
          LeaderID: LeaderID,
          OrderID: OrderID,
          CartID: CartID,
          CustomerID: CustomerID,
          CustomerName: CustomerName,
          ImgUrl: ImgUrl,
          Feedback: Feedback,
          Resolved: Resolved,
          Date: DateOfFeedback,
        })
      })

      setFormState({
        ...formState,
        array: list,
        totalItems: list.length,
      })
      setIsloading(false)
    }
    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== "") {
      const filteredRows = formState.array.filter(row => {
        let leaderIdSearch: any = row.LeaderID
        let customernameSearch: any = row.CustomerName
        let customerIdSearch: any = row.CustomerID
        let feedbackSearch: any = row.Feedback

        let unkown: any
        let expression = new RegExp(searchedVal, "gi")
        return (
          expression.test(leaderIdSearch) ||
          expression.test(customerIdSearch) ||
          expression.test(customernameSearch) ||
          expression.test(feedbackSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched("")
    if (searched === "" || searched.trim().length < 1) {
      console.log("OOP, nothing in your search bar")
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  const handleRefresh = () => {
    setFormState({
      ...formState,
      // totalItems: 0,
      refresh: formState.refresh + 1,
    })
  }

  // const date = new Date();
  // const today = date.getFullYear()+
  //   '-'+
  //   ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
  //   '-'+
  //   (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
  //   ' T '+
  //   (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+
  //   ":"+
  //   (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

  const handleDisplayLeader = (e, UID) => {
    let list: any = []
    firestore
      .collection("CommunityLeaders")
      .doc(UID)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          const { Name, PhoneNumber } = doc.data()

          list.push({
            UID: UID,
            Name: Name,
            PhoneNumber: PhoneNumber,
          })
        }
      })
      .then(() => {
        setFormState({
          ...formState,
          cartItems: list,
          displayCustomer: true,
        })
      })
  }

  const headers: any = [
    { label: "FID", key: "FID" },
    { label: "Customer ID", key: "CustomerID" },
    { label: "Feedback", key: "Feedback" },
    { label: "Leader ID", key: "LeaderID" },
    { label: "Order ID", key: "OrderID" },
    { label: "Cart ID", key: "CartID" },
    { label: "Date", key: "Date" },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: "CLO feedback.csv",
  }
  const handleDisplayCart = (e, cartID, Total) => {
    const firestore = firebase.firestore()

    const listCart: any = []

    let myTotal = 0

    console.log("CART AYDI: ", cartID)

    const fetchData = async () => {
      const data = await firestore.collection("Products").get()

      data.forEach(doc => {
        const { Name, Description, ImgUrl, Price } = doc.data()

        const shoppingCartRef = firebase
          .database()
          .ref("shoppingCarts/" + cartID + "/" + doc.id)
        shoppingCartRef.on("value", snapshot => {
          const data = snapshot.val()

          if (snapshot.exists()) {
            listCart.push({
              key: doc.id,
              name: Name,
              desc: Description,
              imgUrl: ImgUrl,
              price: Price,
              quantity: data.quantity,
              total: data.total,
            })

            myTotal = myTotal + parseInt(data.total)

            setFormState({
              ...formState,
              displayCart: true!,
              cartItems: listCart,
              cartID: cartID,
              grandTotal: myTotal.toString(),
            })

            // Code for adding grandtotal to cart record
          } else {
            console.log("No data available")
            // this.setState({
            //   emptyCart: true
            // })
          }
        })
      })
    }

    setFormState({
      ...formState,
      displayCart: true!,
    })
    fetchData()
  }

  const handleDisplayImage = (e, imgUrl) => {
    setFormState({
      ...formState,
      ImgUrl: imgUrl,
      displayImage: true,
    })
  }

  const handleChangeActivity = (e, key) => {
    e.preventDefault()

    const feedbackRef = firestore.collection("CLOFeedback").doc(key)

    feedbackRef
      .update({
        Resolved: e.target.checked,
      })
      .then(() => {
        alert("Document successfully updated!")
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error)
      })
  }

  const DialogCustomer = () => {
    const handleCloseCustomer = () => {
      setFormState({
        ...formState,
        cartItems: [],
        displayCustomer: false,
      })
    }

    return (
      <Dialog
        open={formState.displayCustomer}
        onClose={handleCloseCustomer}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1>Customer Info</h1>
        </DialogTitle>
        <DialogContent>
          {formState.cartItems.map(data => (
            <ul>
              <li>
                <b>CLID: </b> {data.UID}
              </li>
              <li>
                <b>Name: </b> {data.Name}
              </li>
              <li>
                <b>Phone Number: </b> {data.PhoneNumber}
              </li>
            </ul>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomer} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const DialogCart = () => {
    const handleCloseCart = () => {
      setFormState({
        ...formState,
        cartID: "",
        cartItems: [],
        displayCart: false,
        grandTotal: "",
      })
    }
    return (
      <Dialog
        open={formState.displayCart}
        onClose={handleCloseCart}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          CartID: {formState.cartID}
        </DialogTitle>

        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  height: "5px",
                  width: "100%",
                  backgroundColor: "#d3d3d3",
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>
                    <h2>Price</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Total</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formState.cartItems.map(data => (
                <TableRow key="row1">
                  <TableCell>{data.desc}</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{data.price}</TableCell>
                  <TableCell>
                    <b>{data.total}</b>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key="row2">
                <TableCell colSpan={3}>
                  <h1>GrandTotal</h1>
                </TableCell>
                <TableCell colSpan={2}>
                  <h1>{formState.grandTotal}</h1>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const DialogImage = () => {
    const handleCloseImage = () => {
      setFormState({
        ...formState,
        ImgUrl: "",
        displayImage: false,
      })
    }

    return (
      <Dialog
        open={formState.displayImage}
        onClose={handleCloseImage}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1>Feedback Image</h1>
        </DialogTitle>
        <DialogContent>
          <img src={formState.ImgUrl} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImage} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        {DialogCustomer()}
        {DialogCart()}
        {DialogImage()}
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>Feedback: {formState.totalItems}</h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              style={{ marginRight: "1em" }}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: "2em", marginTop: "1em" }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Feedback ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Feedback</b>
                  </TableCell>
                  <TableCell>
                    <b>Order ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Cart ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Image</b>
                  </TableCell>
                  <TableCell>
                    <b>Resolved?</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.FID}>
                        <TableCell>{data.FID}</TableCell>
                        <TableCell>
                          {" "}
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={e => handleDisplayLeader(e, data.LeaderID)}
                          >
                            {data.LeaderID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.Feedback}</TableCell>
                        <TableCell>{data.OrderID}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={e =>
                              handleDisplayCart(e, data.CartID, data.Total)
                            }
                          >
                            {data.CartID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.CustomerID}</TableCell>
                        <TableCell>{data.CustomerName}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={e => handleDisplayImage(e, data.ImgUrl)}
                          >
                            Image
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.Resolved}
                            onChange={e => handleChangeActivity(e, data.FID)}
                            name={data.FID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>{data.Date}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default FailedTransactionsTable
