import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import RestoreIcon from '@material-ui/icons/Restore'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { CardContent, Button, MenuItem } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'
import { convertDate } from '../../../utils/date_convert'
import { Auth } from '../../App'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  productDropdown: any[]
  categoryDropdown: any[]
  productID: string
  productDesc: string
  productPrice: string
  productQty: string
  imgUrls: any[]
  HVAGBParams: any[]
  refresh: number
  order: number
  open: boolean
  displayImages: boolean
  displayParams: boolean
  Tab: string
  bundleID: string
  category: string
  HVATarget: string
  HVAPrice: string
  GBProdID: string
  imgUrl: string
  GBLimit: number
  GBDailyLimit: number
  ussdOrder: string
  name: string
  quantities: string
  desc: string
  price: number
  GBPrice: number
  originalPrice: number
  targetQty: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '50%',
  },
  imageCell: {
    // maxWidth: '15%',
  },
  textarea: {
    width: '100%',
  },
  button: {
    padding: '20px',
  },

  imageEdit: {
    display: 'block',
    maxWidth: '100%',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const ProductTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    productDropdown: [],
    categoryDropdown: [],
    productID: "",
    productDesc: "",
    productPrice: "",
    productQty: "",
    imgUrls: [],
    HVAGBParams: [],
    displayImages: false,
    displayParams: false,
    Tab: 'ALL',
    refresh: 0,
    order: 0,
    open: false,
    bundleID: 'BD' + Math.floor(100000000 + Math.random() * 900000000),
    GBLimit: 0,
    GBDailyLimit: 0,
    ussdOrder: '',
    HVATarget: '',
    HVAPrice: '',
    category: '',
    GBProdID: '',
    imgUrl: '',
    name: '',
    desc: '',
    quantities: '',
    price: 0,
    GBPrice: 0,
    originalPrice: 0,
    targetQty: 0,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  const storage = firebase.storage()

  let image: File

  useEffect(() => {

    const fetchBundleData = () => {
      setIsloading(true)

      const list: any = []

      firestore.collection("Bundles")
        .orderBy("date_created", "desc")
        .get()
        .then((data) => {

          if (data.empty) {
            setIsloading(false)
          } else {

            data.forEach((doc) => {

              const { Name, Description, Price, ImgUrl, productsArray, TargetQty, QtySold } = doc.data()

              list.push({ key: doc.id, Name, Description, Price, ImgUrl, TargetQty, QtySold, productsArray })


            })

          }
        }).then(() => {

          setFormState({
            ...formState,
            array: list
          })

          setIsloading(false)

        })
      console.log("BUNDLES")
    }

    fetchBundleData()
  }, [formState.refresh, formState.Tab])
  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let nameSearch: any = row.name
        let descSearch: any = row.desc

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(nameSearch) ||
          expression.test(descSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = () => {
    setFormState({
      ...formState,
      open: true!,
    })
  }

  const handleEdit = (
    e,
    id,
    name,
    description,
    price,
    targetQty,
    imgUrl
  ) => {
    setFormState({
      ...formState,
      open: true!,
      bundleID: id,
      name: name,
      desc: description,
      price: price,
      targetQty: targetQty,
      imgUrl: imgUrl
    })
  }

  const displayParams = (e, key, array) => {

    const tabList: any = []

    firestore
      .collection('Products')
      .orderBy('Description', 'asc')
      .get()
      .then(data => {
        data.forEach(doc => {
          const {
            Description,
            Price,
          } = doc.data()

          tabList.push(<MenuItem id={doc.id} value={`${doc.id}@${Description}@${Price}`}>{Description}@{Price}</MenuItem>)

        })
      }).then(() => {

        console.log("tabList", tabList)

      }).then(() => {

        setFormState({
          ...formState,
          HVAGBParams: array,
          displayParams: true!,
          productDropdown: tabList,
          bundleID: key,
        })
      })

    //
  }

  const addBundle = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        open: false!,
        bundleID: 'BD' + Math.floor(100000000 + Math.random() * 900000000),
        imgUrl: '',
        name: '',
        desc: '',
        price: 0,
        targetQty: 0,
      })
      // handleRefresh()
    }

    const handleChange = (e: any) => {
      if (e.target.id === 'name') {
        setFormState({
          ...formState,
          name: e.target.value,
        })
      } else if (e.target.id === 'description') {
        setFormState({
          ...formState,
          desc: e.target.value,
        })
      } else if (e.target.id === 'price') {
        setFormState({
          ...formState,
          price: e.target.value,
        })
      } else if (e.target.id === 'targetQty') {
        setFormState({
          ...formState,
          targetQty: e.target.value,
        })
      } else if (e.target.files[0]) {
        image = e.target.files[0]
      }
    }

    const handleUploadImage = () => {
      const Reference = storage.ref(`BundleImages/${image.name}`)

      Reference.put(image).then(snapshot => {
        Reference.getDownloadURL().then(url => {
          const urlString = url.toString()

          setFormState({
            ...formState,
            imgUrl: urlString,
          })
        })
      })
    }

    const handleAddProduct = () => {
      if (formState.name === '') {
        return alert('Input Product Name')
      } else if (formState.desc === '') {
        return alert('Input Description')
      } else if (formState.imgUrl === '') {
        return alert('Upload Image')
      } else if (formState.price === 0) {
        return alert('Input Valid Price')
      } else if (formState.targetQty === 0) {
        return alert('Input Valid Target Quantity')
      } else {
        const data = firestore.collection('Bundles').doc(formState.bundleID)

        data
          .get()
          .then(doc => {
            if (doc.exists) {
              const body = {
                Name: formState.name,
                Description: formState.desc,
                ImgUrl: formState.imgUrl,
                Price: formState.price,
                date_updated: convertDate(new Date()),
                TargetQty: formState.targetQty,
              }

              console.log('DOC EXISTS! UPDATING')

              data
                .update(body)
                .then(() => {
                  const logRef =
                    'LOG' + Math.floor(100000000 + Math.random() * 900000000)

                  firestore
                    .collection('AdminLogs')
                    .doc(logRef)
                    .set({
                      LogId: logRef,
                      userEmail: userEmail,
                      activity: 'Updated a Bundle',
                      date_created: convertDate(new Date()),
                      type: 'Update',
                      record: body
                    })
                    .catch(err => console.log(err.message))
                })
                .then(() => {
                  handleClose()
                })
            } else {

              const body = {
                Name: formState.name,
                Description: formState.desc,
                ImgUrl: formState.imgUrl,
                Price: formState.price,
                TargetQty: formState.targetQty,
                QtySold: 0,
                productsArray: [],
                date_created: convertDate(new Date()),
                date_updated: convertDate(new Date()),
              }

              console.log('Creating new doc')
              data
                .set(body)
                .then(() => {
                  const logRef =
                    'LOG' + Math.floor(100000000 + Math.random() * 900000000)

                  firestore
                    .collection('AdminLogs')
                    .doc(logRef)
                    .set({
                      LogId: logRef,
                      userEmail: userEmail,
                      activity: 'Created a new bundle',
                      date_created: convertDate(new Date()),
                      type: 'Create',
                      record: body
                    })
                    .catch(err => console.log(err.message))
                })
                .then(() => {
                  handleClose()
                })
            }
          })
          .catch(error => {
            console.log('Error getting document:', error)
          })
      }
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Bundle</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Bundle Details below (ID: {formState.bundleID})
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Bundle Name'
            type='text'
            fullWidth
            onChange={handleChange}
            value={formState.name}
          />
          <TextareaAutosize
            aria-label='empty textarea'
            name='bundleDescription1'
            id='description'
            className={classes.textarea}
            rowsMin={3}
            onChange={handleChange}
            placeholder='Input Product Description'
            value={formState.desc}
          />
          <p>
            <b>Select Product Image:</b>
          </p>
          <input
            type='file'
            className={classes.button}
            onChange={handleChange}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={handleUploadImage}
          >
            Upload Image
          </Button>
          <br />
          {/* eslint-disable-next-line*/}
          <img src={formState.imgUrl} className={classes.imageEdit} />
          <br />
          <TextField
            autoFocus
            margin='dense'
            id='price'
            label='Total Bundle Price (Ksh)'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.price}
          />
          <TextField
            autoFocus
            margin='dense'
            id='targetQty'
            label='Target Quantity'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.targetQty}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleAddProduct} color='primary'>
            Add Bundle
          </Button>
        </DialogActions>
      </Dialog>
    )
  }


  const addProduct = () => {
    const handleClose = () => {
      firestore
        .collection('Bundles')
        .doc(formState.bundleID)
        .update({
          productsArray: formState.HVAGBParams,
        })
        .then(() => {
          alert('Bundle Products updated')

          setFormState({
            ...formState,
            displayParams: false!,
            HVAGBParams: []
          })
        })
    }

    const handleChange = e => {
      if (e.target.id === 'Qty') {
        setFormState({
          ...formState,
          productQty: e.target.value,
        })
      } else if (e.target.name === "productID") {
        console.log(e.target.value)
        const value = e.target.value

        const data = value.split("@")

        setFormState({
          ...formState,
          productID: data[0],
          productDesc: data[1],
          productPrice: data[2]
        })


      }
    }

    const handleUpdateParams = () => {
      formState.HVAGBParams.push({
        productID: formState.productID,
        productDesc: formState.productDesc,
        productPrice: formState.productPrice,
        quantity: formState.productQty,
      })



      firestore
        .collection('Bundles')
        .doc(formState.bundleID)
        .update({
          productsArray: formState.HVAGBParams,
        })
        .then(() => {
          alert('Bundle Products updated')

          setFormState({
            ...formState,
            displayParams: true!,
            productID: "",
            productDesc: "",
            productPrice: "",
            productQty: "",
          })
        })
    }

    const handleDeleteParam = (e, obj) => {
      formState.HVAGBParams.forEach((data, index) => {
        if (data === obj) {
          formState.HVAGBParams.splice(index, 1)
        }
      })

      setFormState({
        ...formState,
        displayParams: true!,
      })
    }

    return (
      <Dialog
        open={formState.displayParams}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Product</DialogTitle>
        <DialogContent>
          <DialogContentText>Products to be present in bundle</DialogContentText>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>Product</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.HVAGBParams.map(data => (
                  <TableRow key={data.productID}>
                    <TableCell>{data.productDesc}@{data.productPrice}</TableCell>
                    <TableCell>{data.quantity}</TableCell>
                    <TableCell>
                      <Button
                        color='secondary'
                        variant='contained'
                        onClick={e => handleDeleteParam(e, data)}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
                }
                <TableRow>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel id='ProdDropdown'>Bundle Product</InputLabel>
                      <Select
                        labelId='ProdDropdown'
                        name='productID'
                        onChange={handleChange}
                      >
                        <MenuItem>--------Select Bundle Product--------</MenuItem>
                        {formState.productDropdown}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      autoFocus
                      margin='dense'
                      id='Qty'
                      label='Quantity'
                      type='text'
                      fullWidth
                      onChange={handleChange}
                      value={formState.productQty}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={e => handleUpdateParams()}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }


  return (
    <div className={classes.root}>
      {addProduct()}
      {addBundle()}
      <br />
      <Card className={classes.box}>
        <CardContent>
          <Button color='primary' variant='contained' onClick={handleClickOpen}>
            Add Bundle
          </Button>
          <div className={classes.row}>
            <h1 className={classes.h1}>Bundles</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Product ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                  <TableCell>
                    <b>Price (Ksh)</b>
                  </TableCell>
                  <TableCell>
                    <b>Qty Sold</b>
                  </TableCell>
                  <TableCell>
                    <b>Target Qty</b>
                  </TableCell>
                  <TableCell>
                    <b>Image</b>
                  </TableCell>
                  <TableCell>
                    <b>Products</b>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.Name}</TableCell>
                        <TableCell>{data.Description}</TableCell>
                        <TableCell>{data.Price}</TableCell>
                        <TableCell>{data.QtySold}</TableCell>
                        <TableCell>{data.TargetQty}</TableCell>
                        <TableCell className={classes.imageCell}><img className={classes.image} src={data.ImgUrl} /></TableCell>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e => {
                              displayParams(e, data.key, data.productsArray)
                            }}
                          >
                            Add Bundle Products
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e =>
                              handleEdit(
                                e,
                                data.key,
                                data.Name,
                                data.Description,
                                data.Price,
                                data.TargetQty,
                                data.ImgUrl
                              )
                            }
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            color='secondary'
                            variant='contained'
                          // onClick={e => handleDelete(e, data.key)}
                          >
                            {formState.Tab === 'Archived' ? (
                              <RestoreIcon />
                            ) : (
                              <DeleteForeverIcon />
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default ProductTable
