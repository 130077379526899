import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ITheme } from '../../theme'
import AddBlog from './AddBlog'
import BlogView from './BlogView'

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const Blogs: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={7} md={7} xl={12} xs={12}>
          <AddBlog />
        </Grid>
        <Grid item lg={4} md={4} xl={12} xs={12}>
          <BlogView />
        </Grid>
      </Grid>
    </div>
  )
}

export default Blogs
