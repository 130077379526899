import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  MenuItem,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Auth } from '../../App'
import { ITheme } from '../../theme'
import RefreshIcon from '@material-ui/icons/Refresh'
import FirebaseApp, { db } from '../../firebase/firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  table: any[]
  CLID: string
  CLID2: string
  CLName: string
  Reason: string
  LeaderReason: string
  refresh: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {},
  details: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  h1: {
    textAlign: 'center',
  },
  textarea: {
    width: '100%',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const Registered: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles()
  const { user, authUser } = Auth.useContainer()

  const firebase = FirebaseApp
  const firestore = firebase.firestore()
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [values, setValues] = useState<IFormState>({
    table: [],
    CLID: '',
    CLID2: '',
    CLName: '',
    LeaderReason: '',
    Reason: '',
    refresh: 0,
  })
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 10
  const count = Math.ceil(values.table.length / PER_PAGE)
  const _DATA = usePagination(values.table, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }
  useEffect(() => {
    const fetchRegisteredReferrals = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('CLReferrals')
        .where('Processed', '==', true)
        .where('Registered', '==', true)
        .orderBy('DateUpdated', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        console.log('OYAA')

        const {
          LeaderID,
          PhoneNumber,
          Address,
          AddressUrl,
          Zone,
          Name,
          Date,
          DateUpdated,
        } = doc.data()

        list.push({
          key: doc.id,
          leaderID: LeaderID,
          name: Name,
          pNo: PhoneNumber,
          address: Address,
          addressUrl: AddressUrl,
          Zone: Zone,
          Date: Date,
          DateUpdated: DateUpdated,
        })
      })

      setValues({
        ...values,
        table: list,
      })
      setIsloading(false)
    }

    fetchRegisteredReferrals()
  }, [values.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = values.table.filter(row => {
        let keySearch: any = row.key
        let leaderIDSearch: any = row.leaderID
        let noSearch: any = row.pNo
        let nameSearch: any = row.name
        let addressSearch: any = row.address
        let zoneSearch: any = row.Zone
        let dateSearch: any = row.Date
        let uploadedSearch: any = row.DateUploaded

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(leaderIDSearch) ||
          expression.test(noSearch) ||
          expression.test(addressSearch) ||
          expression.test(dateSearch) ||
          expression.test(zoneSearch) ||
          expression.test(nameSearch) ||
          expression.test(uploadedSearch) ||
          expression.test(unkown)
        )
      })
      setValues({ ...values, table: filteredRows })
      console.log(values.table)
    } else {
      setValues({ ...values, refresh: values.refresh + 1 })
    }
  }

  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setValues({ ...values, refresh: values.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setValues({
      ...values,
      refresh: values.refresh + 1,
    })
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const headers = [
    { label: 'Referral ID', key: 'key' },
    { label: 'Referrer ID', key: 'leaderID' },
    { label: 'Referee Name', key: 'name' },
    { label: 'Referee Phone No.', key: 'pNo' },
    { label: 'Referee Address', key: 'address' },
    { label: 'Referee Zone', key: 'Zone' },
    { label: 'Date Created', key: 'Date' },
    { label: 'Date Updated', key: 'DateUpdated' },
  ]

  const csvReport = {
    data: values.table,
    headers: headers,
    filename: 'RegisteredReferrals(' + today + ').csv',
  }

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <div className={classes.row}>
            <h2>Registered Referrals</h2>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            <Button
              color='primary'
              variant='contained'
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '1em', marginTop: '1em' }}
          />
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <TableContainer>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>
                      <b>Referral ID</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <b>Referrer ID</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <b>Referee Name</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <b>Referee Phone No.</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <b>Referee Address</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <b>Referee Zone</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <b>Date Created</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p>
                      <b>Date Updated</b>
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow>
                        <TableCell>
                          <p>{data.key}</p>
                        </TableCell>
                        <TableCell>
                          <p>{data.leaderID}</p>
                        </TableCell>
                        <TableCell>
                          <p>{data.name}</p>
                        </TableCell>
                        <TableCell>
                          <p>{data.pNo}</p>
                        </TableCell>
                        <TableCell>
                          <p>{data.address}</p>
                        </TableCell>
                        <TableCell>
                          <p>{data.Zone}</p>
                        </TableCell>
                        <TableCell>
                          <p>{data.Date}</p>
                        </TableCell>
                        <TableCell>
                          <p>{data.DateUpdated}</p>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={1}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default Registered
