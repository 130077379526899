import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'
import { convertDate } from '../../../utils/date_convert'

interface IProps {
  className?: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

export interface IFormState {
  farmerID: string
  array: any[]
  dropdownArray: any[]
  prodID: string
  refresh: number
  open: boolean
  name: string
  address: string
  moq: number
  pNo: string
}

const CommunityTable: React.FC<IProps> = () => {
  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  console.log('farmers --->', userEmail)
  const classes = useStyles()
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [searched, setSearched] = useState<any>('')
  const [formState, setFormState] = useState<IFormState>({
    farmerID: 'FM' + Math.floor(100000000 + Math.random() * 900000000),
    array: [],
    dropdownArray: [],
    prodID: '',
    refresh: 0,
    open: false,
    name: '',
    address: '',
    moq: 0,
    pNo: '',
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 30

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)

      const data = await firestore
        .collection('Farmers')
        .orderBy('DateCreated', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          Name,
          Address,
          FarmerID,
          PhoneNumber,
          Active,
          DateCreated,
          PickUp,
          ProductTypeName,
          ProductTypeID,
          MOQ,
        } = doc.data()
        list.push({
          key: doc.id,
          Address: Address,
          Name: Name,
          FarmerID: FarmerID,
          PhoneNumber: PhoneNumber,
          date: DateCreated,
          active: Active ? true : false,
          pickUp: PickUp ? true : false,
          ProductTypeID: ProductTypeID,
          ProductTypeName: ProductTypeName,
          MOQ: MOQ,
        })
      })

      setFormState({
        ...formState,
        array: list,
      })
      setIsloading(false)
    }

    const fetchZoneDropDown = async () => {
      const data = await firestore.collection('ProdType').get()

      data.forEach(doc => {
        const { Type } = doc.data()

        formState.dropdownArray.push(<MenuItem value={doc.id}>{Type}</MenuItem>)
      })
    }

    fetchData()
    fetchZoneDropDown()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let nameSearch: any = row.Name
        let addressSearch: any = row.Address
        let phoneNumberSearch: any = row.PhoneNumber
        let dateSearch: any = row.date
        let moqSearch: any = row.MOQ
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(nameSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(addressSearch) ||
          expression.test(dateSearch) ||
          expression.test(moqSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = () => {
    setFormState({
      ...formState,
      open: true!,
    })
  }

  const handleEdit = (
    e,
    key,
    Name,
    PhoneNumber,
    ProductTypeID,
    Address,
    MOQ
  ) => {
    setFormState({
      ...formState,
      open: true!,
      farmerID: key,
      name: Name,
      address: Address,
      pNo: PhoneNumber,
      prodID: ProductTypeID,
      moq: MOQ,
    })
  }

  const handleDelete = (e, id) => {
    console.log('ID: ' + id)
    const deletedUserRecord = id
    firestore
      .collection('Farmers')
      .doc(id)
      .delete()
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Deleted a farmer',
            date_created: convertDate(new Date()),
            type: 'Delete',
            record: { CL: deletedUserRecord },
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Farmer Deleted')
        handleRefresh()
      })
  }

  const handleChangeActivity = e => {
    e.preventDefault()

    const farmerRef = firestore.collection('Farmers').doc(e.target.name)

    farmerRef
      .update({
        Active: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated farmer active status',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangePickUp = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        PickUp: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated  farmers change pickup status',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const addField = () => {
    const handleClose = () => {
      console.log('CLOSE')

      setFormState({
        ...formState,
        open: false!,
        farmerID: 'FM' + Math.floor(100000000 + Math.random() * 900000000),
        prodID: '',
        name: '',
        address: '',
        moq: 0,
        pNo: '',
        refresh: formState.refresh + 1,
      })
    }

    const handleChange = (e: any) => {
      if (e.target.id === 'name') {
        setFormState({
          ...formState,
          name: e.target.value,
        })
      } else if (e.target.id === 'address') {
        setFormState({
          ...formState,
          address: e.target.value,
        })
      } else if (e.target.id === 'pNo') {
        setFormState({
          ...formState,
          pNo: e.target.value,
        })
      } else if (e.target.name === 'product') {
        setFormState({
          ...formState,
          prodID: e.target.value,
        })
      } else if (e.target.id === 'moq') {
        setFormState({
          ...formState,
          moq: e.target.value,
        })
      }

      console.log('CHANGED!')
    }

    const handleAddFarmer = () => {
      const date = new Date()

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
        ':' +
        (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

      if (formState.name === '') {
        alert('Please enter farmer name')
      } else if (formState.address === '') {
        alert('Please enter farmer address')
      } else if (formState.pNo === '') {
        alert('Please enter farmer phone number')
      } else if (formState.prodID === '') {
        alert('Please enter farmer product')
      } else if (formState.moq === 0) {
        alert('Please enter farmer MOQ')
      } else {
        firestore
          .collection('ProdType')
          .doc(formState.prodID)
          .get()
          .then((doc: any) => {
            const { Type } = doc.data()

            firestore
              .collection('Farmers')
              .doc(formState.farmerID)
              .set({
                Name: formState.name,
                Address: formState.address,
                FarmerID: formState.farmerID,
                PhoneNumber: formState.pNo,
                Active: true,
                DateCreated: today,
                PickUp: false,
                ProductTypeName: Type,
                ProductTypeID: formState.prodID,
                MOQ: formState.moq,
              })
              .then(() => {
                const logRef =
                  'LOG' + Math.floor(100000000 + Math.random() * 900000000)

                firestore
                  .collection('AdminLogs')
                  .doc(logRef)
                  .set({
                    LogId: logRef,
                    userEmail: userEmail,
                    activity: 'Added a new Farmer',
                    date_created: convertDate(new Date()),
                    type: 'Create',
                    record: {
                      Name: formState.name,
                      Address: formState.address,
                      FarmerID: formState.farmerID,
                      PhoneNumber: formState.pNo,
                      Active: true,
                      DateCreated: today,
                      PickUp: false,
                      ProductTypeName: Type,
                      ProductTypeID: formState.prodID,
                      MOQ: formState.moq,
                    },
                  })
                  .catch(err => console.log(err.message))
              })
              .then(() => {
                alert('Farmer Added')

                handleClose()
              })
          })
      }

      console.log('ADD CL')
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Farmer Datails</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add New Farmer Data below (ID: {formState.farmerID})
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Name'
            type='text'
            fullWidth
            onChange={handleChange}
            value={formState.name}
          />
          <TextField
            autoFocus
            margin='dense'
            id='address'
            label='Address'
            type='text'
            fullWidth
            onChange={handleChange}
            value={formState.address}
          />
          <TextField
            autoFocus
            margin='dense'
            id='pNo'
            label='Phone Number (07xx / 01xx)'
            inputProps={{ maxLength: 10 }}
            type='text'
            fullWidth
            onChange={handleChange}
            value={formState.pNo}
          />
          <FormControl fullWidth>
            <InputLabel id='Productdropdown'>Product</InputLabel>
            <Select
              labelId='Productdropdown'
              name='product'
              onChange={handleChange}
              value={formState.prodID}
            >
              {formState.dropdownArray}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin='dense'
            id='moq'
            label='MOQ'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.moq}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleAddFarmer} color='primary'>
            Add Farmer
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      {addField()}
      <Card className={classes.box}>
        <CardContent>
          <Button color='primary' variant='contained' onClick={handleClickOpen}>
            Add Farmer
          </Button>
          <div className={classes.row}>
            <h1 className={classes.h1}>Farmers</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />

          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Farmer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Product?</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>MOQ</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Active</b>
                  </TableCell>
                  <TableCell>
                    <b>Pick Up?</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.Name}</TableCell>
                        <TableCell>{data.PhoneNumber}</TableCell>
                        <TableCell>{data.ProductTypeName}</TableCell>
                        <TableCell>{data.Address}</TableCell>
                        <TableCell>{data.MOQ}</TableCell>
                        <TableCell>{data.date}</TableCell>
                        <TableCell>
                          <Switch
                            checked={data.active}
                            onChange={e => handleChangeActivity(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.pickUp}
                            onChange={e => handleChangePickUp(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e =>
                              handleEdit(
                                e,
                                data.key,
                                data.Name,
                                data.PhoneNumber,
                                data.ProductTypeID,
                                data.Address,
                                data.MOQ
                              )
                            }
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            color='secondary'
                            variant='contained'
                            onClick={e => handleDelete(e, data.key)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default CommunityTable
