import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import { CSVLink } from 'react-csv'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  cartID: string
  totalItems: number
  cartItems: any[]
  displayCart: boolean
  grandTotal: string
  CodeIDSearch: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginLeft: 'auto',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const SuccessfulTransactionsTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    totalItems: 0,
    cartID: '',
    cartItems: [],
    displayCart: false,
    grandTotal: '',
    CodeIDSearch: '',
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('Transactions')
        .orderBy('CreatedAt', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const { UserID, PhoneNumber, UpdatedAt, Amount, Status, MPesaCode } =
          doc.data()

        if (Status === 'SUCCESS') {
          if (formState.CodeIDSearch === '') {
            list.push({
              key: doc.id,
              MPesaCode: MPesaCode,
              CustomerID: UserID,
              PhoneNumber: PhoneNumber,
              Amount: Amount,
              Date: UpdatedAt,
            })
          } else if (formState.CodeIDSearch === MPesaCode) {
            list.push({
              key: doc.id,
              MPesaCode: MPesaCode,
              CustomerID: UserID,
              PhoneNumber: PhoneNumber,
              Amount: Amount,
              Date: UpdatedAt,
            })
          }
        }
      })

      setFormState({
        ...formState,
        array: list,
        totalItems: list.length,
      })
      setIsloading(false)
    }
    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let mpesaCodeSearch: any = row.MpesaCode
        let customerIdSearch: any = row.CustomerId
        let phoneNumberSearch: any = row.PhoneNumber
        let dateSearch: any = row.Date

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(mpesaCodeSearch) ||
          expression.test(customerIdSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(dateSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      // totalItems: 0,
      refresh: formState.refresh + 1,
    })
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const headers = [
    { label: 'Transaction ID', key: 'key' },
    { label: 'M-Pesa Code', key: 'MPesaCode' },
    { label: 'CustomerID', key: 'CustomerID' },
    { label: 'mobile', key: 'PhoneNumber' },
    { label: 'amount', key: 'Amount' },
    { label: 'date', key: 'Date' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'Transactions(' + today + ').csv',
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>
              Successful Transactions: {formState.totalItems}
            </h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <div className={classes.row}>
            <TextField
              label='SEARCH MPESA CODE'
              onChange={e =>
                setFormState({ ...formState, CodeIDSearch: e.target.value })
              }
              onSubmit={handleRefresh}
            />
            <Button color='primary' variant='outlined' onClick={handleRefresh}>
              <SearchIcon />
            </Button>

            <Button
              color='primary'
              variant='contained'
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Transaction ID</b>
                  </TableCell>
                  <TableCell>
                    <b>M-Pesa Code</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Amount</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.MPesaCode}</TableCell>
                        <TableCell>{data.CustomerID}</TableCell>
                        <TableCell>{data.PhoneNumber}</TableCell>
                        <TableCell>{data.Amount}</TableCell>
                        <TableCell>{data.Date}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default SuccessfulTransactionsTable
