const rootPaths = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  REFERRALS: '/referrals',
  CLREFERRALS: '/CLReferrals',
  BUNDLES: '/Bundles',
  PRODUCTS: '/products',
  COMMUNITYLEADERS: '/communityLeaders',
  FARMERS: '/farmers',
  SOURCINGLIST: '/sourcingList',
  ORDERS: '/orders',
  COMPLETEDORDERS: '/completedOrders',
  CANCELLEDORDERS: '/cancelledOrders',
  BIDREQUEST: '/bidRequest',
  SUCCSESSFULTRANSACTIONS: '/SuccessfulTransactions',
  PROCESSEDINVOICE: '/ProcessedInvoice',
  UNPROCESSEDINVOICE: '/UnprocessedInvoice',
  FAILEDTRANSACTIONS: '/FailedTransactions',
  ALLTRANSACTIONS: '/AllTransactions',
  CART: '/Cart',
  LEADERBOARD: '/leaderBoard',
  NEWACTIVE: '/newActive',
  LEDGER: '/ledger',
  ALLLEDGER: '/AllLedger',
  DEFAULTLEDGER: '/DefaultLedger',
  COMMISSIONS: '/commissions',
  ALLCOMMISSIONS: '/allCommissions',
  FEEDBACK: '/feedback',
  CLFEEDBACK: '/CLfeedback',
  CLOFEEDBACK: '/CLOfeedback',
  GROUPBUY: '/groupBuy',
  GROUPBUYQUEUE: '/groupBuyQueue',
  STOCKOUTS: '/stockOuts',
  STOCKCONTROL: '/stockControl',
  NEWS: '/news',
  BLOGS: '/blogs',
  POPUP: '/popUps',
  VAT: '/vat',
  SETTINGS: '/settings',
  ADMINLOGS: '/logs',
  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  NOT_FOUND: '/not-found',
  ALLPAYOUTS: '/AllPayOuts',
  BURSTSMS: '/burstSMS',
}

export const PATHS = {
  ...rootPaths,
}
