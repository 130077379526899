import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CSVLink } from 'react-csv'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  exportArray: any[]
  refresh: number
  cartID: string
  totalItems: number
  cartItems: any[]
  arrayReferrer: any[]
  displayCart: boolean
  displayReferrer: boolean
  grandTotal: string
  leaderIDSearch: string
  starttime: string
  endtime: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginLeft: 'auto',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const ProcessedInvoiceTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    exportArray: [],
    refresh: 0,
    totalItems: 0,
    cartID: '',
    cartItems: [],
    arrayReferrer: [],
    displayCart: false,
    displayReferrer: false,
    grandTotal: '',
    leaderIDSearch: '',
    starttime: '',
    endtime: '',
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)

      const data = await firestore
        .collection('Invoice')
        .where('paid', '==', true)
        .where('created_at', '>=', formState.starttime)
        .where('created_at', '<=', formState.endtime)
        .orderBy('created_at', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          UserID,
          CartID,
          fullNo,
          LeaderID,
          orderID,
          total,
          created_at,
          paid,
          POD,
          transactionID,
          cancelled,
        } = doc.data()

        list.push({
          key: doc.id,
          customerID: UserID,
          cartID: CartID,
          pNo: fullNo,
          leaderID: LeaderID,
          Total: total,
          date: created_at,
          pod: POD,
          orderID,
          transactionID: transactionID,
        })
      })

      setFormState({
        ...formState,
        array: list,
        totalItems: list.length,
      })
      setIsloading(false)
    }
    if (formState.starttime === '' || formState.endtime === '') {
      setFormState({
        ...formState,
        array: [],
        totalItems: 0,
      })
    } else {
      fetchData()
      console.log(formState.array)
    }
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let customerIdSearch: any = row.customerID
        let transIdSearch: any = row.transactionalID
        let leaderIdSearch: any = row.leaderID
        let cartIdSearch: any = row.cartID
        let phoneNumberSearch: any = row.pNo
        let podSearch: any = row.pod
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(customerIdSearch) ||
          expression.test(transIdSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(podSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleDisplayCart = (e, cartID, Total) => {
    const firestore = firebase.firestore()

    const fetchData = async () => {
      const data = await firestore.collection('Products').get()

      const listCart: any = []

      data.forEach(doc => {
        const { Name, Description, ImgUrl, Price } = doc.data()

        const shoppingCartRef = firebase
          .database()
          .ref('shoppingCarts/' + cartID + '/' + doc.id)
        shoppingCartRef.on('value', snapshot => {
          const data = snapshot.val()

          if (snapshot.exists()) {
            listCart.push({
              key: doc.id,
              name: Name,
              desc: Description,
              imgUrl: ImgUrl,
              price: Price,
              quantity: data.quantity,
              total: data.total,
            })

            setFormState({
              ...formState,
              displayCart: true!,
              cartItems: listCart,
              cartID: cartID,
              grandTotal: Total,
            })

            // Code for adding grandtotal to cart record
          }
        })
      })
    }

    setFormState({
      ...formState,
      displayCart: true!,
    })
    fetchData()
  }

  const handleCloseCart = () => {
    setFormState({
      ...formState,
      displayCart: false!,
      cartItems: [],
      cartID: '',
      grandTotal: '',
    })
  }
  const handleChange = (e: any) => {
    const date = new Date(e.target.value)

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    if (e.target.name === 'starttime') {
      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const DialogCart = () => {
    return (
      <Dialog
        open={formState.displayCart}
        onClose={handleCloseCart}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          CartID: {formState.cartID}
        </DialogTitle>

        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#d3d3d3',
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Price</h2>
                  </b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Total</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formState.cartItems.map(data => (
                <TableRow key={data.desc}>
                  <TableCell>{data.desc}</TableCell>
                  <TableCell>{data.price}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>
                    <b>{data.total}</b>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key="row2">
                <TableCell colSpan={3}>
                  <h1>GrandTotal</h1>
                </TableCell>
                <TableCell colSpan={2}>
                  <h1>{formState.grandTotal}</h1>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const headers = [
    { label: 'Invoice ID', key: 'key' },
    { label: 'Order ID', key: 'orderID' },
    { label: 'Customer ID', key: 'customerID' },
    { label: 'Phone Number', key: 'pNo' },
    { label: 'Leader ID', key: 'leaderID' },
    { label: 'Transaction ID', key: 'transactionID' },
    { label: 'Date', key: 'date' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'ProcessedInvoiceList(' + today + ').csv',
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>
              Processed Invoices: {formState.totalItems}
            </h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <div className={classes.row}>
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="starttime"
              label="Start Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="endtime"
              label="End Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>
          {DialogCart()}
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Invoice ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Customer ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Cart ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Transaction ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.customerID}</TableCell>
                        <TableCell>{data.pNo}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e =>
                              handleDisplayCart(e, data.cartID, data.Total)
                            }
                          >
                            {data.cartID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.leaderID}</TableCell>
                        <TableCell>{data.pod ? 'POD' : 'Paid'}</TableCell>
                        <TableCell>{data.transactionID}</TableCell>
                        <TableCell>{data.date}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default ProcessedInvoiceTable
