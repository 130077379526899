import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { CSVLink } from 'react-csv'
import usePlacesAutocomplete from 'use-places-autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  commissionArray: any[]
  bonusArray: any[]
  refresh: number
  totalCommissions: number
  open: boolean
  starttime: string
  endtime: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const StockOutTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const week = new Date()

  week.setDate(week.getDate() - 7)

  const weekdate =
    week.getFullYear() +
    '-' +
    (week.getMonth() + 1 > 9
      ? week.getMonth() + 1
      : '0' + (week.getMonth() + 1)) +
    '-' +
    (week.getDate() > 9 ? week.getDate() : '0' + week.getDate()) +
    ' T 00:00'

  const [isLoading, setIsloading] = useState<boolean>(true)
  const [searched, setSearched] = useState<any>('')
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    commissionArray: [],
    bonusArray: [],
    refresh: 0,
    totalCommissions: 0,
    open: false,
    starttime: '',
    endtime: '',
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchStockOutData = async () => {
      setIsloading(true)
      let totalCommissions = 0

      const data = await firestore
        .collection('StockOut')
        .where('StockOutDate', '>=', formState.starttime)
        .where('StockOutDate', '<', formState.endtime)
        .get()

      const pendingList: any = []

      console.log('SIZEEE: ', data.size)

      data.forEach(doc => {
        const { UserID, TargetQty, StockOutDate, QtySold, QtyBought, ProductID, LeaderID, OrderID } = doc.data()

        pendingList.push({
          key: doc.id,
          UserID,
          TargetQty,
          StockOutDate,
          QtySold,
          QtyBought,
          ProductID,
          LeaderID,
          OrderID
        })
      })

      setFormState({
        ...formState,
        array: pendingList
      })

      setIsloading(false)
    }

    fetchStockOutData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let PIDSearch: any = row.ProductID
        let dateSearch: any = row.StockOutDate
        let OrderSearch: any = row.OrderID
        let UserSearch: any = row.UserID
        let leaderSearch: any = row.LeaderID


        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(PIDSearch) ||
          expression.test(dateSearch) ||
          expression.test(OrderSearch) ||
          expression.test(UserSearch) ||
          expression.test(leaderSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'starttime') {
      // setFormState({
      //   ...formState,
      //   starttime: e.target.value
      // })

      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const headers = [
    { label: 'StockOutID', key: 'key' },
    { label: 'OrderID', key: 'OrderID' },
    { label: 'CustomerID', key: 'UserID' },
    { label: 'LeaderID', key: 'LeaderID' },
    { label: 'ProductID', key: 'ProductID' },
    { label: 'QtyBought', key: 'QtyBought' },
    { label: 'Overdue', key: 'QtySold' },
    { label: 'TargetQty', key: 'TargetQty' },
    { label: 'StockOutDate', key: 'StockOutDate' },
    { label: 'Date', key: 'Date' },
  ]

  const csvReport = {
    data: _DATA.currentData(),
    headers: headers,
    filename: 'AllLedgers(' + today + ').csv',
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <h1>Stock Out</h1>
          <div className={classes.row}>
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='starttime'
              label='Start Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id='datetime-local'
              onChange={handleChange}
              name='endtime'
              label='End Date/Time'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>
          <br />
          <span className={classes.spacer} />
          <Button
            color='primary'
            variant='contained'
            className={classes.refresh}
          >
            <CSVLink {...csvReport}>
              Export to CSV
            </CSVLink>
          </Button>
          <br />
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Stock Out ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Order ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Product ID</b>
                  </TableCell>
                  <TableCell>
                    <b>User ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Qty Bought</b>
                  </TableCell>
                  <TableCell>
                    <b>Qty Sold</b>
                  </TableCell>
                  <TableCell>
                    <b>Target Qty</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.OrderID}</TableCell>
                        <TableCell>{data.ProductID}</TableCell>
                        <TableCell>{data.UserID}</TableCell>
                        <TableCell>{data.LeaderID}</TableCell>
                        <TableCell>{data.QtyBought}</TableCell>
                        <TableCell>{data.QtySold}</TableCell>
                        <TableCell>{data.TargetQty}</TableCell>
                        <TableCell>{data.StockOutDate}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default StockOutTable
