import React, { useState, useEffect } from "react"
import { CardContent, MenuItem } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import Switch from "@material-ui/core/Switch"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/styles"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Button } from "@material-ui/core"
import RefreshIcon from "@material-ui/icons/Refresh"
import { ITheme } from "../../theme"
import FirebaseApp from "../../firebase/firebase"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import Pagination from "@material-ui/lab/Pagination"
import usePagination from "../../../utils/Pagination"
import { CSVLink } from "react-csv"
import { Auth } from "../../App"
import SearchBar from "material-ui-search-bar"
import { convertDate } from "../../../utils/date_convert"

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  arraycus: any[]
  arrayLedger: any[]
  customerItem: any[]
  clearArray: any[]
  refresh: number
  leaderID: string
  customerID: string
  password: string
  LGID: string
  orderID: string
  mpesaCode: string
  markedAll: boolean
  ledgerLimit: number
  displayLedgerEdit: boolean
  displayCustomers: boolean
  displayLedger: boolean
  openVerify: boolean
  leaderLedger: number
  leaderLedger2: number
  customerLedger: number
  customerLedger2: number
  ledgerTotal: number
  starttime: string
  endtime: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  box: {
    padding: "16px",
    justifyContent: "center",
  },
  tr: {
    background: "#D3D3D3",
  },
  h1: {
    textAlign: "center",
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: "100%",
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: "center",
  },
}))

const LedgerTable: React.FC<IProps> = () => {
  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const classes = useStyles()
  const [searched, setSearched] = useState<any>("")
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    arraycus: [],
    arrayLedger: [],
    customerItem: [],
    clearArray: [],
    refresh: 0,
    leaderID: "",
    customerID: "",
    mpesaCode: "",
    password: "",
    LGID: "",
    orderID: "",
    ledgerLimit: 0,
    markedAll: false,
    displayLedgerEdit: false,
    displayCustomers: false,
    displayLedger: false,
    openVerify: false,
    leaderLedger: 0,
    leaderLedger2: 0,
    customerLedger: 0,
    customerLedger2: 0,
    ledgerTotal: 0,
    starttime: "",
    endtime: "",
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  useEffect(() => {
    setIsloading(true)
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection("CommunityLeaders")
        .orderBy("DateCreated", "asc")
        .get()

      const list: any = []

      let total = 0

      data.forEach(doc => {
        const { Name, LedgerBalance, LedgerLimit, LeaderID } = doc.data()

        if (LedgerBalance !== 0) {

          list.push({
            key: doc.id,
            name: Name,
            leaderID: LeaderID,
            LedgerBalance: LedgerBalance,
            LedgerLimit: LedgerLimit,
          })

          total = total + LedgerBalance

        }
      })

      list.sort((a, b) => {
        return a.LedgerBalance - b.LedgerBalance
      })

      setFormState({
        ...formState,
        array: list,
        ledgerTotal: total,
      })
      setIsloading(false)
    }

    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== "") {
      const filteredRows = formState.array.filter(row => {
        let leaderIdSearch: any = row.leaderID
        let nameSearch: any = row.name

        let unkown: any
        let expression = new RegExp(searchedVal, "gi")
        return (
          expression.test(leaderIdSearch) ||
          expression.test(nameSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched("")
    if (searched === "" || searched.trim().length < 1) {
      console.log("OOP, nothing in your search bar")
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const date = new Date()
  const today =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : "0" + (date.getMonth() + 1)) +
    "-" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    " T " +
    (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
    ":" +
    (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

  const headers = [
    { label: "LeaderID", key: "key" },
    { label: "Leader Name", key: "name" },
    { label: "Ledger Balance", key: "LedgerBalance" },
    { label: "Ledger Limit", key: "LedgerLimit" },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: "LedgerList(" + today + ").csv",
  }

  const handleFilterDate = e => {
    if (formState.starttime === "") {
      alert("ENTER START DATE")
    } else if (formState.endtime === "") {
      alert("ENTER END DATE")
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const handleDisplayLedgers = async (e, id, ledgerBalance) => {
    console.log("OYAA")
    const data = await firestore
      .collection("Ledger")
      .where("LeaderID", "==", id)
      .where("Paid", "==", false)
      .orderBy("Date", "asc")

    data.get().then(docc => {
      const ledgerList: any = []

      if (!docc.empty) {
        docc.forEach(doc => {
          const { OrderID, CustomerID, LeaderID, Total, Paid, Date, Hold } =
            doc.data()

          ledgerList.push({
            mark: false,
            key: doc.id,
            orderID: OrderID,
            total: Total,
            Paid: Paid,
            customerID: CustomerID,
            leaderID: LeaderID,
            hold: Hold,
            date: Date,
          })

          setFormState({
            ...formState,
            arrayLedger: ledgerList,
            displayLedger: true!,
            leaderLedger: ledgerBalance,
            leaderID: id,
          })
        })
      }
    })
  }

  const handleEditLedgerLimit = (e, leaderID, ledgerLimit) => {
    setFormState({
      ...formState,
      leaderID: leaderID,
      ledgerLimit: ledgerLimit,
      displayLedgerEdit: true,
    })
  }

  const handleDisplayCustomer = async (e, UID) => {
    console.log("ellow")

    let list: any = []
    await firestore
      .collection("Customers")
      .doc(UID)
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log("OLAAAA")

          const data = JSON.parse(JSON.stringify(doc.data()))

          list.push({
            UID: UID,
            Name: data.Name,
            LeaderID: data.LeaderID,
            Address: data.Address,
            Neighborhood: data.Neighborhood,
            City: data.City,
            PhoneNumber: data.PhoneNumber,
            Email: data.Email,
            ReferralLink: data.ReferralLink,
          })
        }
      })
      .then(() => {
        console.log("PIMBI!")
        setFormState({
          ...formState,
          customerItem: list,
          displayCustomers: true!,
        })
      })
  }

  const customerPopup = () => {
    const handleCloseCustomer = () => {
      setFormState({
        ...formState,
        displayCustomers: false!,
        customerItem: [],
      })
    }

    return (
      <Dialog
        open={formState.displayCustomers}
        onClose={handleCloseCustomer}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1>Customer Info</h1>
        </DialogTitle>
        <DialogContent>
          {formState.customerItem.map(data => (
            <ul>
              <li>
                <b>UID: </b> {data.UID}
              </li>
              <li>
                <b>Name: </b> {data.Name}
              </li>
              <li>
                <b>Leader ID: </b> {data.LeaderID}
              </li>
              <li>
                <b>Address: </b> {data.Address}
              </li>
              <li>
                <b>Neighborhood: </b> {data.Neighborhood}
              </li>
              <li>
                <b>City: </b> {data.City}
              </li>
              <li>
                <b>Phone Number: </b> {data.PhoneNumber}
              </li>
              <li>
                <b>Email: </b> {data.Email}
              </li>
            </ul>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomer} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const ledgerLimitEdit = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        leaderID: "",
        ledgerLimit: 0,
        displayLedgerEdit: false,
      })
    }

    const handleChange = e => {
      setFormState({
        ...formState,
        ledgerLimit: e.target.value,
      })
    }

    const handleSubmit = () => {
      firestore
        .collection("CommunityLeaders")
        .doc(formState.leaderID)
        .update({
          LedgerLimit: formState.ledgerLimit,
        })
        .then(() => {
          const logRef =
            "LOG" + Math.floor(100000000 + Math.random() * 900000000)
          firestore
            .collection("AdminLogs")
            .doc(logRef)
            .set({
              LogId: logRef,
              userEmail: userEmail,
              activity: "Update ledger limit",
              date_created: convertDate(new Date()),
              record: { LedgerLimit: formState.ledgerLimit },
              type: "Update",
            })
            .catch(err => console.log(err.message))
        })
        .then(() => {
          alert("Limit updated")
          setFormState({
            ...formState,
            leaderID: "",
            ledgerLimit: 0,
            displayLedgerEdit: false,
            refresh: 200,
          })
        })
    }

    return (
      <Dialog
        open={formState.displayLedgerEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">CUSTOMERS</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="limit"
            label="Ledger Limit"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formState.ledgerLimit}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            UPDATE
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const verifyClear = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        openVerify: false,
        customerID: "",
        customerLedger: 0,
        LGID: "",
        leaderID: "",
        orderID: "",
        password: "",
      })
    }

    const handleChange = e => {
      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        " T " +
        (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
        ":" +
        (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())
      if (e.target.id === "password") {
        setFormState({
          ...formState,
          password: e.target.value,
        })
      } else if (e.target.id === "mpesaCode") {
        setFormState({
          ...formState,
          mpesaCode: e.target.value,
        })
      } else if (e.target.name === "starttime") {
        setFormState({
          ...formState,
          starttime: today,
        })

        console.log("OYAA", today)
      } else if (e.target.name === "endtime") {
        setFormState({
          ...formState,
          endtime: today,
        })
      }
    }

    const handleSubmit = e => {
      firestore
        .collection("QuikkTransactions")
        .doc(formState.mpesaCode)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const { Type, Processed } = doc.data()

            if (Type === "LedgerTill") {
              if (!Processed) {
                firestore
                  .collection("QuikkTransactions")
                  .doc(formState.mpesaCode)
                  .update({
                    Processed: true,
                  })
                  .then(async () => {
                    try {
                      await firebase
                        .auth()
                        .signInWithEmailAndPassword(
                          "ledgers@tushop.io",
                          formState.password
                        )

                      handleEdit(e, formState.clearArray)
                    } catch (error) {
                      alert(error.message)
                    }
                  })
              } else {
                alert("Transaction already processed")
              }
            } else {
              alert("Transaction not of type LedgerTill")
            }
          } else {
            alert("Transaction does not exist")
          }
        })
    }

    const handleEdit = (e, array) => {
      array.forEach(async dataCArray => {
        const id = dataCArray.customerID
        const cledger = dataCArray.cledger
        const LGID = dataCArray.LGID
        const LeaderID = dataCArray.leaderID
        const OrderID = dataCArray.orderID

        console.log("ONE")

        const date = new Date()

        const today =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "-" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          " T " +
          (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
          ":" +
          (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

        if (id !== "") {
          const customer = await firestore.collection("Customers").doc(id)

          customer.get().then(doc => {
            if (doc.exists) {
              const data = JSON.parse(JSON.stringify(doc.data()))

              customer.update({
                LedgerBalance: data.LedgerBalance + cledger,
              })

              console.log("TWO")
            }
          })
        }

        const leader = await firestore
          .collection("CommunityLeaders")
          .doc(LeaderID)
        const ledger = await firestore.collection("Ledger").doc(LGID)
        const order = await firestore.collection("Orders").doc(OrderID)

        ledger
          .get()
          .then(doc => {
            if (doc.exists) {
              ledger.update({
                Paid: true,
                ClearedAt: today,
                Cleared: "MANUAL",
                MPesaCode: formState.mpesaCode,
              })
            }
          })
          .then(() => {
            order.get().then(doc => {
              if (doc.exists) {
                order.update({
                  POD: false,
                })
              }
            })
          })
          .then(() => {
            alert("Ledger cleared")

            setFormState({
              ...formState,
              openVerify: false!,
            })

            handleDisplayLedgers(e, formState.leaderID, formState.leaderLedger)
            console.log("FOUR")
          })
          .then(() => {
            const logRef =
              "LOG" + Math.floor(100000000 + Math.random() * 900000000)
            firestore
              .collection("AdminLogs")
              .doc(logRef)
              .set({
                LogId: logRef,
                userEmail: userEmail,
                activity: "Cleared ledgers",
                date_created: convertDate(new Date()),
                type: "Update",
              })
              .catch(err => console.log(err.message))
          })
          .then(() => {
            const url = "https://api.tushop.io/api/v1/updateLedgers"

            fetch(url, { method: "GET" })
              .then((response: any) => {
                if (response.status === 200) {
                  return response.json()
                } else if (response.status === 500) {
                  setIsloading(false)
                  alert(response.message)
                } else {
                  throw new Error("Something went wrong on api server!")
                }
              })
              .then((response: any) => {
                console.log(response)

                setFormState({
                  ...formState,
                  refresh: ++formState.refresh,
                })
              })
              .catch(error => {
                console.error(error)
              })
          })
      })
    }

    return (
      <Dialog
        open={formState.openVerify}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Enter M-PESA Code & password to proceed!
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="mpesaCode"
            label="M-PESA Code"
            type="text"
            fullWidth
            onChange={handleChange}
            value={formState.mpesaCode}
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            onChange={handleChange}
            value={formState.password}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            PROCEED
          </Button>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleChange = (e: any) => {
    const date = new Date(e.target.value)

    const today =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      " T " +
      (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
      ":" +
      (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())

    if (e.target.name === "starttime") {
      setFormState({
        ...formState,
        starttime: today,
      })

      console.log("OYAA", today)
    } else if (e.target.name === "endtime") {
      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const ledgerPopup = () => {
    const handleCloseCart = () => {
      setFormState({
        ...formState,
        displayLedger: false!,
        arrayLedger: [],
        leaderLedger: 0,
        leaderLedger2: 0,
        leaderID: "",
      })
    }

    const handleVerify = async e => {
      const ClearArray: any = []

      await formState.arrayLedger.forEach(data => {
        if (data.mark) {
          ClearArray.push({
            customerID: data.customerID,
            cledger: data.total,
            LGID: data.key,
            leaderID: data.leaderID,
            orderID: data.orderID,
          })
        }
      })

      setFormState({
        ...formState,
        openVerify: true,
        clearArray: ClearArray,
      })
    }

    const handleHoldLedger = (e, orderID) => {
      const url = "https://api.tushop.io/api/v1/holdOrder"

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: orderID,
          checked: e.target.checked,
        }),
      })
        .then((response: any) => {
          if (response.status === 201 || response.status === 500) {
            return response.json()
          } else {
            alert(response.message)
            throw new Error("Something went wrong on api server!")
          }
        })

        .then(response => {
          setFormState({
            ...formState,
            displayLedger: false,
          })

          console.debug(response)
          alert(response.message)
          handleRefresh()
        })
        .then(() => {
          const logRef =
            "LOG" + Math.floor(100000000 + Math.random() * 900000000)
          firestore
            .collection("AdminLogs")
            .doc(logRef)
            .set({
              LogId: logRef,
              userEmail: userEmail,
              activity: "Updated HOLD status on ledgers",
              date_created: convertDate(new Date()),
              type: "Update",
              orderID: orderID,
              checked: e.target.checked,
            })
            .catch(err => console.log(err.message))
        })
        .catch(error => {
          console.error(error)
        })
    }

    const handleAddAllLedgers = async (e, arrayLedgers) => {
      e.preventDefault()

      if (e.target.checked) {
        setFormState({
          ...formState,
          markedAll: true,
        })

        await arrayLedgers.forEach(data => {
          data.mark = true
        })
      } else {
        setFormState({
          ...formState,
          markedAll: false,
        })

        await arrayLedgers.forEach(data => {
          data.mark = false
        })
      }
    }

    const handleAddLedger = async (e, LGID, ledgerArray) => {
      console.log("YOW")
      console.log(LGID)
      console.log(e.target.checked)
      console.log(formState.arrayLedger)
      e.preventDefault()

      if (e.target.checked) {
        console.log("YOW")

        await ledgerArray.forEach(data => {
          if (data.key === LGID) {
            console.log(data.key)

            data.mark = true
          }
        })
      } else {
        await ledgerArray.forEach(data => {
          if (data.key === LGID) {
            data.mark = false
          }
        })

        if (formState.markedAll) {
          setFormState({
            ...formState,
            markedAll: false,
          })
        }
      }

      setFormState({
        ...formState,
        arrayLedger: ledgerArray,
      })
    }

    return (
      <Dialog
        open={formState.displayLedger}
        onClose={handleCloseCart}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">LEDGERS</DialogTitle>

        <DialogContent>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  height: "5px",
                  width: "100%",
                  backgroundColor: "#d3d3d3",
                }}
              >
                <TableCell>
                  <FormControlLabel
                    label="Clear All"
                    control={
                      <Checkbox
                        checked={formState.markedAll}
                        onChange={e =>
                          handleAddAllLedgers(e, formState.arrayLedger)
                        }
                      />
                    }
                  />
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Ledger ID</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Order ID</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Leader ID</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Customer ID</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Amount</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Hold</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Date</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formState.arrayLedger.map(data => (
                <TableRow key={data.key}>
                  <TableCell>
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          checked={data.mark ? true : false}
                          onChange={e =>
                            handleAddLedger(e, data.key, formState.arrayLedger)
                          }
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>{data.key}</TableCell>
                  <TableCell>{data.orderID}</TableCell>
                  <TableCell>{data.leaderID}</TableCell>
                  <TableCell>
                    <Button
                      onClick={e => handleDisplayCustomer(e, data.customerID)}
                    >
                      {data.customerID}
                    </Button>
                  </TableCell>
                  <TableCell>{data.total}</TableCell>
                  <TableCell>
                    <Switch
                      checked={data.hold}
                      onChange={e => handleHoldLedger(e, data.orderID)}
                      name={data.key}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{data.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => handleVerify(e)}>CLEAR</Button>
          <Button onClick={handleCloseCart} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      {ledgerPopup()}
      {customerPopup()}
      {ledgerLimitEdit()}
      {verifyClear()}
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>
              Ledger Balance: {formState.ledgerTotal}
            </h1>
          </div>
          <div className={classes.row}>
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: "2em", marginTop: "1em" }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Ledger Balance</b>
                  </TableCell>
                  <TableCell>
                    <b>Ledger Limit</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>
                          <Button
                            onClick={e =>
                              handleDisplayLedgers(
                                e,
                                data.leaderID,
                                data.LedgerBalance
                              )
                            }
                          >
                            {data.leaderID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{data.LedgerBalance}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e =>
                              handleEditLedgerLimit(
                                e,
                                data.leaderID,
                                data.LedgerLimit
                              )
                            }
                          >
                            {data.LedgerLimit}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default LedgerTable
