import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useEffect, useState, useRef } from 'react'

import { Auth } from '../../App'
import { ITheme } from '../../theme'
import EditIcon from '@material-ui/icons/Edit'
import FirebaseApp from '../../firebase/firebase'
import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

interface IProps {
  className?: string
}

export interface IFormState {
  blogs: any[]
  refresh: number
  randomId: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  button: {
    padding: '20px',
  },
  refresh: {
    marginRight: theme.spacing(2),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const BlogView: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles()
  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const firebase = FirebaseApp
  const firestore = firebase.firestore()

  const [pending, setPending] = useState(false)
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [btnMessage, setBtnMessage] = useState('UPLOAD IMAGE')
  const [blogTitle, setBlogTitle] = useState<string>('')
  const [blogVideoUrl, setBlogVideoUrl] = useState<string>('')
  const [blogBody, setBlogBody] = useState<string>('')
  const [blogImgUrl, setBlogImgUrl] = useState<string>('')
  const [openDialog, setOpenDialog] = useState(false)
  const [blog, setBlog] = useState({
    id: '',
    title: '',
    body: '',
    img_urls: '',
    video_url: '',
  })

  const [values, setValues] = useState<IFormState>({
    randomId: 'BLG' + Math.floor(Math.random() * 10000),
    blogs: [],
    refresh: 0,
  })

  const editorRef = useRef<TinyMCEEditor | null>(null)
  const storage = firebase.storage()
  let image: File
  useEffect(() => {
    setIsloading(true)
    const fetchBlogs = async () => {
      await fetch(`https://staging.apis.tushop.io/api/v1/blogs`)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setValues({ ...values, blogs: data.data })
          console.log(values.blogs)
        })
        .then(() => setIsloading(false))
        .catch(error => {
          setIsloading(false)
          alert(error.message)
        })
    }

    fetchBlogs()
  }, [values.refresh])

  const handleRefresh = () => {
    setValues({
      ...values,
      refresh: values.refresh + 1,
    })
  }

  const handleDeleteBlog = async id => {
    try {
      console.log('id to delete', id)
      fetch(`https://staging.apis.tushop.io/api/v1/blogs/${id}`, {
        method: 'DELETE',
      })
        .then(response => response.json()) // or res.json()
        .then(data => {
          console.log(data)
          alert('Blog post delete successfully')
          handleRefresh()
        })
        .catch(error => {
          alert(error.message)
        })
    } catch (error) {
      if (error instanceof Error) {
        setPending(false)
        console.log(error.message)
        alert(error.message)
      } else {
        console.log('Unexpected erroror', error)
        setPending(false)
        alert('Unexpected erroror')
      }
    }
  }

  const handleSave = async () => {
    setPending(true)
    try {
      console.log('shakabuyaa', blog)
      const config = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(blog),
      }
      const response = await fetch(
        'https://staging.apis.tushop.io/api/v1/blogs',
        config
      )
      const data = await response.json()
      console.log(data)
      setPending(false)
      if (data.status == 'SUCCESS') {
        setPending(false)
        alert(data.message)
        return
      }
    } catch (error) {
      if (error instanceof Error) {
        setPending(false)
        console.log(error.message)
        alert(error.message)
      } else {
        console.log('Unexpected erroror', error)
        setPending(false)
        alert('Unexpected erroror')
      }
    }
  }

  const handleUploadImage = () => {
    setBtnMessage('PROCESSING')
    const Reference = storage.ref(`BlogImages/${values.randomId}/${image.name}`)
    Reference.put(image)
      .then(snapshot => {
        Reference.getDownloadURL().then(url => {
          const urlString = url.toString()
          setBlogImgUrl(urlString)
        })
      })
      .then(() => setBtnMessage('UPLOAD SUCCESSFULL'))
  }

  const handleEdit = async id => {
    console.log(id)
    setOpenDialog(true)
    try {
      await fetch(`https://staging.apis.tushop.io/api/v1/blogs/${id}`)
        .then(response => {
          return response.json()
        })
        .then(data => {
          setBlogVideoUrl(data.data.video_url)
          setBlogTitle(data.data.title)
          setBlogBody(data.data.body)
          setBlogImgUrl(data.data.img_url)
        })
        .then(() => setIsloading(false))
        .catch(error => {
          setIsloading(false)
          alert(error.message)
        })
    } catch (error) {
      console.log(error)
    }
  }
  const displayEditForm = () => {
    console.log(openDialog)
    return (
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <h1>Edit Blog Article</h1>
        </DialogTitle>
        <DialogContent>
          <Card {...rest} className={clsx(classes.root, className)}>
            <form autoComplete="off" noValidate>
              <CardContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="title"
                  label="Blog title"
                  type="text"
                  fullWidth
                  onChange={e => setBlogTitle(e.target.value)}
                  value={blogTitle}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="video_url"
                  label="Video url"
                  type="text"
                  fullWidth
                  onChange={e => setBlogVideoUrl(e.target.value)}
                  value={blogVideoUrl}
                />

                <br />
                <Editor
                  apiKey="v584hww3w8wptbmaeut4jfadamlqep3ckrn09rek5vmm5mhq"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={blogBody}
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      // 'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'code',
                      // 'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      // 'code',
                      'help',
                      // 'wordcount',
                    ],
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />

                <p>
                  <b>Select Article Image:</b>
                </p>
                <input
                  type="file"
                  className={classes.button}
                  onChange={e => {
                    if (!e.target.files) return
                    image = e.target.files[0]
                    console.log(image)
                  }}
                  name="blog_image"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadImage}
                >
                  <span> {btnMessage}</span>
                </Button>
                <br />
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                >
                  {pending ? (
                    <span>Processing </span>
                  ) : (
                    <span> Update blog </span>
                  )}
                </Button>
              </CardActions>
            </form>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      {displayEditForm()}
      <Card className={classes.box}>
        <TableContainer component={Paper} style={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.tr}>
                <TableCell>
                  <b>Blog ID</b>
                </TableCell>

                <TableCell>
                  <b>Blog title</b>
                </TableCell>
                <TableCell>
                  <b>Video_url</b>
                </TableCell>
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading === false ? (
                values.blogs &&
                values.blogs.map(blog => {
                  return (
                    <TableRow key={blog.id}>
                      <TableCell>{blog.id}</TableCell>
                      <TableCell>{blog.title}</TableCell>
                      <TableCell>{blog.video_url}</TableCell>
                      <TableCell
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleEdit(blog.id)}
                          style={{ marginRight: '1em' }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleDeleteBlog(blog.id)}
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    style={{ textAlign: 'center', justifyContent: 'center' }}
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  )
}

export default BlogView
