import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { CSVLink } from 'react-csv'
import usePlacesAutocomplete from 'use-places-autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'
import { convertDate } from '../../../utils/date_convert'
import serializePhoneNumber from '../../../utils/phone-number'
import sha1 from 'sha1'
interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  customerArray: any[]
  dropdownArray: any[]
  dropdown: any[]
  LedgerLimit: number
  refresh: number
  open: boolean
  openCustomers: boolean
  name: string
  signedupby: string
  DDFee: number
  address: string
  address2: string
  leaderID: string
  zoneID: string
  pNo: string
  email: string
  url: string
  clprofile: any
  clsource: string
  clprofileother: string
  clsourceother: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(2),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const CommunityTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete()
  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [searched, setSearched] = useState<any>('')
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    customerArray: [],
    dropdownArray: [],
    refresh: 0,
    LedgerLimit: 0,
    dropdown: [],
    open: false,
    openCustomers: false,
    DDFee: 0,
    name: '',
    address: '',
    address2: '',
    leaderID: 'CL' + Math.floor(100000000 + Math.random() * 900000000),
    signedupby: '',
    zoneID: '',
    pNo: '',
    email: '',
    url: 'https://shop.tushop.io/order/',
    clprofile: 'Others',
    clsource: 'Others',
    clprofileother: '',
    clsourceother: '',
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 10

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('CommunityLeaders')
        .orderBy('DateCreated', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const {
          Email,
          Name,
          Address,
          LeaderID,
          PhoneNumber,
          Active,
          HVA,
          Url,
          DateCreated,
          Zone,
          Signed,
          PickUp,
          PreSignUp,
          KYC,
          AddressUrl,
          POD,
          LedgerLimit,
          SpecialOffer,
          DDFee,
          DDelivery,
          Verified,
          BA,
          CLProfiles,
          CLSources,
        } = doc.data()
        list.push({
          key: doc.id,
          email: Email,
          address: Address,
          name: Name,
          leaderID: LeaderID,
          phoneNumber: PhoneNumber,
          date: DateCreated,
          active: Active ? true : false,
          pickUp: PickUp ? true : false,
          preSignUp: PreSignUp ? true : false,
          POD: POD ? true : false,
          HVA: HVA ? true : false,
          Type: HVA ? 'HVA' : 'LVA',
          specialOffer: SpecialOffer ? true : false,
          signed: Signed,
          url: Url,
          BA,
          Verified,
          AddressUrl: AddressUrl,
          zone: Zone,
          DDFee: DDFee === undefined ? 0 : DDFee,
          DDelivery: DDelivery === undefined ? false : DDelivery,
          KYC,
          LedgerLimit: LedgerLimit,
          FullNames: KYC === undefined ? "NOT PROVIDED" : `${KYC.FirstName} ${KYC.LastName}`,
          IdNo: KYC === undefined ? "NOT PROVIDED" : KYC.IDNo,
          DOB: KYC === undefined ? "NOT PROVIDED" : KYC.DOB,
          CLProfiles: CLProfiles ?? "NOT PROVIDED",
          CLSources: CLSources ?? "NOT PROVIDED",
        })
      })

      setFormState({
        ...formState,
        array: list,
      })

      setIsloading(false)
    }

    const fetchZoneDropDown = async () => {
      const data = await firestore.collection('Zones').get()

      const dropdownList: any = []

      data.forEach(doc => {
        const { Areas } = doc.data()

        formState.dropdownArray.push(
          <MenuItem value={doc.id}>
            {doc.id} : {Areas}
          </MenuItem>
        )
      })
    }

    //call the fetching functions
    fetchData()
    fetchZoneDropDown()
  }, [formState.refresh])

  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let nameSearch: any = row.name
        let leaderIdSearch: any = row.leaderId
        let addressSearch: any = row.Address
        let phoneNumberSearch: any = row.phoneNumber
        let urlSearch: any = row.url
        let dateSearch: any = row.date
        let signedDateSearch: any = row.signed
        let zoneSearch: any = row.zone
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(leaderIdSearch) ||
          expression.test(addressSearch) ||
          expression.test(nameSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(urlSearch) ||
          expression.test(dateSearch) ||
          expression.test(zoneSearch) ||
          expression.test(signedDateSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = () => {
    setFormState({
      ...formState,
      open: true!,
    })
  }

  const handleInput = e => {
    setValue(e.target.value)
  }

  const handleEdit = (
    e,
    id,
    name,
    address,
    phoneNumber,
    email,
    url,
    zone,
    signed,
    addUrl,
    LedgerLimit,
    DDFee
  ) => {
    setFormState({
      ...formState,
      open: true!,
      leaderID: id,
      name: name,
      address: address,
      pNo: phoneNumber,
      email: email,
      url: url,
      zoneID: zone,
      address2: addUrl,
      LedgerLimit: LedgerLimit,
      signedupby: signed,
      DDFee
    })
  }

  const headers = [
    { label: 'LeaderID', key: 'key' },
    { label: 'Email', key: 'email' },
    { label: 'Name', key: 'name' },
    { label: 'PhoneNumber', key: 'phoneNumber' },
    { label: 'Active', key: 'active' },
    { label: 'Url', key: 'url' },
    { label: 'Zone', key: 'zone' },
    { label: 'Signed up by?', key: 'signed' },
    { label: 'Date', key: 'date' },
    { label: 'Type', key: 'Type' },
    { label: 'CL Profile', key: 'CLProfiles' },
    { label: 'CL Source', key: 'CLSources' },
    { label: 'ID No', key: 'IdNo' },
    { label: 'DOB', key: 'DOB' },
    { label: 'Full Names', key: 'FullNames' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'CommunityLeaders.csv',
  }

  const handleDelete = (e, id) => {
    console.log('ID to: ' + id)
    const deletedUserRecord = id
    firestore
      .collection('CommunityLeaders')
      .doc(id)
      .delete()
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Deleted a community leader',
            date_created: convertDate(new Date()),
            type: 'Delete',
            record: { CL: deletedUserRecord },
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Leader Deleted')
        handleRefresh()
      })
  }

  const handleChangeActivity = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        Active: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated user active status',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeVerified = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        Verified: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated user verified status',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
        handleRefresh()
      })
      .then(() => {
        console.log('Document successfully updated!')
        //handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
        handleRefresh()
      })
  }

  const handleChangeBA = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        BA: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated BA status',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Leader successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeHVA = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        HVA: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated HVA status',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Leader successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangePOD = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        POD: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated POD',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Leader successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangePickUp = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        PickUp: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated pickup station',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Leader successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeDDelivery = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        DDelivery: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)
        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated Direct Delivery',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Leader successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeSpecialOffer = e => {
    e.preventDefault()

    const leaderRef = firestore
      .collection('CommunityLeaders')
      .doc(e.target.name)

    leaderRef
      .update({
        SpecialOffer: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated Special offer',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Leader successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleDisplayCustomers = (e, key) => {
    let list: any = []

    firestore
      .collection('CommunityLeaders')
      .where('LeaderID', '==', key)
      .get()
      .then(data => {
        data.forEach(doc => {
          const {
            Address,
            Name,
            CLProfiles,
            CLSources,
            Email,
            Signed,
            Zone,
            PhoneNumber,
            KYC,
          } = doc.data()

          list.push({
            UID: doc.id,
            Name: Name,
            Address: Address,
            CLProfiles,
            KYC,
            CLSources,
            Email,
            Signed,
            Zone,
            PhoneNumber,
          })
        })
      })
      .then(() => {
        setFormState({
          ...formState,
          customerArray: list,
          openCustomers: true,
        })
      })
  }
  const addCL = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        open: false!,
        leaderID: 'CL' + Math.floor(100000000 + Math.random() * 900000000),
        name: '',
        address: '',
        address2: '',
        zoneID: '',
        signedupby: '',
        pNo: '',
        email: '',
        url: 'https://shop.tushop.io/order/',
      })

      setValue('')
    }

    const handleAddCommunityLeader = () => {
      console.log('you ckicked me')
      if (formState.name === '') {
        console.log('MMH! ', formState.address2)
        return alert('Input Name')
      } else if (formState.address === '') {
        return alert('Input Address')
      } else if (formState.leaderID === '') {
        return alert('Input Neighborhood')
      } else if (formState.pNo === '') {
        return alert('Input Phone Number')
      } else if (formState.email === '') {
        return alert('Input Email')
      } else if (formState.signedupby === '') {
        return alert('Input Signed Up By')
      } else if (formState.address2 === '') {
        return alert('Input Location URL')
      } else if (formState.zoneID === '') {
        return alert('Input Zone')
      } else if (formState.clprofile === '') {
        return alert('Input CL Profile')
      } else if (formState.clsource === '') {
        return alert('Input CL Source')
      } else {
        const date = new Date()

        const today =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : '0' + (date.getMonth() + 1)) +
          '-' +
          (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
          ' T ' +
          (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
          ':' +
          (date.getMinutes() > 9
            ? date.getMinutes()
            : '0' + date.getMinutes()) +
          ':' +
          (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

        console.log('forr state to save --->', formState)
        const CLProfiles =
          formState.clprofile === 'Others'
            ? formState.clprofileother
            : formState.clprofile
        const CLSources =
          formState.clsource === 'Others'
            ? formState.clsourceother
            : formState.clsource
        console.log(CLProfiles, CLSources)
        firestore
          .collection('CommunityLeaders')
          .doc(formState.leaderID)
          .get()
          .then(doc => {
            if (doc.exists) {
              firestore
                .collection('CommunityLeaders')
                .doc(formState.leaderID)
                .update({
                  Name: formState.name,
                  Address: formState.address,
                  AddressUrl: formState.address2,
                  Email: formState.email,
                  LeaderID: formState.leaderID,
                  PhoneNumber: formState.pNo,
                  EncryptPhoneNumber: sha1(serializePhoneNumber(formState.pNo)),
                  LedgerLimit: formState.LedgerLimit,
                  Zone: formState.zoneID,
                  Signed: formState.signedupby,
                  DDFee: formState.DDFee,
                  CLProfiles: CLProfiles,
                  CLSources: CLSources,
                })
                .then(() => {
                  const logRef =
                    'LOG' + Math.floor(100000000 + Math.random() * 900000000)
                  firestore
                    .collection('AdminLogs')
                    .doc(logRef)
                    .set({
                      LogId: logRef,
                      userEmail: userEmail,
                      activity: 'Updated a community leader',
                      date_created: convertDate(new Date()),
                      type: 'Update',
                      record: {
                        Name: formState.name,
                        Address: formState.address,
                        AddressUrl: formState.address2,
                        Email: formState.email,
                        LeaderID: formState.leaderID,
                        PhoneNumber: formState.pNo,
                        LedgerLimit: formState.LedgerLimit,
                        Zone: formState.zoneID,
                        Signed: formState.signedupby,
                        DDFee: formState.DDFee,
                        CLProfiles: CLProfiles,
                        CLSources: CLSources,
                      },
                    })
                    .catch(err => console.log(err.message))
                })
            } else {
              firestore
                .collection('CommunityLeaders')
                .doc(formState.leaderID)
                .set({
                  Name: formState.name,
                  Address: formState.address,
                  AddressUrl: formState.address2,
                  LeaderID: formState.leaderID,
                  PhoneNumber: formState.pNo,
                  EncryptPhoneNumber: sha1(serializePhoneNumber(formState.pNo)),
                  Email: formState.email,
                  Url: formState.url + formState.leaderID,
                  Orders: 0,
                  LedgerBalance: 0,
                  LedgerLimit: -1000,
                  Active: true,
                  Zone: formState.zoneID,
                  Signed: formState.signedupby,
                  OTP: '0',
                  PickUp: false,
                  PreSignUp: true,
                  AmountSold: 0,
                  TotalCommission: 0,
                  CurrentCommission: 0,
                  DDFee: formState.DDFee,
                  DateCreated: today,
                  KYCUpdated: false,
                  CLProfiles: CLProfiles,
                  CLSources: CLSources,
                })
                .then(() => {
                  const logRef =
                    'LOG' + Math.floor(100000000 + Math.random() * 900000000)
                  firestore
                    .collection('AdminLogs')
                    .doc(logRef)
                    .set({
                      LogId: logRef,
                      userEmail: userEmail,
                      activity: 'Added a new community leader',
                      date_created: convertDate(new Date()),
                      type: 'Create',
                      record: {
                        Name: formState.name,
                        Address: formState.address,
                        AddressUrl: formState.address2,
                        Email: formState.email,
                        LeaderID: formState.leaderID,
                        PhoneNumber: formState.pNo,
                        LedgerLimit: formState.LedgerLimit,
                        Zone: formState.zoneID,
                        Signed: formState.signedupby,
                        OTP: '0',
                        PickUp: false,
                        PreSignUp: true,
                        AmountSold: 0,
                        TotalCommission: 0,
                        CurrentCommission: 0,
                        DDFee: formState.DDFee,
                        DateCreated: today,
                        CLProfiles: CLProfiles,
                        CLSources: CLSources,
                      },
                    })
                    .catch(err => console.log(err.message))
                })
            }
          })

        handleClose()
      }
    }

    const handleChange = (e: any) => {
      if (e.target.id === 'name') {
        setFormState({
          ...formState,
          name: e.target.value,
        })
      } else if (e.target.id === 'address') {
        setFormState({
          ...formState,
          address: e.target.value,
        })
      } else if (e.target.id === 'pNo') {
        setFormState({
          ...formState,
          pNo: e.target.value,
        })
      } else if (e.target.id === 'email') {
        setFormState({
          ...formState,
          email: e.target.value,
        })
      } else if (e.target.id === 'leaderID') {
        setFormState({
          ...formState,
          leaderID: e.target.value,
        })
      } else if (e.target.id === 'locationUrl') {
        setFormState({
          ...formState,
          address2: e.target.value,
        })
      } else if (e.target.id === 'signedupby') {
        setFormState({
          ...formState,
          signedupby: e.target.value,
        })
      } else if (e.target.id === 'limit') {
        setFormState({
          ...formState,
          LedgerLimit: e.target.value,
        })
      } else if (e.target.id === "ddfee") {
        setFormState({
          ...formState,
          DDFee: e.target.value,
        })
      } else if (e.target.name === 'zone') {
        setFormState({
          ...formState,
          zoneID: e.target.value,
        })
      }
      // else if (e.target.name === 'clprofile') {
      //   setFormState({
      //     ...formState,
      //     clprofile: e.target.value,
      //   })
      //   console.log('CL profile status ---->', formState.clprofile)
      // }
      else if (e.target.name === 'clsource') {
        setFormState({
          ...formState,
          clsource: e.target.value,
        })
      } else if (e.target.id === 'clprofileother') {
        setFormState({
          ...formState,
          clprofileother: e.target.value,
        })
      } else if (e.target.id === 'clsourceother') {
        setFormState({
          ...formState,
          clsourceother: e.target.value,
        })
      }
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add Community Leader Datails
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add New Community Leader Data below (ID: {formState.leaderID})
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            onChange={handleChange}
            value={formState.name}
          />
          <TextField
            autoFocus
            margin="dense"
            id="address"
            label="Address"
            type="text"
            fullWidth
            onChange={handleChange}
            value={formState.address}
          />
          <TextField
            autoFocus
            margin="dense"
            id="pNo"
            label="Phone Number"
            type="email"
            fullWidth
            onChange={handleChange}
            value={formState.pNo}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            onChange={handleChange}
            value={formState.email}
          />

          <TextField
            autoFocus
            margin="dense"
            id="signedupby"
            label="Signed up by"
            type="text"
            fullWidth
            onChange={handleChange}
            value={formState.signedupby}
          />
          <TextField
            autoFocus
            margin="dense"
            id="limit"
            label="Ledger Limit"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formState.LedgerLimit}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ddfee"
            label="Direct Delivery Fee"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formState.DDFee}
          />
          <FormControl fullWidth>
            <InputLabel id="Zonedropdown">Zone</InputLabel>
            <Select
              labelId="Zonedropdown"
              name="zone"
              onChange={handleChange}
              value={formState.zoneID}
            >
              {formState.dropdownArray}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ marginTop: '1em' }}>
            <InputLabel id="CLprofile">CL Profile</InputLabel>
            <Select
              labelId="CLprofile"
              name="clprofile"
              onChange={e =>
                setFormState({ ...formState, clprofile: e.target.value })
              }
              value={formState.clprofile}
            >
              <MenuItem value="Stay at home mum">Stay at home mum</MenuItem>
              <MenuItem value="Gig economy workers">
                Gig economy workers
              </MenuItem>
              <MenuItem value="Caretaker / Estate manager">
                Caretaker / Estate manager
              </MenuItem>
              <MenuItem value="Small bussiness owners e.g barber shop, beauty shop e.t.c">
                Small bussiness owners e.g barber shop, beauty shop e.t.c
              </MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>
          {formState.clprofile === 'Others' ? (
            <TextField
              autoFocus
              margin="dense"
              id="clprofileother"
              label="Other - CL Profile"
              type="text"
              onChange={handleChange}
              value={formState.clprofileother}
            />
          ) : null}

          <FormControl fullWidth style={{ marginTop: '1em' }}>
            <InputLabel id="CLsource">CL Source</InputLabel>
            <Select
              labelId="CLsource"
              name="clsource"
              onChange={handleChange}
              value={formState.clsource}
            >
              <MenuItem value="Referrals">Referrals</MenuItem>
              <MenuItem value="Boots on the ground">
                Boots on the ground
              </MenuItem>
              <MenuItem value="Brand Ambassadors">Brand Ambassadors</MenuItem>
              <MenuItem value="Estate Activation">Estate Activation</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </FormControl>
          {formState.clsource === 'Others' ? (
            <TextField
              autoFocus
              margin="dense"
              id="clsourceother"
              label="Other - CL Source"
              type="text"
              onChange={handleChange}
              value={formState.clsourceother}
            />
          ) : null}

          {/* <FormControl fullWidth>

              <TextField
                autoFocus
                margin="dense"
                label="Location"
                fullWidth
                onChange={handleInput}
                value={value}
                disabled={!ready}
              />100
              {status === "OK" && data.map((suggestion) => (
                <MenuItem onClick={e => handleSelect(e, suggestion)} value={suggestion.place_id}>{suggestion.description}</MenuItem>
              ))}
            </FormControl> */}

          <TextField
            autoFocus
            margin="dense"
            id="locationUrl"
            label="location URL"
            type="text"
            fullWidth
            onChange={handleChange}
            value={formState.address2}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCommunityLeader} color="primary">
            Add Community Leader
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const displayCustomers = () => {
    console.log(formState.customerArray)
    const handleClose = () => {
      setFormState({
        ...formState,
        openCustomers: false,
      })
    }

    return (
      <Dialog
        open={formState.openCustomers}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <h1>Community Leader Info</h1>
        </DialogTitle>
        <DialogContent>
          {formState.customerArray.map(data => (
            <ul>
              <li style={{ justifyContent: 'space-between' }}>
                <b>CL ID: </b>
                <span>{data.UID}</span>
              </li>
              <li>
                <b>Name: </b> <span>{data.Name}</span>
              </li>
              <li>
                <b>Email: </b> <span>{data.Email}</span>
              </li>
              <li>
                <b>Address: </b> <span>{data.Address}</span>
              </li>
              <li>
                <b>Phone Number: </b> <span>{data.PhoneNumber}</span>
              </li>
              <li>
                <b>CL Profile: </b> <span>{data.CLProfiles}</span>
              </li>
              <li>
                <b>CL Sources: </b>
                <span>{data.CLSources}</span>
              </li>
              <li>
                <b>Signed By: </b> <span>{data.Signed}</span>
              </li>
              <li>
                <b>ID Full Names: </b>
                {data.KYC === undefined || null ? (
                  <span>NOT PROVIDED</span>
                ) : (
                  <span>
                    {data.KYC.FirstName} {data.KYC.LastName}
                  </span>
                )}
              </li>
              <li>
                <b>ID Number: </b>
                {data.KYC === undefined || null ? (
                  <span>NOT PROVIDED</span>
                ) : (
                  <span>{data.KYC.IDNo}</span>
                )}
              </li>
              <li>
                <b>ID Date of Birth: </b>
                {data.KYC === undefined || null ? (
                  <span>NOT PROVIDED</span>
                ) : (
                  <span>{data.KYC.DOB}</span>
                )}
              </li>
              <li>
                <b>ID Images: </b>
                <br />
                {data.KYC === undefined || null ? (
                  <span>NOT PROVIDED</span>
                ) : (
                  <span
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      rowGap: '8px',
                      columnGap: '8px',
                    }}
                  >
                    <img
                      style={{
                        objectFit: 'cover',
                        height: '350px',
                        width: '570px',
                      }}
                      src={data.KYC.FrontIDUrl}
                    />
                    <img
                      style={{
                        objectFit: 'cover',
                        height: '350px',
                        width: '570px',
                      }}
                      src={data.KYC.BackIDUrl}
                      height={570}
                      width={200}
                    />
                  </span>
                )}
              </li>
            </ul>
          ))}
          {/* <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>UID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Neighborhood</b>
                  </TableCell>
                  <TableCell>
                    <b>City</b>
                  </TableCell>
                  <TableCell>
                    <b>LeaderID</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Type</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.customerArray.map(data => (
                  <TableRow key={data.UID}>
                    <TableCell>{data.UID}</TableCell>
                    <TableCell>{data.Name}</TableCell>
                    <TableCell>{data.Address}</TableCell>
                    <TableCell>{data.Neighborhood}</TableCell>
                    <TableCell>{data.City}</TableCell>
                    <TableCell>{data.LeaderID}</TableCell>
                    <TableCell>{data.PhoneNumber}</TableCell>
                    <TableCell>{data.Email}</TableCell>
                    <TableCell>{data.Type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <CardContent>
          <Button color="primary" variant="contained" onClick={handleClickOpen}>
            Add Community Leader
          </Button>
          {addCL()}
          {displayCustomers()}
          <div className={classes.row}>
            <h1 className={classes.h1}>
              Community Leaders: {formState.array.length}
            </h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: '1em' }}
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Url</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Signed up by?</b>
                  </TableCell>
                  <TableCell>
                    <b>HVA</b>
                  </TableCell>
                  <TableCell>
                    <b>BA</b>
                  </TableCell>
                  <TableCell>
                    <b>POD</b>
                  </TableCell>
                  <TableCell>
                    <b>Active</b>
                  </TableCell>
                  <TableCell>
                    <b>Verified</b>
                  </TableCell>
                  <TableCell>
                    <b>Pick Up?</b>
                  </TableCell>
                  <TableCell>
                    <b>Direct Delivery?</b>
                  </TableCell>
                  <TableCell>
                    <b>Special Offer</b>
                  </TableCell>
                  <TableCell>
                    <b>Zone</b>
                  </TableCell>
                  <TableCell>
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    console.log(data.UID, data.Name)
                    return (
                      <TableRow key={data.key}>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={e => {
                              handleDisplayCustomers(e, data.leaderID)
                            }}
                          >
                            {data.leaderID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{data.phoneNumber}</TableCell>
                        <TableCell>
                          <a href={data.url} target="_blank">
                            {data.url}
                          </a>
                        </TableCell>
                        <TableCell>{data.date}</TableCell>
                        <TableCell>{data.signed}</TableCell>
                        <TableCell>
                          <Switch
                            checked={data.HVA}
                            onChange={e => handleChangeHVA(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.BA}
                            onChange={e => handleChangeBA(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.POD}
                            onChange={e => handleChangePOD(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.active}
                            onChange={e => handleChangeActivity(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.Verified}
                            onChange={e => handleChangeVerified(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.pickUp}
                            onChange={e => handleChangePickUp(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.DDelivery}
                            onChange={e => handleChangeDDelivery(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.specialOffer}
                            onChange={e => handleChangeSpecialOffer(e)}
                            name={data.leaderID}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>{data.zone}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={e =>
                              handleEdit(
                                e,
                                data.key,
                                data.name,
                                data.address,
                                data.phoneNumber,
                                data.email,
                                data.url,
                                data.zone,
                                data.signed,
                                data.AddressUrl,
                                data.LedgerLimit,
                                data.DDFee
                              )
                            }
                          >
                            <EditIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default CommunityTable
