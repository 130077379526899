import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/performance'
import 'firebase/storage'
import React from 'react'

const firebaseConfig = {
  apiKey: 'AIzaSyCh2TKYhCJTtd1ySvvhJPu7zkURp4mrthE',
  authDomain: 'tushop-shopping-app.firebaseapp.com',
  databaseURL: 'https://tushop-shopping-app-default-rtdb.firebaseio.com',
  projectId: 'tushop-shopping-app',
  storageBucket: 'tushop-shopping-app.appspot.com',
  messagingSenderId: '241381709741',
  appId: '1:241381709741:web:a800d0d614cc66dc793324',
  measurementId: 'G-3JK7R2HDRP',
}
firebase.firestore.setLogLevel('debug')

const FirebaseApp = firebase.initializeApp(firebaseConfig)

const analytics = firebase.analytics()
const perf = firebase.performance()
const db = firebase.firestore()
const storage = firebase.storage().ref()

const auth = firebase.auth()
const FirebaseContext = React.createContext(null)
const googleProvider = new firebase.auth.GoogleAuthProvider()
const facebookProvider = new firebase.auth.FacebookAuthProvider()
const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com')
const twitterProvider = new firebase.auth.TwitterAuthProvider()
const githubProvider = new firebase.auth.GithubAuthProvider()

export {
  db,
  storage,
  perf,
  analytics,
  FirebaseContext,
  auth,
  googleProvider,
  facebookProvider,
  microsoftProvider,
  twitterProvider,
  githubProvider,
}
export default FirebaseApp
