import Card from '@material-ui/core/Card'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import RestoreIcon from '@material-ui/icons/Restore'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { CardContent, Button, MenuItem } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'

import SearchBar from 'material-ui-search-bar'
import { convertDate } from '../../../utils/date_convert'
import { Auth } from '../../App'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  productDropdown: any[]
  categoryDropdown: any[]
  imgUrls: any[]
  HVAGBParams: any[]
  refresh: number
  order: number
  open: boolean
  displayImages: boolean
  displayParams: boolean
  Tab: string
  productID: string
  category: string
  HVATarget: string
  HVAPrice: string
  GBProdID: string
  imgUrl: string
  GBLimit: number
  GBDailyLimit: number
  ussdOrder: string
  name: string
  quantities: string
  desc: string
  price: number
  GBPrice: number
  BBPODPrice: number
  originalPrice: number
  targetQty: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '50%',
  },
  imageCell: {
    width: '25%',
  },
  textarea: {
    width: '100%',
  },
  button: {
    padding: '20px',
  },

  imageEdit: {
    display: 'block',
    maxWidth: '100%',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const ProductTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    productDropdown: [],
    categoryDropdown: [],
    imgUrls: [],
    HVAGBParams: [],
    displayImages: false,
    displayParams: false,
    Tab: 'ALL',
    refresh: 0,
    order: 0,
    open: false,
    productID: 'T' + Math.floor(100000000 + Math.random() * 900000000),
    GBLimit: 0,
    GBDailyLimit: 0,
    ussdOrder: '',
    HVATarget: '',
    HVAPrice: '',
    category: '',
    GBProdID: '',
    imgUrl: '',
    name: '',
    desc: '',
    quantities: '',
    price: 0,
    GBPrice: 0,
    BBPODPrice: 0,
    originalPrice: 0,
    targetQty: 0,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  const storage = firebase.storage()

  let image: File

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const list: any = []

      const productArray: any = []

      const tabList: any = []

      firestore
        .collection('Products')
        .orderBy('Order', 'asc')
        .get()
        .then(data => {
          data.forEach(doc => {
            const {
              Name,
              Description,
              ImgUrl,
              Order,
              OriginalPrice,
              Price,
              Active,
              QtySold,
              USSD,
              USSDOrder,
              WhatsApp,
              Special,
              SpecialOffer,
              GBProdID,
              GBLimit,
              Quantities,
              GroupBuy,
              GBPOD,
              TargetQty,
              LimitedQty,
              Bulk,
              Category,
              VAT,
              ZeroVAT,
              Archived,
              GBPrice,
              BBPODPrice,
              GBDailyLimit,
            } = doc.data()

            const fields = () => {
              list.push({
                key: doc.id,
                name: Name,
                desc: Description,
                imgUrl: ImgUrl,
                order: Order,
                price: Price,
                originalPrice: OriginalPrice,
                qtySold: QtySold,
                active: Active,
                groupBuy: GroupBuy,
                gbpod: GBPOD,
                gbProdID: GBProdID,
                gbPrice: GBPrice,
                BBPODPrice,
                GBLimit: GBLimit,
                GBDailyLimit,
                quantities: Quantities,
                USSD: USSD,
                VAT: VAT,
                ZeroVAT: ZeroVAT,
                WhatsApp,
                USSDOrder: USSDOrder,
                bulk: Bulk,
                category: Category,
                targetQty: TargetQty,
                LimitedQty: LimitedQty,
                special: Special,
                SpecialOffer: SpecialOffer,
                Archived: Archived,
              })

              if (GroupBuy) {
                productArray.push(
                  <MenuItem id={Price} value={doc.id}>
                    {' '}
                    {doc.id}: {Description} @ {Price}
                  </MenuItem>
                )
              }
            }

            if (formState.Tab === 'ALL' && !Archived) {
              fields()
            } else if (formState.Tab === 'GroupBuy' && GroupBuy && !Archived) {
              fields()
            } else if (formState.Tab === 'Active' && Active && !Archived) {
              fields()
            } else if (formState.Tab === 'BulkBuy' && Bulk && !Archived) {
              fields()
            } else if (formState.Tab === 'Special' && Special && !Archived) {
              fields()
            } else if (formState.Tab === 'USSD' && USSD && !Archived) {
              fields()
            } else if (formState.Tab === 'WhatsApp' && WhatsApp && !Archived) {
              fields()
            } else if (formState.Tab === 'Archived' && Archived) {
              fields()
            }
          })
        })
        .then(() => {
          firestore
            .collection('SKUCategories')
            .get()
            .then(data => {
              data.forEach(doc => {
                const { Name } = doc.data()

                console.log('YOOWW ', Name, doc.id)

                tabList.push(<MenuItem value={doc.id}>{Name}</MenuItem>)
              })
            })
        })
        .then(() => {
          console.log(tabList)

          setFormState({
            ...formState,
            categoryDropdown: tabList,
            array: list,
            productDropdown: productArray,
          })
          setIsloading(false)
        })

      //
    }

    fetchData()
  }, [formState.refresh, formState.Tab])
  console.log(formState.array)
  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let nameSearch: any = row.name
        let descSearch: any = row.desc

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(nameSearch) ||
          expression.test(descSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = () => {
    setFormState({
      ...formState,
      open: true!,
    })
  }

  const handleDelete = (e, id) => {
    console.log('ID: ' + id)
    const deletedUserRecord = id
    firestore
      .collection('Products')
      .doc(id)
      .update({
        Archived: formState.Tab === 'Archived' ? false : true,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Archived a product',
            date_created: convertDate(new Date()),
            type: 'Archive',
            record: { PR_ID: deletedUserRecord },
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        if (formState.Tab === 'Archived') {
          alert('Item Restored')
        } else {
          alert('Item Archived')
        }

        handleRefresh()
      })
  }

  const handleEdit = (
    e,
    id,
    name,
    desc,
    imgUrl,
    order,
    price,
    originalPrice,
    targetQty,
    gbProdID,
    gbPrice,
    gbLimit,
    USSDOrder,
    quantities,
    category,
    BBPODPrice
  ) => {
    setFormState({
      ...formState,
      open: true!,
      productID: id,
      imgUrl: imgUrl,
      ussdOrder: USSDOrder,
      order: order,
      name: name,
      desc: desc,
      price: price,
      quantities: quantities,
      GBProdID: gbProdID === undefined ? '' : gbProdID,
      GBPrice: gbPrice === undefined ? '' : gbPrice,
      GBLimit: gbLimit === undefined ? 0 : gbLimit,
      BBPODPrice: BBPODPrice === undefined ? '' : BBPODPrice,
      originalPrice: originalPrice,
      targetQty: targetQty,
      category: category,
    })
  }

  const handleChangeSpecialOffer = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        SpecialOffer: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated document',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeLQ = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        LimitedQty: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated LQ',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeBB = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        Bulk: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated BB',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeGB = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        GroupBuy: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated GB',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeGBPOD = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        GBPOD: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated GBPOD',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeActivity = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        Active: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated product active status',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeSpecial = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        Special: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated special offer',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        console.log('Document successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeUSSD = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        USSD: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated USSD',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Product successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeWhatsApp = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        WhatsApp: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated whatsapp',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Product successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }
  const handleChangeVAT = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        VAT: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated vat value',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Product successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleChangeZeroVAT = e => {
    e.preventDefault()

    const productRef = firestore.collection('Products').doc(e.target.name)

    productRef
      .update({
        ZeroVAT: e.target.checked,
      })
      .then(() => {
        const logRef = 'LOG' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('AdminLogs')
          .doc(logRef)
          .set({
            LogId: logRef,
            userEmail: userEmail,
            activity: 'Updated Zero VAT',
            date_created: convertDate(new Date()),
            type: 'Update',
            record: e.target.checked,
          })
          .catch(err => console.log(err.message))
      })
      .then(() => {
        alert('Product successfully updated!')
        handleRefresh()
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const displayImages = (e, key) => {
    firestore
      .collection('Products')
      .doc(key)
      .get()
      .then((doc: any) => {
        const { ImgUrls } = doc.data()

        console.log(ImgUrls)
        setFormState({
          ...formState,
          imgUrls: ImgUrls,
          displayImages: true,
          productID: key,
        })
      })
  }

  const displayParams = (e, key) => {
    firestore
      .collection('Products')
      .doc(key)
      .get()
      .then((doc: any) => {
        const { HVAGBParams } = doc.data()

        setFormState({
          ...formState,
          HVAGBParams: HVAGBParams,
          displayParams: true,
          productID: key,
        })
      })
  }

  const addProduct = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        open: false!,
        productID: 'T' + Math.floor(100000000 + Math.random() * 900000000),
        imgUrl: '',
        order: 0,
        name: '',
        desc: '',
        quantities: '',
        price: 0,
        originalPrice: 0,
        targetQty: 0,
      })
      // handleRefresh()
    }

    const handleChange = (e: any) => {
      if (e.target.id === 'name') {
        setFormState({
          ...formState,
          name: e.target.value,
        })
      } else if (e.target.id === 'description') {
        setFormState({
          ...formState,
          desc: e.target.value,
        })
      } else if (e.target.id === 'order') {
        setFormState({
          ...formState,
          order: parseInt(e.target.value),
        })
      } else if (e.target.id === 'price') {
        setFormState({
          ...formState,
          price: e.target.value,
        })
      } else if (e.target.id === 'GBPrice') {
        setFormState({
          ...formState,
          GBPrice: e.target.value,
        })
      } else if (e.target.id === 'ussd') {
        setFormState({
          ...formState,
          ussdOrder: e.target.value,
        })
      } else if (e.target.id === 'quantities') {
        setFormState({
          ...formState,
          quantities: e.target.value,
        })
      } else if (e.target.id === 'originalPrice') {
        setFormState({
          ...formState,
          originalPrice: e.target.value,
        })
      } else if (e.target.name === 'productID') {
        setFormState({
          ...formState,
          GBProdID: e.target.value,
        })

        console.log(e.target.id)
      } else if (e.target.name === 'category') {
        setFormState({
          ...formState,
          category: e.target.value,
        })
      } else if (e.target.id === 'targetQty') {
        setFormState({
          ...formState,
          targetQty: e.target.value,
        })
      } else if (e.target.id === 'gbLimit') {
        setFormState({
          ...formState,
          GBLimit: e.target.value,
        })
      } else if (e.target.id === 'BBPODPrice') {
        setFormState({
          ...formState,
          BBPODPrice: e.target.value,
        })
      } else if (e.target.files[0]) {
        image = e.target.files[0]
      }
    }

    const handleUploadImage = () => {
      const Reference = storage.ref(`ProductImages/${image.name}`)

      Reference.put(image).then(snapshot => {
        Reference.getDownloadURL().then(url => {
          const urlString = url.toString()

          setFormState({
            ...formState,
            imgUrl: urlString,
          })
        })
      })
    }

    const handleAddProduct = () => {
      if (formState.name === '') {
        return alert('Input Product Name')
      } else if (formState.desc === '') {
        return alert('Input Description')
      } else if (formState.imgUrl === '') {
        return alert('Upload Image')
      } else if (formState.price === 0) {
        return alert('Input Valid Price')
      } else if (formState.quantities === '') {
        return alert('Input Valid Buyable Quantities')
      } else if (formState.originalPrice === 0) {
        return alert('Input Valid Original Price')
      } else if (
        formState.category === '' ||
        formState.category === undefined
      ) {
        console.log('PIMBI')

        return alert('Please select product category')
      } else {
        const data = firestore.collection('Products').doc(formState.productID)

        data
          .get()
          .then(doc => {
            if (doc.exists) {
              console.log('DOC EXISTS! UPDATING')

              data
                .update({
                  ProductID: formState.productID,
                  Name: formState.name,
                  Description: formState.desc,
                  ImgUrl: formState.imgUrl,
                  Order: formState.order,
                  Price: formState.price,
                  GBPrice: formState.GBPrice,
                  GBLimit: formState.GBLimit,
                  Category: formState.category,
                  BBPODPrice: formState.BBPODPrice,
                  Quantities: formState.quantities,
                  GBProdID:
                    formState.GBProdID === undefined ? '' : formState.GBProdID,
                  OriginalPrice: formState.originalPrice,
                  GBDailyLimit: formState.GBDailyLimit,
                  USSDOrder:
                    formState.ussdOrder === undefined
                      ? ''
                      : parseInt(formState.ussdOrder),
                  TargetQty: formState.targetQty,
                })
                .then(() => {
                  const logRef =
                    'LOG' + Math.floor(100000000 + Math.random() * 900000000)

                  firestore
                    .collection('AdminLogs')
                    .doc(logRef)
                    .set({
                      LogId: logRef,
                      userEmail: userEmail,
                      activity: 'Updated a Product',
                      date_created: convertDate(new Date()),
                      type: 'Update',
                      record: {
                        ProductID: formState.productID,
                        Name: formState.name,
                        Description: formState.desc,
                        ImgUrl: formState.imgUrl,
                        Order: formState.order,
                        Price: formState.price,
                        GBPrice: formState.GBPrice,
                        GBLimit: formState.GBLimit,
                        Category: formState.category,
                        Quantities: formState.quantities,
                        BBPODPrice: formState.BBPODPrice,
                        GBProdID:
                          formState.GBProdID === undefined
                            ? ''
                            : formState.GBProdID,
                        OriginalPrice: formState.originalPrice,
                        GBDailyLimit: formState.GBDailyLimit,
                        USSDOrder:
                          formState.ussdOrder === undefined
                            ? ''
                            : parseInt(formState.ussdOrder),
                        TargetQty: formState.targetQty,
                      },
                    })
                    .catch(err => console.log(err.message))
                })
                .then(() => {
                  handleClose()
                })
            } else {
              console.log('Creating new doc')
              data
                .set({
                  ProductID: formState.productID,
                  Name: formState.name,
                  Description: formState.desc,
                  ImgUrl: formState.imgUrl,
                  ImgUrls: [formState.imgUrl],
                  Order: formState.order,
                  Price: formState.price,
                  GBProdID:
                    formState.GBProdID === undefined ? '' : formState.GBProdID,
                  GBPrice: formState.GBPrice,
                  OriginalPrice: formState.originalPrice,
                  QtySold: 1,
                  HVAGBParams: [],
                  TargetQty: formState.targetQty,
                  GBLimit: formState.GBLimit,
                  GBDailyLimit: formState.GBDailyLimit,
                  Quantities: formState.quantities,
                  Category: formState.category,
                  GroupBuy: false,
                  BBPODPrice: formState.BBPODPrice,
                  GBPOD: false,
                  VAT: false,
                  GBID: [],
                  Active: true,
                  USSDOrder:
                    formState.ussdOrder === undefined
                      ? ''
                      : parseInt(formState.ussdOrder),
                  Special: false,
                })
                .then(() => {
                  const logRef =
                    'LOG' + Math.floor(100000000 + Math.random() * 900000000)

                  firestore
                    .collection('AdminLogs')
                    .doc(logRef)
                    .set({
                      LogId: logRef,
                      userEmail: userEmail,
                      activity: 'Created a new product',
                      date_created: convertDate(new Date()),
                      type: 'Create',
                      record: {
                        ProductID: formState.productID,
                        Name: formState.name,
                        Description: formState.desc,
                        ImgUrl: formState.imgUrl,
                        ImgUrls: [formState.imgUrl],
                        Order: formState.order,
                        Price: formState.price,
                        GBProdID:
                          formState.GBProdID === undefined
                            ? ''
                            : formState.GBProdID,
                        GBPrice: formState.GBPrice,
                        OriginalPrice: formState.originalPrice,
                        QtySold: 1,
                        HVAGBParams: [],
                        TargetQty: formState.targetQty,
                        GBLimit: formState.GBLimit,
                        GBDailyLimit: formState.GBDailyLimit,
                        Quantities: formState.quantities,
                        Category: formState.category,
                        GroupBuy: false,
                        BBPODPrice: formState.BBPODPrice,
                        GBPOD: false,
                        VAT: false,
                        GBID: [],
                        Active: true,
                        USSDOrder:
                          formState.ussdOrder === undefined
                            ? ''
                            : parseInt(formState.ussdOrder),
                        Special: false,
                      },
                    })
                    .catch(err => console.log(err.message))
                })
                .then(() => {
                  handleClose()
                })
            }
          })
          .catch(error => {
            console.log('Error getting document:', error)
          })
      }
    }

    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Product Datails below (ID: {formState.productID})
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel id='Categorydropdown'>Category</InputLabel>
            <Select
              labelId='Categorydropdown'
              name='category'
              onChange={handleChange}
              value={formState.category}
            >
              <MenuItem>--------Select Group Buy Product--------</MenuItem>
              {formState.categoryDropdown}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Product Name'
            type='text'
            fullWidth
            onChange={handleChange}
            value={formState.name}
          />
          <TextareaAutosize
            aria-label='empty textarea'
            name='productDescription1'
            id='description'
            className={classes.textarea}
            rowsMin={3}
            onChange={handleChange}
            placeholder='Input Product Description'
          // value={formState.desc}
          />
          <p>
            <b>Select Product Image:</b>
          </p>
          <input
            type='file'
            className={classes.button}
            onChange={handleChange}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={handleUploadImage}
          >
            Upload Image
          </Button>
          <br />
          {/* eslint-disable-next-line*/}
          <img src={formState.imgUrl} className={classes.imageEdit} />
          <br />
          <TextField
            autoFocus
            margin='dense'
            id='order'
            label='Order'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.order}
          />
          <TextField
            autoFocus
            margin='dense'
            id='price'
            label='Price (Ksh)'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.price}
          />

          <TextField
            autoFocus
            margin='dense'
            id='originalPrice'
            label='Original Price (Ksh)'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.originalPrice}
          />

          <TextField
            autoFocus
            margin='dense'
            id='targetQty'
            label='Target Quantity'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.targetQty}
          />
          <TextField
            autoFocus
            margin='dense'
            id='gbLimit'
            label='Group Buy Limit'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.GBLimit}
          />
          <TextField
            autoFocus
            margin='dense'
            id='gbDailyLimit'
            label='Group Buy Daily Limit'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.GBDailyLimit}
          />
          <TextField
            autoFocus
            margin='dense'
            id='GBPrice'
            label='Group Buy Price'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.GBPrice}
          />
          <TextField
            autoFocus
            margin='dense'
            id='BBPODPrice'
            label='Bulk Buy POD Price'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.BBPODPrice}
          />
          <FormControl fullWidth>
            <InputLabel id='CLdropdown'>Group Buy Product</InputLabel>
            <Select
              labelId='CLdropdown'
              name='productID'
              onChange={handleChange}
              value={formState.GBProdID}
            >
              <MenuItem>--------Select Group Buy Product--------</MenuItem>
              {formState.productDropdown}
              {/* <MenuItem>Test Item1</MenuItem>
              <MenuItem>Test Item2</MenuItem> */}
            </Select>
          </FormControl>

          <TextField
            autoFocus
            margin='dense'
            id='ussd'
            label='USSD Order'
            type='number'
            fullWidth
            onChange={handleChange}
            value={formState.ussdOrder}
          />

          <TextField
            autoFocus
            margin='dense'
            id='quantities'
            label='Quantities (e.g 1,2,3,4,5,10)'
            type='text'
            fullWidth
            onChange={handleChange}
            value={formState.quantities}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleAddProduct} color='primary'>
            Add Product
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const productImages = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        displayImages: false!,
        imgUrls: [],
      })
      // handleRefresh()
    }

    const handleChange = e => {
      if (e.target.files[0]) {
        image = e.target.files[0]
      }
    }

    const handleUploadImage = () => {
      const Reference = storage.ref(`ProductImages/${image.name}`)

      Reference.put(image).then(snapshot => {
        Reference.getDownloadURL()
          .then(url => {
            const urlString = url.toString()

            // setFormState({
            //   ...formState,
            //   imgUrl: urlString,
            // })

            formState.imgUrls.push(urlString)
          })
          .then(() => {
            setFormState({
              ...formState,
              displayImages: true!,
            })
          })
      })
    }

    const handleUpdateProductImages = () => {
      firestore
        .collection('Products')
        .doc(formState.productID)
        .update({
          ImgUrls: formState.imgUrls,
        })
        .then(() => {
          alert('Images updated')

          handleClose()
        })
    }

    const handleDeleteImg = (e, url) => {
      formState.imgUrls.forEach((data, index) => {
        if (data === url) {
          formState.imgUrls.splice(index, 1)
        }
      })

      setFormState({
        ...formState,
        displayImages: true!,
      })
    }

    return (
      <Dialog
        open={formState.displayImages}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Product</DialogTitle>
        <DialogContent>
          <DialogContentText>Product Images</DialogContentText>
          <p>
            <b>Select Product Image:</b>
          </p>
          <input
            type='file'
            className={classes.button}
            onChange={handleChange}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={handleUploadImage}
          >
            Upload Image
          </Button>
          <br />
          {formState.imgUrls.map(data => (
            <div>
              <hr />
              <Button
                color='secondary'
                variant='contained'
                onClick={e => handleDeleteImg(e, data)}
              >
                <DeleteForeverIcon />
              </Button>
              <img src={data} style={{ width: '100%' }} />
              <hr />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleUpdateProductImages} color='primary'>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const HVAParams = () => {
    const handleClose = () => {
      firestore
        .collection('Products')
        .doc(formState.productID)
        .update({
          HVAGBParams: formState.HVAGBParams,
        })
        .then(() => {
          alert('HVA GB Params updated')

          setFormState({
            ...formState,
            displayParams: false!,
            HVAGBParams: [],
            HVATarget: '',
            HVAPrice: '',
          })
        })
    }

    const handleChange = e => {
      if (e.target.id === 'HVAGBtargetQty') {
        setFormState({
          ...formState,
          HVATarget: e.target.value,
        })
      } else if (e.target.id === 'HVAGBprice') {
        setFormState({
          ...formState,
          HVAPrice: e.target.value,
        })
      }
    }

    const handleUpdateParams = () => {
      formState.HVAGBParams.push({
        targetQty: formState.HVATarget,
        price: formState.HVAPrice,
      })

      firestore
        .collection('Products')
        .doc(formState.productID)
        .update({
          HVAGBParams: formState.HVAGBParams,
        })
        .then(() => {
          alert('HVA GB Params updated')

          setFormState({
            ...formState,
            displayParams: true!,
            HVATarget: '',
            HVAPrice: '',
          })
        })
    }

    const handleDeleteParam = (e, obj) => {
      formState.HVAGBParams.forEach((data, index) => {
        if (data === obj) {
          formState.HVAGBParams.splice(index, 1)
        }
      })

      setFormState({
        ...formState,
        displayParams: true!,
      })
    }

    return (
      <Dialog
        open={formState.displayParams}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Product</DialogTitle>
        <DialogContent>
          <DialogContentText>Define HVA Group Buy Params</DialogContentText>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>Target Qty</TableCell>
                  <TableCell>Target Price</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.HVAGBParams.map(data => (
                  <TableRow>
                    <TableCell>{data.targetQty}</TableCell>
                    <TableCell>{data.price}</TableCell>
                    <TableCell>
                      <Button
                        color='secondary'
                        variant='contained'
                        onClick={e => handleDeleteParam(e, data)}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <TextField
                      autoFocus
                      margin='dense'
                      id='HVAGBtargetQty'
                      label='Target Qty'
                      type='text'
                      fullWidth
                      onChange={handleChange}
                      value={formState.HVATarget}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      autoFocus
                      margin='dense'
                      id='HVAGBprice'
                      label='Price'
                      type='text'
                      fullWidth
                      onChange={handleChange}
                      value={formState.HVAPrice}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={e => handleUpdateParams()}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const tabs = () => {
    return (
      <Card
        className={classes.box}
        style={{ alignContent: 'center', alignItems: 'center' }}
      >
        <Tabs
          onChange={(e, newVal) => {
            setFormState({ ...formState, Tab: newVal })
          }}
          value={formState.Tab}
          variant='fullWidth'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab label={'ALL'} value={'ALL'} />
          <Tab label={'Group Buy'} value={'GroupBuy'} />
          <Tab label={'Active'} value={'Active'} />
          <Tab label={'Bulk Buy'} value={'BulkBuy'} />
          <Tab label={'Special'} value={'Special'} />
          <Tab label={'USSD'} value={'USSD'} />
          <Tab label={'WhatsApp'} value={'WhatsApp'} />
          <Tab label={'Archived'} value={'Archived'} />
        </Tabs>
      </Card>
    )
  }

  return (
    <div className={classes.root}>
      {addProduct()}
      {tabs()}
      {productImages()}
      {HVAParams()}
      <br />
      <Card className={classes.box}>
        <CardContent>
          <Button color='primary' variant='contained' onClick={handleClickOpen}>
            Add Product
          </Button>
          <div className={classes.row}>
            <h1 className={classes.h1}>Products</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Product ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                  <TableCell>
                    <b>Price (Ksh)</b>
                  </TableCell>
                  <TableCell>
                    <b>Original Price (Ksh)</b>
                  </TableCell>
                  <TableCell>
                    <b>Qty Sold</b>
                  </TableCell>
                  <TableCell>
                    <b>Image</b>
                  </TableCell>
                  {formState.Tab === 'GroupBuy' ? (
                    <TableCell>
                      <b>HVA GB Params</b>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell>
                    <b>Order</b>
                  </TableCell>
                  <TableCell>
                    <b>Special Offer</b>
                  </TableCell>
                  <TableCell>
                    <b>Limited Qty?</b>
                  </TableCell>
                  <TableCell>
                    <b>Bulk Buy?</b>
                  </TableCell>
                  <TableCell>Group Buy?</TableCell>
                  <TableCell>Group Buy POD?</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Special</TableCell>
                  <TableCell>USSD</TableCell>
                  <TableCell>WhatsApp</TableCell>
                  <TableCell>VATable?</TableCell>
                  <TableCell>0% VAT?</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{data.desc}</TableCell>
                        <TableCell>{data.price}</TableCell>
                        <TableCell>{data.originalPrice}</TableCell>
                        <TableCell>{data.qtySold}</TableCell>
                        <TableCell className={classes.imageCell}>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e => {
                              displayImages(e, data.key)
                            }}
                          >
                            Add Image
                          </Button>
                        </TableCell>

                        {formState.Tab === 'GroupBuy' ? (
                          <TableCell className={classes.imageCell}>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={e => {
                                displayParams(e, data.key)
                              }}
                            >
                              Add GB Params
                            </Button>
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        <TableCell>{data.order}</TableCell>
                        <TableCell>
                          <Switch
                            checked={data.SpecialOffer}
                            onChange={e => handleChangeSpecialOffer(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.LimitedQty}
                            onChange={e => handleChangeLQ(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.bulk}
                            onChange={e => handleChangeBB(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.groupBuy}
                            onChange={e => handleChangeGB(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.gbpod}
                            onChange={e => handleChangeGBPOD(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.active}
                            onChange={e => handleChangeActivity(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.special}
                            onChange={e => handleChangeSpecial(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.USSD}
                            onChange={e => handleChangeUSSD(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.WhatsApp}
                            onChange={e => handleChangeWhatsApp(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.VAT}
                            onChange={e => handleChangeVAT(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={data.ZeroVAT}
                            onChange={e => handleChangeZeroVAT(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e =>
                              handleEdit(
                                e,
                                data.key,
                                data.name,
                                data.desc,
                                data.imgUrl,
                                data.order,
                                data.price,
                                data.originalPrice,
                                data.targetQty,
                                data.gbProdID,
                                data.gbPrice,
                                data.GBLimit,
                                data.USSDOrder,
                                data.quantities,
                                data.category,
                                data.BBPODPrice
                              )
                            }
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            color='secondary'
                            variant='contained'
                            onClick={e => handleDelete(e, data.key)}
                          >
                            {formState.Tab === 'Archived' ? (
                              <RestoreIcon />
                            ) : (
                              <DeleteForeverIcon />
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default ProductTable
