import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  MenuItem,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { getInitials } from '../../../utils';
import { Auth } from '../../App';
import { ITheme } from '../../theme';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FirebaseApp from '../../firebase/firebase';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

interface IProps {
  className?: string;
}

export interface IFormState {
  dropdownArray: any[];
  CLID: string;
  CLID2: string;
  CLName: string;
  Reason: string;
  LeaderReason: string;
  refresh: number;
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 105,
    width: 105,
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: "50%",
    boxShadow: "0 0 0 2px transparent",
    "&:hover": {
      cursor: "pointer",
      overflow: "hidden",
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`
    }
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  locationText: {},
  dateText: {},
  textarea: {
    width: "100%",
  },
}));

const CLOW: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user, authUser } = Auth.useContainer();

  const firebase = FirebaseApp;
  const firestore = firebase.firestore();

  const [values, setValues] = useState<IFormState>({
    dropdownArray: [],
    CLID: "",
    CLID2: "",
    CLName: "",
    LeaderReason: "",
    Reason: "",
    refresh: 0
  });

  useEffect(() => {

    const fetchCureentCLOW = async () => {

      const data = await firestore.collection("CommunityLeaders").where("CLOW", "==", true).get()

      let id = ""

      let name = ""

      data.forEach((doc) => {

        console.log("OYAA");
        
        const {Name, ReasonCLOW} = doc.data()

        setValues({
          ...values,
          CLID: doc.id,
          CLID2: doc.id,
          CLName: Name,
          LeaderReason: ReasonCLOW
        })

      })

    }

    const fetchDropdownData = async () => {
      const data = await firestore.collection("CommunityLeaders").orderBy("Name", "asc").get()
      
      data.forEach(doc => {
        const { Name } = doc.data();

        values.dropdownArray.push(<MenuItem value={doc.id}>{doc.id} : {Name}</MenuItem>)
        
      });

    }

    fetchCureentCLOW()
    fetchDropdownData();


  }, [values.refresh]);

  if (!user || !authUser) {
    return null;
  }

  const handleUpdateCLOW = async () => {

    if(values.Reason === ""){
      alert("Please enter reason for selecting")
    }else{

      await firestore.collection("CommunityLeaders").doc(values.CLID).update({
        CLOW: false,
        ReasonCLOW: ""
      }).then(() => {
        firestore.collection("CommunityLeaders").doc(values.CLID2).update({
          CLOW: true,
          ReasonCLOW: values.Reason
        })
      }).then(() => {
        alert("Leader Updated")
  
        setValues({
          ...values,
          dropdownArray: [],
          refresh: values.refresh+1,
          Reason: ""
        })
      })
    }
  };

  const handleChange = (e : any) => {

    if(e.target.id === "description"){
    
      setValues({
        ...values,
        Reason: e.target.value
      })
    }else{

      console.log(e.target.value);

      setValues({
        ...values,
        CLID2: e.target.value
      })
    }
    
  }

  const initials = getInitials(authUser.displayName || 'NOPE');

  const complettness = Math.round((Object.values(user).filter(String).length / Object.keys(user).length) * 100);

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <h2>Community Leader of the week!</h2>
        </CardContent>  
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <p>Current community leader of the week</p>
          <Typography gutterBottom variant="h4">
            {values.CLID} : {values.CLName}
          </Typography>
          <p>{values.LeaderReason}</p>
          <br/>
          <Divider />
          <br/>
          <p>
            Select the community leader of the week
          </p>
          <FormControl fullWidth>
            <InputLabel id="Leaderdropdown">Leader</InputLabel>
              <Select
                labelId="Leaderdropdown"
                name="leader"
                onChange={handleChange}
                value={values.CLID2}
              >
                {values.dropdownArray}
              </Select>
          </FormControl>
          <br/>
          <Divider />
          <br/>
          <TextareaAutosize 
            aria-label="empty textarea" 
            name="productDescription1" 
            id="description"
            className={classes.textarea} 
            rowsMin={5} 
            onChange={handleChange} 
            placeholder="Reason" 
            value={values.Reason}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            onClick={handleUpdateCLOW}
            variant="contained"
            color="primary" >
            Update
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CLOW;
