import Card from "@material-ui/core/Card"
import { CardContent } from "@material-ui/core"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/styles"
import React, { useState, useEffect } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import { Button } from "@material-ui/core"
import RefreshIcon from "@material-ui/icons/Refresh"
import { ITheme } from "../../theme"
import FirebaseApp from "../../firebase/firebase"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import { CSVLink } from "react-csv"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CircularProgress from "@material-ui/core/CircularProgress"
import Pagination from "@material-ui/lab/Pagination"
import usePagination from "../../../utils/Pagination"
import SearchBar from "material-ui-search-bar"

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  cartID: string
  totalItems: number
  cartItems: any[]
  displayCart: boolean
  displayCustomer: boolean
  grandTotal: string
  CodeIDSearch: string
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  box: {
    padding: "16px",
    justifyContent: "center",
  },
  tr: {
    background: "#D3D3D3",
  },
  h1: {
    textAlign: "center",
  },
  refresh: {
    marginLeft: "auto",
  },
  pager: {
    width: "100%",
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: "center",
  },
}))

const FailedTransactionsTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [searched, setSearched] = useState<any>("")
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    totalItems: 0,
    cartID: "",
    cartItems: [],
    displayCart: false,
    displayCustomer: false,
    grandTotal: "",
    CodeIDSearch: "",
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection("CLFeedback")
        .orderBy("DateOfFeedback", "desc")
        .get()

      const list: any = []

      data.forEach(doc => {
        const { DateOfFeedback, Feedback, Rate, UserID } = doc.data()

        list.push({
          FID: doc.id,
          UserID: UserID,
          Rate: Rate,
          Feedback: Feedback,
          Date: DateOfFeedback,
        })
      })

      setFormState({
        ...formState,
        array: list,
        totalItems: list.length,
      })
      setIsloading(false)
    }
    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== "") {
      const filteredRows = formState.array.filter(row => {
        let leaderIdSearch: any = row.UserID
        let feedbackSearch: any = row.Feedback
        let unkown: any
        let expression = new RegExp(searchedVal, "gi")
        return (
          expression.test(leaderIdSearch) ||
          expression.test(feedbackSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched("")
    if (searched === "" || searched.trim().length < 1) {
      console.log("OOP, nothing in your search bar")
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleDisplayLeader = (e, UID) => {
    let list: any = []
    firestore
      .collection("CommunityLeaders")
      .doc(UID)
      .get()
      .then((doc: any) => {
        if (doc.exists) {
          const { Name, PhoneNumber } = doc.data()

          list.push({
            UID: UID,
            Name: Name,
            PhoneNumber: PhoneNumber,
          })
        }
      })
      .then(() => {
        setFormState({
          ...formState,
          cartItems: list,
          displayCustomer: true,
        })
      })
  }
  const headers: any = [
    { label: "FID", key: "FID" },
    { label: "Leader ID", key: "UserID" },
    { label: "Rate", key: "Rate" },
    { label: "Feedback", key: "Feedback" },
    { label: "Date", key: "Date" },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: "CL feedback.csv",
  }
  const handleCloseCustomer = () => {
    setFormState({
      ...formState,
      cartItems: [],
      displayCustomer: false,
    })
  }

  const DialogCustomer = () => {
    return (
      <Dialog
        open={formState.displayCustomer}
        onClose={handleCloseCustomer}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1>Leader Info</h1>
        </DialogTitle>
        <DialogContent>
          {formState.cartItems.map(data => (
            <ul>
              <li>
                <b>CLID: </b> {data.UID}
              </li>
              <li>
                <b>Name: </b> {data.Name}
              </li>
              <li>
                <b>Phone Number: </b> {data.PhoneNumber}
              </li>
            </ul>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCustomer} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        {DialogCustomer()}
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>CL Feedback: {formState.totalItems}</h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              style={{ marginRight: "1em" }}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: "2em", marginTop: "1em" }}
          />

          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Feedback ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Rate</b>
                  </TableCell>
                  <TableCell>
                    <b>Feedback</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.FID}>
                        <TableCell>{data.FID}</TableCell>
                        <TableCell>
                          {" "}
                          <Button
                            onClick={e => handleDisplayLeader(e, data.UserID)}
                          >
                            {data.UserID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.Rate}</TableCell>
                        <TableCell>{data.Feedback}</TableCell>
                        <TableCell>{data.Date}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default FailedTransactionsTable
