import { makeStyles } from "@material-ui/styles"
import { RouteComponentProps } from "@reach/router"
import React, { useState } from "react"
import { ITheme } from "../../theme"
import AllCommissionsTable from "./CommissionsTable"

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

const AllCommissionsList: React.FC<RouteComponentProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AllCommissionsTable />
      </div>
    </div>
  )
}

export default AllCommissionsList
