import { makeStyles } from '@material-ui/styles'
import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { ITheme } from '../../theme'
import AllPayOutsTable from './AllPayOutsTable'


const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(-3)
  }
}))

const AllPayOuts: React.FC<RouteComponentProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AllPayOutsTable />
      </div>
    </div>
  )
}

export default AllPayOuts
