import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getInitials } from '../../../utils'
import { Auth } from '../../App'
import { ITheme } from '../../theme'
import RefreshIcon from '@material-ui/icons/Refresh'
import FirebaseApp from '../../firebase/firebase'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

interface IProps {
  className?: string
}

export interface IFormState {
  news: any[]
  CLID: string
  CLID2: string
  CLName: string
  Reason: string
  LeaderReason: string
  refresh: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {},
  imageEdit: {
    display: "block",
    maxWidth: "100%"
  },
  avatar: {
    marginLeft: 'auto',
    height: 105,
    width: 105,
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: "50%",
    boxShadow: "0 0 0 2px transparent",
    "&:hover": {
      cursor: "pointer",
      overflow: "hidden",
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`
    }
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  locationText: {},
  dateText: {},
  textarea: {
    width: "100%",
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  h1: {
    textAlign: 'center',
  },
}))

const NewsView: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles()
  const { user, authUser } = Auth.useContainer()

  const firebase = FirebaseApp
  const firestore = firebase.firestore()

  const [values, setValues] = useState<IFormState>({
    news: [],
    CLID: "",
    CLID2: "",
    CLName: "",
    LeaderReason: "",
    Reason: "",
    refresh: 0
  })

  useEffect(() => {

    const fetchNews = async () => {

      let newsItems: any = []

      const data = await firestore.collection("PopUps").orderBy("Date", "desc").get()

      data.forEach((doc) => {

        console.log("OYAA")

        const { ImgUrl } = doc.data()

        newsItems.push({
          key: doc.id,
          ImgUrl: ImgUrl
        })

      })

      setValues({
        ...values,
        news: newsItems,
      })

    }

    fetchNews()

  }, [values.refresh])

  const handleRefresh = (e: any) => {

    setValues({
      ...values,
      refresh: values.refresh + 1
    })

  }


  const handleDeleteNewsArticle = (e, key) => {

    firestore.collection("PopUps").doc(key).delete().then(() => {
      alert("Item Deleted!")

      setValues({
        ...values,
        refresh: values.refresh + 1
      })
    })

  }

  return (

    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <div className={classes.row}>
            <h2>PopUp Display</h2>
            <span className={classes.spacer} />
            <Button color="primary" variant="contained" onClick={e => handleRefresh(e)} className={classes.refresh}>
              <RefreshIcon />
            </Button>
          </div>
        </CardContent>
      </Card>
      {values.news.map(data =>
        <>
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
              <div className={classes.row}>
                <h4 className={classes.h1}>{data.key}</h4>
                <span className={classes.spacer} />
                <Button color="primary" variant="contained" onClick={e => handleDeleteNewsArticle(e, data.key)} className={classes.refresh}>
                  <DeleteForeverIcon />
                </Button>
              </div>
              <Divider />
              <Typography gutterBottom variant="h2">
                {data.Header}
              </Typography>
              <Divider />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={data.ImgUrl} className={classes.imageEdit} />
              </div>
              <Divider />
              <p>{data.Article}</p>
            </CardContent>
            <Divider />
          </Card>
          <br />
        </>

      )}
    </>
  )
}

export default NewsView
