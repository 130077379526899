import Card from '@material-ui/core/Card';
import { CardContent, MenuItem } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ITheme } from '../../theme';
import FirebaseApp from '../../firebase/firebase';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { CSVLink } from "react-csv";
import usePlacesAutocomplete from "use-places-autocomplete";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

interface IProps {
  className?: string;
}

export interface IFormState {
  array: any[];
  commissionArray: any[];
  TotalActiveCustomers: number;
  AllNewCustomers: number;
  bonusArray: any[];
  refresh: number;
  totalCommissions: number;
  open: boolean;
  starttime: string;
  endtime: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4)
  },
  spacer: {
    flexGrow: 1
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    padding: "16px",
    justifyContent: 'center',  
  },
  tr:{
    background: "#D3D3D3",
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  
}));

const CommissionTable: React.FC<IProps> = () => {
  const classes = useStyles();

  const week  = new Date();

  week.setDate(week.getDate() - 7);

  const weekdate = week.getFullYear()+
                '-'+
                ((week.getMonth()+1)>9?(week.getMonth()+1):'0'+(week.getMonth()+1))+
                '-'+
                (week.getDate()>9?(week.getDate()):'0'+(week.getDate()))+
                ' T 00:00'

  const [formState, setFormState] = useState<IFormState>({
    array: [],
    commissionArray: [],
    bonusArray: [],
    AllNewCustomers: 0,
    TotalActiveCustomers: 0,
    refresh: 0,
    totalCommissions: 0,
    open: false,
    starttime: "",
    endtime: "",
  });

  const firebase = FirebaseApp;

  const firestore = firebase.firestore();

  useEffect(() => {

    const fetchOrderData = () => {

      if(formState.starttime !== "" && formState.endtime !== ""){
        const url = "https://api.tushop.io/api/v1/activeUsers"

        fetch(url, 
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              startDate: formState.starttime,
              endDate: formState.endtime,
            })
          }
        ).then((response: any) => {
          if (response.status === 200) {
            return response.json()
          } else {
            alert(response.error)
            throw new Error('Something went wrong on api server!');
          }
        }).then(response => {
        

          setFormState({
            ...formState,
            array: response.Data,
            AllNewCustomers: response.AllNewCustomers,
            TotalActiveCustomers: response.TotalActiveCustomers
          })
    
        }).catch(error => {
    
            console.error(error);
    
        });
      }
                            
    }


    fetchOrderData();
  }, [formState.refresh]);

  const handleRefresh = () => {
    setFormState({
      ...formState,
    refresh: formState.refresh+1})
  }


  const handleChange = (e : any) => {

    if(e.target.name === "starttime"){
      // setFormState({
      //   ...formState,
      //   starttime: e.target.value
      // })

      const date = new Date(e.target.value);

      const today = date.getFullYear()+
        '-'+
        ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
        '-'+
        (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
        ' T '+
        (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
        ":"+ 
        (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

      setFormState({
        ...formState,
        starttime: today
      })

    }else if(e.target.name === "endtime"){

      const date = new Date(e.target.value);

      const today = date.getFullYear()+
        '-'+
        ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
        '-'+
        (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
        ' T '+
        (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
        ":"+ 
        (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

      setFormState({
        ...formState,
        endtime: today
      })

    }
  }

  const handleFilterDate = (e) => {
    if(formState.starttime === ""){
      alert("ENTER START DATE")
    }else if(formState.endtime === ""){
      alert("ENTER END DATE")
    }else{
      setFormState({
        ...formState,
        refresh: formState.refresh + 1
      })
    }
  }

  const date = new Date();
  const today = date.getFullYear()+
                '-'+
                ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
                '-'+
                (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
                ' T '+
                (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
                ":"+ 
                (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

  const headers = [
    { label: "customerID", key: "ID" },
    { label: "customerName", key: "Name" },
    { label: "Phone Number", key: "PNo" },
    { label: "QtyOfOrders", key: "QtyOfOrders" }
  ];

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'NewActiveCustomers('+ today + ').csv'
  };

  return (
      <div className={classes.root}>
        <Card className={classes.box}>
          <CardContent>
            <div className={classes.row}>
              <h1>New Active Customers</h1>
              <span className={classes.spacer} />
              <Button color="primary" variant="contained" className={classes.refresh}>
                <CSVLink onClick={handleRefresh} {...csvReport}>Export to CSV</CSVLink>
              </Button>
            </div>
            <div className={classes.row}>
              <TextField
                  id="datetime-local"
                  onChange={handleChange}
                  name="starttime"
                  label="Start Date/Time"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
              <span className={classes.spacer} />
              <TextField
                  id="datetime-local"
                  onChange={handleChange}
                  name="endtime"
                  label="End Date/Time"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
              <span className={classes.spacer} />
              <Button color="primary" variant="contained" onClick={e => handleFilterDate(e)} className={classes.refresh}>FILTER</Button>
            </div>  
            <br/>
            <h1>Total New Users {formState.AllNewCustomers}</h1>
            <h1>Active Users {formState.TotalActiveCustomers}</h1>
            <br/>
            <TableContainer component={Paper} style={{maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.tr}>
                    <TableCell><b>Customer ID</b></TableCell>
                    <TableCell><b>Customer Name</b></TableCell>
                    <TableCell><b>Phone Number</b></TableCell>
                    <TableCell><b>No. of orders</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {formState.array.map(data =>
                  <TableRow key={data.ID}>
                    <TableCell>{data.ID}</TableCell>
                    <TableCell>{data.Name}</TableCell>
                    <TableCell>{data.PNo}</TableCell>
                    <TableCell>{data.QtyOfOrders}</TableCell>
                  </TableRow>
                )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
  );
};

export default CommissionTable;
