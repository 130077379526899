import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Button, MenuItem } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  table: any[]
  refresh: number
  order: number
  open: boolean
  loading: boolean
  openCart: boolean
  Selectcustomer: any[]
  displayCart: boolean
  cartItems: any[]
  cartID: string
  customers: any[]
  CartID: string
  qty: string
  imgUrl: string
  name: string
  desc: string
  price: number
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  image: {
    display: 'block',
    maxWidth: '50%',
  },
  imageCell: {
    width: '25%',
  },
  textarea: {
    width: '100%',
  },
  button: {
    padding: '20px',
  },

  imgStyle: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
  },

  price: {
    textAlign: 'right',
    width: '1%',
    color: 'green',
    height: '5px',
    padding: '0px',
  },

  rowStyle: {
    textAlign: 'center',
    width: '100%',
    marginTop: 'auto',
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const ProductTable: React.FC<IProps> = () => {
  const { authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    table: [],
    refresh: 0,
    order: 0,
    open: false,
    openCart: false,
    loading: false,
    displayCart: false,
    cartItems: [],
    cartID: '',
    Selectcustomer: [],
    customers: [],
    qty: '0',
    CartID: 'CT' + Math.floor(100000000 + Math.random() * 900000000),
    imgUrl: '',
    name: '',
    desc: '',
    price: 0,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  const storage = firebase.storage()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.table.length / PER_PAGE)
  const _DATA = usePagination(formState.table, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  // let image: File;

  useEffect(() => {
    const fetchTableData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('Carts')
        .orderBy('CreatedAt', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const { CartId, CustomerID, LeaderID, CustomerName, CreatedAt } =
          doc.data()

        list.push({
          key: doc.id,
          CartID: CartId,
          CustomerID: CustomerID,
          Name: CustomerName,
          LeaderID: LeaderID,
          CreatedAt: CreatedAt,
          Link: 'https://shop.tushop.io/MyshoppingCart/' + doc.id,
        })
      })

      setFormState({
        ...formState,
        table: list,
      })
      setIsloading(false)
    }

    fetchTableData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.table.filter(row => {
        let keySearch: any = row.key
        let nameSearch: any = row.Name
        let leaderIdSearch: any = row.LeaderId
        let customerIdSearch: any = row.CustomerId
        let linkSearch: any = row.Link
        let dateSearch: any = row.CreatedAt
        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(leaderIdSearch) ||
          expression.test(customerIdSearch) ||
          expression.test(nameSearch) ||
          expression.test(linkSearch) ||
          expression.test(dateSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, table: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  const fetchDropdownData = async () => {
    const data = await firestore.collection('Customers').get()

    data.forEach(doc => {
      const { Address, City, LeaderID, Name, Neighborhood, PhoneNumber, Type } =
        doc.data()

      formState.customers.push({
        UID: doc.id,
        Name: Name,
        Address: Address,
        Neighborhood: Neighborhood,
        City: City,
        PhoneNumber: PhoneNumber,
        LeaderID: LeaderID,
        Type: Type,
      })
    })
  }

  const fetchProductData = async () => {
    const data = await firestore
      .collection('Products')
      .orderBy('Order', 'asc')
      .get()

    const list: any = []

    data.forEach(doc => {
      const { Name, Description, ImgUrl, Price, QtySold, Active, Special } =
        doc.data()

      formState.array.push({
        key: doc.id,
        name: Name,
        desc: Description,
        imgUrl: ImgUrl,
        qtySold: QtySold,
        price: Price,
        array: [1, 2, 3, 4, 5, 10],
      })
    })
  }

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleClickOpen = async () => {
    setFormState({
      ...formState,
      loading: true,
    })

    await fetchDropdownData()

    setFormState({
      ...formState,
      open: true!,
      loading: false,
    })
  }

  const handleClose = () => {
    setFormState({
      ...formState,
      open: false!,
      customers: [],
      Selectcustomer: [],
    })
    // handleRefresh()
  }

  const handleCloseCart = () => {
    setFormState({
      ...formState,
      openCart: false!,
      CartID: 'CT' + Math.floor(100000000 + Math.random() * 900000000),
      customers: [],
      array: [],
      Selectcustomer: [],
      loading: false,
      refresh: formState.refresh + 1,
    })
    // handleRefresh()
  }

  const handleProceedToCart = async () => {
    setFormState({
      ...formState,
      loading: true,
    })

    await fetchProductData()

    setFormState({
      ...formState,
      open: false!,
      openCart: true!,
      loading: false,
    })
  }

  const handleAddCustomer = (e, UID, Name, Address, LeaderID) => {
    if (e.target.checked) {
      formState.Selectcustomer[UID] = {
        UID: UID,
        Name: Name,
        Address: Address,
        LeaderID: LeaderID,
      }

      console.log('CUSTOMA', formState.Selectcustomer)
    } else {
      delete formState.Selectcustomer[UID]

      console.log('CUSTOMB', formState.Selectcustomer)
    }
  }

  const DialogCartAssigner = () => {
    return (
      <Dialog
        open={formState.open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          Assign Customers to Cart
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the customer you wish to assign the cart to
          </DialogContentText>
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>Mark</TableCell>
                  <TableCell>
                    <b>UID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Neighborhood</b>
                  </TableCell>
                  <TableCell>
                    <b>City</b>
                  </TableCell>
                  <TableCell>
                    <b>Phone Number</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Type</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.customers.map(data => (
                  <TableRow key={data.UID}>
                    <TableCell>
                      <FormControlLabel
                        label=''
                        control={
                          <Checkbox
                            onChange={e =>
                              handleAddCustomer(
                                e,
                                data.UID,
                                data.Name,
                                data.Address,
                                data.LeaderID
                              )
                            }
                          />
                        }
                      />
                    </TableCell>
                    <TableCell>{data.UID}</TableCell>
                    <TableCell>{data.Name}</TableCell>
                    <TableCell>{data.Address}</TableCell>
                    <TableCell>{data.Neighborhood}</TableCell>
                    <TableCell>{data.City}</TableCell>
                    <TableCell>{data.PhoneNumber}</TableCell>
                    <TableCell>{data.LeaderID}</TableCell>
                    <TableCell>{data.Type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleProceedToCart} color='primary'>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const loader = () => {
    return (
      <Dialog
        open={formState.loading}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogContent>
          <LoadingSpinner />
          <p style={{ textAlign: 'center' }}>Please wait</p>
        </DialogContent>
      </Dialog>
    )
  }

  const DialogCart = () => {
    const handleAddtoCart = (e, key) => {
      console.log('Hellooo')

      setFormState({
        ...formState,
        loading: true,
      })

      console.log(key, formState.CartID)

      const firestore = firebase.firestore()

      const fetchData = async () => {
        const myProduct = firestore.collection('Products').doc(key)
        const doc = await myProduct.get()
        if (!doc.exists) {
          console.log('No such document!')
        } else {
          if (formState.qty === '0') {
            alert('Select Quantity')
          } else {
            const product1 = doc.data()

            const product2 = JSON.stringify(product1)

            const product = JSON.parse(product2)

            try {
              const total = parseInt(product['Price']) * parseInt(formState.qty)
              // console.log('Document data:', product["Price"]);
              const itemRef = firebase
                .database()
                .ref('shoppingCarts/' + formState.CartID + '/' + key)

              itemRef.once('value').then(snapshot => {
                itemRef
                  .set({
                    productDesc: product['Description'],
                    productID: product['ProductID'],
                    productPrice: product['Price'],
                    quantity: formState.qty,
                    total: total,
                  })
                  .then(() => {
                    setFormState({
                      ...formState,
                      qty: '0',
                      loading: false,
                    })
                  })
              })
            } catch (error) {
              console.error(error)
              // expected output: ReferenceError: nonExistentFunction is not defined
              // Note - error messages will vary depending on browser
            }
          }
        }
      }

      fetchData()
    }

    const handleCheck = event => {
      setFormState({
        ...formState,
        qty: event.target.value,
      })
    }

    const handleCreateCart = () => {
      setFormState({
        ...formState,
        loading: true,
      })

      const date = new Date()

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
        ':' +
        (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

      let i = 1

      for (const customer in formState.Selectcustomer) {
        const data = formState.Selectcustomer[customer]

        const RecordID =
          'ACT' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('Carts')
          .doc(RecordID)
          .set({
            CartId: formState.CartID,
            CustomerID: customer,
            CustomerName: data.Name,
            Address: data.Address,
            LeaderID: data.LeaderID,
            CreatedAt: today,
          })
          .then(() => {
            i++
          })
          .then(() => {
            if (i === Object.keys(formState.Selectcustomer).length) {
              handleCloseCart()
            } else if (Object.keys(formState.Selectcustomer).length === 1) {
              handleCloseCart()
            }
          })
      }

      // formState.Selectcustomer.forEach((customer) => {

      // })
    }

    return (
      <Dialog
        open={formState.openCart}
        onClose={handleCloseCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Add Items to Cart</DialogTitle>
        <DialogContent>
          <DialogContentText>Select Items to add to cart</DialogContentText>
          <Table>
            {formState.array.map(data => (
              <TableBody>
                <TableRow key='row1' className={classes.rowStyle}>
                  <TableCell colSpan={2}>
                    <img src={data.imgUrl} className={classes.imgStyle} />
                  </TableCell>
                </TableRow>
                <TableRow key='row2'>
                  <TableCell style={{ height: '2px', padding: '0px' }}>
                    <h2>
                      <b>{data.desc}</b>
                    </h2>
                  </TableCell>
                  <TableCell className={classes.price}>
                    <h2>
                      <b>{data.price}Ksh</b>
                    </h2>
                  </TableCell>
                </TableRow>
                <TableRow key='row4'>
                  <TableCell colSpan={2}>
                    <FormLabel>Select Quantity</FormLabel>
                    <RadioGroup
                      name='spacing'
                      aria-label='spacing'
                      defaultValue='0'
                      onChange={handleCheck}
                      row
                    >
                      {data.array.map(value => (
                        <FormControlLabel
                          key={value}
                          value={value.toString()}
                          control={<Radio />}
                          label={value.toString()}
                        />
                      ))}
                    </RadioGroup>

                    <Button
                      variant='contained'
                      color='primary'
                      style={{ width: '100%' }}
                      onClick={e => handleAddtoCart(e, data.key)}
                    >
                      ADD TO CART{' '}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow key='row3'>
                  <TableCell
                    colSpan={2}
                    style={{ height: '2px', padding: '0px' }}
                  >
                    <h2>
                      {data.qtySold} {data.name} already bought & delivered
                    </h2>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleCreateCart} color='primary'>
            Finish
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleDisplayCart = (e, cartID) => {
    const firestore = firebase.firestore()

    const fetchData = async () => {
      const data = await firestore.collection('Products').get()

      const listCart: any = []

      data.forEach(doc => {
        const { Name, Description, ImgUrl, Price } = doc.data()

        const shoppingCartRef = firebase
          .database()
          .ref('shoppingCarts/' + cartID + '/' + doc.id)
        shoppingCartRef.on('value', snapshot => {
          const data = snapshot.val()

          if (snapshot.exists()) {
            listCart.push({
              key: doc.id,
              name: Name,
              desc: Description,
              imgUrl: ImgUrl,
              price: Price,
              quantity: data.quantity,
              total: data.total,
            })

            setFormState({
              ...formState,
              displayCart: true!,
              cartItems: listCart,
              cartID: cartID,
            })

            // Code for adding grandtotal to cart record
          } else {
            console.log('No data available')
            // this.setState({
            //   emptyCart: true
            // })
          }
        })
      })
    }

    setFormState({
      ...formState,
      displayCart: true!,
    })
    fetchData()
  }

  const displayCartDialog = () => {
    const handleCloseDisplayCart = () => {
      setFormState({
        ...formState,
        displayCart: false!,
        cartItems: [],
        cartID: '',
      })
    }

    return (
      <Dialog
        open={formState.displayCart}
        onClose={handleCloseDisplayCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          CartID: {formState.cartID}
        </DialogTitle>

        <DialogContent>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow
                style={{
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#d3d3d3',
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Price</h2>
                  </b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Total</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formState.cartItems.map(data => (
                <TableRow key='row1'>
                  <TableCell>{data.desc}</TableCell>
                  <TableCell>{data.price}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>
                    <b>{data.total}</b>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisplayCart} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      {loader()}
      {displayCartDialog()}
      {DialogCartAssigner()}
      {DialogCart()}
      <Card className={classes.box}>
        <CardContent>
          <Button color='primary' variant='contained' onClick={handleClickOpen}>
            Add Cart
          </Button>
          <div className={classes.row}>
            <h1 className={classes.h1}>Carts {formState.table.length}</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Cart ID</b>
                  </TableCell>
                  <TableCell>
                    <b>User ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>LeaderID</b>
                  </TableCell>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Link</b>
                  </TableCell>
                  {/* CreatedAt */}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>
                          <Button
                            onClick={e => handleDisplayCart(e, data.CartID)}
                          >
                            {data.CartID}
                          </Button>
                        </TableCell>
                        <TableCell>{data.CustomerID}</TableCell>
                        <TableCell>{data.Name}</TableCell>
                        <TableCell>{data.LeaderID}</TableCell>
                        <TableCell>{data.CreatedAt}</TableCell>
                        <TableCell>
                          <a href={data.Link} target='_blank'>
                            {data.Link}
                          </a>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={7}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default ProductTable
