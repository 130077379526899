import Card from '@material-ui/core/Card'
import { CardContent, MenuItem } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { CSVLink } from 'react-csv'
import usePlacesAutocomplete from 'use-places-autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import SearchBar from 'material-ui-search-bar'
import { Auth } from '../../App'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  displaySendOut: boolean
  sendComplete: boolean
  commissionArray: any[]
  bonusArray: any[]
  refresh: number
  totalCommissions: number
  processed: number
  unProcessed: number
  open: boolean
  starttime: string
  endtime: string
  req: object
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const CommissionTable: React.FC<IProps> = () => {
  const classes = useStyles()

  const week = new Date()

  week.setDate(week.getDate() - 7)

  const weekdate =
    week.getFullYear() +
    '-' +
    (week.getMonth() + 1 > 9
      ? week.getMonth() + 1
      : '0' + (week.getMonth() + 1)) +
    '-' +
    (week.getDate() > 9 ? week.getDate() : '0' + week.getDate()) +
    ' T 00:00'

  const [isLoading, setIsloading] = useState<boolean>(true)
  const [searched, setSearched] = useState<any>('')
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    displaySendOut: false,
    sendComplete: false,
    commissionArray: [],
    bonusArray: [],
    refresh: 0,
    totalCommissions: 0,
    processed: 0,
    unProcessed: 0,
    open: false,
    starttime: '',
    endtime: '',
    req: {},
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()
  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchOrderData = async () => {
      setIsloading(true)

      let array: any = []

      const url =
        'https://api.tushop.io/api/v1/commissions/getAllCommissionsData'

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate: formState.starttime,
          endDate: formState.endtime,
        }),
      })
        .then((response: any) => {
          if (response.status === 200) {
            return response.json()
          } else if (response.status === 500) {
            setIsloading(false)
            alert(response.message)
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then((response: any) => {
          setFormState({
            ...formState,
            array: response.array,
            totalCommissions: response.total,
          })

          setIsloading(false)
        })
        .catch(error => {
          console.error(error)
        })
    }

    if (formState.starttime !== '' && formState.endtime !== '') {
      fetchOrderData()
    } else {
      setIsloading(false)
    }
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let nameLeaderSearch: any = row.name

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(nameLeaderSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }

  //header for csv

  const date = new Date()
  const today =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    ' T ' +
    (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
    ':' +
    (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const getCommissions = async (e, CLID, open) => {
    const CommissionList: any = []
    const BonusList: any = []

    const data = await firestore
      .collection('PayOuts')
      .where('LeaderID', '==', CLID)
      .where('DateCreated', '>=', formState.starttime)
      .where('DateCreated', '<', formState.endtime)
      .orderBy('DateCreated', 'asc')

    data
      .get()
      .then(data => {
        data.forEach(doc => {
          const {
            Bonus,
            POD,
            TotalCommission,
            TotalAmount,
            OrderID,
            CartID,
            DateCreated,
            Processed,
          } = doc.data()

          if (Bonus) {
            BonusList.push({
              key: doc.id,
              TotalCommission,
              TotalAmount,
              OrderID,
              CartID,
              DateCreated,
              Processed,
            })
          } else {
            CommissionList.push({
              key: doc.id,
              TotalCommission,
              TotalAmount,
              OrderID,
              CartID,
              DateCreated,
              Processed,
            })
          }
        })
      })
      .then(() => {
        setFormState({
          ...formState,
          bonusArray: BonusList,
          commissionArray: CommissionList,
          open: true,
        })
      })
  }

  const sendOutCommissions = () => {
    const handleClose = e => {
      setFormState({
        ...formState,
        displaySendOut: false!,
      })
    }

    return (
      <Dialog
        open={formState.displaySendOut}
        onClose={e => {
          handleClose(e)
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Sending out commissions between {formState.starttime} -{' '}
          {formState.endtime}
        </DialogTitle>
        <DialogContent>
          {formState.sendComplete ? (
            <div style={{ textAlign: 'center' }}>
              <h2>
                <p style={{ color: 'green' }}>SUCCESS</p>
              </h2>
              <p>All payouts have been sent out for records between:</p>
              <p>
                {formState.starttime} - {formState.endtime}
              </p>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
              <p>Sending Payouts... please wait</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={e => {
              handleClose(e)
            }}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const commissions = () => {
    const handleClose = e => {
      setFormState({
        ...formState,
        open: false!,
        bonusArray: [],
        commissionArray: [],
      })
    }

    return (
      <Dialog
        fullScreen={true}
        open={formState.open}
        onClose={e => {
          handleClose(e)
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <h1>
            Commissions between {formState.starttime} - {formState.endtime}{' '}
          </h1>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
              <h3>Commissions : {formState.commissionArray.length}</h3>
              <br />
              {/* <Button color="primary" variant="contained" className={classes.refresh}>
                <CSVLink onClick={handleRefresh} {...csvReport}>Export to CSV</CSVLink>
              </Button> */}
              <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className={classes.tr}>
                      <TableCell>
                        <b>Pay Out ID</b>
                      </TableCell>
                      <TableCell>
                        <b>Order ID</b>
                      </TableCell>
                      <TableCell>
                        <b>Cart ID</b>
                      </TableCell>
                      <TableCell>
                        <b>Order Amount</b>
                      </TableCell>
                      <TableCell>
                        <b>Commission</b>
                      </TableCell>
                      <TableCell>
                        <b>Processed?</b>
                      </TableCell>
                      <TableCell>
                        <b>Date</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formState.commissionArray.map(data => (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.OrderID}</TableCell>
                        <TableCell>{data.CartID}</TableCell>
                        <TableCell>{data.TotalAmount}</TableCell>
                        <TableCell>{data.TotalCommission}</TableCell>
                        <TableCell>
                          {data.Processed ? (
                            <p style={{ color: 'green' }}>True</p>
                          ) : (
                            <p style={{ color: 'red' }}>False</p>
                          )}
                        </TableCell>
                        <TableCell>{data.DateCreated}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <span className={classes.spacer} />
            <div>
              <h3>Bonus : {formState.bonusArray.length}</h3>
              {/* <Button color="primary" variant="contained" className={classes.refresh}>
                <CSVLink onClick={handleRefresh} {...csvReport}>Export to CSV</CSVLink>
              </Button> */}
              <br />
              <TableContainer component={Paper} style={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className={classes.tr}>
                      <TableCell>
                        <b>Pay Out ID</b>
                      </TableCell>
                      <TableCell>
                        <b>Order ID</b>
                      </TableCell>
                      <TableCell>
                        <b>Cart ID</b>
                      </TableCell>
                      <TableCell>
                        <b>Order Amount</b>
                      </TableCell>
                      <TableCell>
                        <b>Commission</b>
                      </TableCell>
                      <TableCell>
                        <b>Processed?</b>
                      </TableCell>
                      <TableCell>
                        <b>Date</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formState.bonusArray.map(data => (
                      <TableRow key={data.key}>
                        <TableCell>{data.key}</TableCell>
                        <TableCell>{data.OrderID}</TableCell>
                        <TableCell>{data.CartID}</TableCell>
                        <TableCell>{data.TotalAmount}</TableCell>
                        <TableCell>{data.TotalCommission}</TableCell>
                        <TableCell>
                          {data.Processed ? (
                            <p style={{ color: 'green' }}>True</p>
                          ) : (
                            <p style={{ color: 'red' }}>False</p>
                          )}
                        </TableCell>
                        <TableCell>{data.DateCreated}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={e => {
              handleClose(e)
            }}
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'starttime') {
      // setFormState({
      //   ...formState,
      //   starttime: e.target.value
      // })

      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      setFormState({
        ...formState,
        starttime: today,
      })

      console.log('OYAA', today)
    } else if (e.target.name === 'endtime') {
      const date = new Date(e.target.value)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T ' +
        (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' +
        (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

      setFormState({
        ...formState,
        endtime: today,
      })
    }
  }

  const handleFilterDate = e => {
    if (formState.starttime === '') {
      alert('ENTER START DATE')
    } else if (formState.endtime === '') {
      alert('ENTER END DATE')
    } else {
      setFormState({
        ...formState,
        refresh: formState.refresh + 1,
      })
    }
  }

  const handleDisplaySendOut = () => {
    setFormState({
      ...formState,
      displaySendOut: true,
    })

    if (formState.req !== {}) {
      const url = 'https://api.tushop.io/api/v1/commissions/sendOutCommissions'

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formState.req),
      })
        .then((response: any) => {
          if (response.status === 200) {
            return response.json()
          } else if (response.status === 500) {
            setIsloading(false)
            alert(response.message)
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then((response: any) => {
          setFormState({
            ...formState,
            sendComplete: true,
          })

          setIsloading(false)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const headers: any = [
    { label: 'LedgerID', key: 'LeaderID' },
    { label: 'Leader Name', key: 'LeaderName' },
    { label: 'Total Commissions', key: 'Amount' },
    { label: 'Processed Commissions', key: 'pCommissions' },
    { label: 'Unprocessed Commissions', key: 'upCommissions' },
  ]

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename:
      'AllCommissions(' +
      formState.starttime +
      ' - ' +
      formState.endtime +
      ').csv',
  }

  const { authUser } = Auth.useContainer()

  const userEmail = authUser?.email

  return (
    <div className={classes.root}>
      {commissions()}
      {sendOutCommissions()}
      <Card className={classes.box}>
        <CardContent>
          <h1>All Commissions</h1>
          <div className={classes.row}>
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="starttime"
              label="Start Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <TextField
              id="datetime-local"
              onChange={handleChange}
              name="endtime"
              label="End Date/Time"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              onClick={e => handleFilterDate(e)}
              className={classes.refresh}
            >
              FILTER
            </Button>
          </div>
          <br />
          <div className={classes.row}>
            <h1>Total: KES {formState.totalCommissions}</h1>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="contained"
              className={classes.refresh}
            >
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>
          <br />
          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />
          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Leader ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Leader Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Total</b>
                  </TableCell>
                  <TableCell>
                    <b>Processed</b>
                  </TableCell>
                  <TableCell>
                    <b>Unprocessed</b>
                  </TableCell>
                  <TableCell>
                    <b>Commissions & Bonuses</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.LeaderID}>
                        <TableCell>{data.LeaderID}</TableCell>
                        <TableCell>{data.LeaderName}</TableCell>
                        <TableCell>{data.Amount}</TableCell>
                        <TableCell>{data.pCommissions}</TableCell>
                        <TableCell>{data.upCommissions}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={e =>
                              getCommissions(e, data.LeaderID, true)
                            }
                          >
                            Commissions & Bonuses
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default CommissionTable
