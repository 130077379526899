import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Auth } from '../../App'
import DashboardTable from './DashboardTable'
const useStyles = makeStyles(() => ({
  root: {
    padding: '32px',
  },
}))

const Dashboard: React.FC<RouteComponentProps> = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {/* <Grid container spacing={4}></Grid> */}
      <DashboardTable />
    </div>
  )
}
export default Dashboard
