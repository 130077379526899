import { Divider, Drawer } from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import DashboardIcon from '@material-ui/icons/Dashboard'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import PeopleIcon from '@material-ui/icons/People'
import ShareIcon from '@material-ui/icons/Share'
import SettingsIcon from '@material-ui/icons/Settings'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import BarChartIcon from '@material-ui/icons/BarChart'
import ViewListIcon from '@material-ui/icons/ViewList'
import FeedbackIcon from '@material-ui/icons/Feedback'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import BorderAllIcon from '@material-ui/icons/BorderAll'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import PaymentIcon from '@material-ui/icons/Payment'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import SmsIcon from '@material-ui/icons/Sms'
import LogsIcon from '@material-ui/icons/Toc'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { PATHS } from '../../../paths'
import { ITheme } from '../../../theme'
import Profile from './Profile'
import SidebarNav from './SidebarNav'
import { Auth } from '../../../App'

interface IProps {
  className?: string
  onClose: () => void
  open: boolean
  variant: 'permanent' | 'persistent' | 'temporary'
}

const useStyles = makeStyles((theme: ITheme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar: React.FC<IProps> = ({
  open,
  variant,
  onClose,
  className,
  ...rest
}) => {
  const classes = useStyles()

  const { authUser } = Auth.useContainer()
  const userEmail: any = authUser?.email

  const legitimateArrays = [
    'rytone.tushop@gmail.com',
    'josh@tushop.io',
    'cathy@tushop.io',
    'abdallah@tushop.io',
  ]
  const isLegitimate = legitimateArrays.includes(userEmail)
  const pages = [
    {
      title: 'Dashboard',
      href: PATHS.DASHBOARD,
      icon: <DashboardIcon />,
    },
    {
      title: 'Users',
      href: PATHS.USERS,
      icon: <PeopleIcon />,
    },
    {
      title: 'New Active Users',
      href: PATHS.NEWACTIVE,
      icon: <PeopleIcon />,
    },
    {
      title: 'Community Leader',
      href: PATHS.COMMUNITYLEADERS,
      icon: <SupervisedUserCircleIcon />,
    },
    {
      title: 'Farmers',
      href: PATHS.FARMERS,
      icon: <SupervisedUserCircleIcon />,
    },
    {
      title: 'Bid Request',
      href: PATHS.BIDREQUEST,
      icon: <ViewListIcon />,
    },
    {
      title: 'Bundles',
      href: PATHS.BUNDLES,
      icon: <BorderAllIcon />,
    },
    {
      title: 'Products',
      href: PATHS.PRODUCTS,
      icon: <ShoppingBasketIcon />,
    },
    {
      title: 'Stock-Control',
      href: PATHS.STOCKCONTROL,
      icon: <ViewListIcon />,
    },
    {
      title: 'Sourcing List',
      href: PATHS.SOURCINGLIST,
      icon: <ShoppingBasketIcon />,
    },
    {
      title: 'Pending Orders',
      href: PATHS.ORDERS,
      icon: <HourglassEmptyIcon />,
    },
    {
      title: 'Completed Orders',
      href: PATHS.COMPLETEDORDERS,
      icon: <AssignmentTurnedInIcon />,
    },
    {
      title: 'Cancelled Orders',
      href: PATHS.CANCELLEDORDERS,
      icon: <CloseIcon />,
    },
    {
      title: 'Group Buy',
      href: PATHS.GROUPBUY,
      icon: <GroupAddIcon />,
    },
    {
      title: 'Group Buy Queue',
      href: PATHS.GROUPBUYQUEUE,
      icon: <GroupAddIcon />,
    },
    {
      title: 'Ledger',
      href: PATHS.LEDGER,
      icon: <ViewListIcon />,
    },
    {
      title: 'All Ledgers',
      href: PATHS.ALLLEDGER,
      icon: <ViewListIcon />,
    },
    {
      title: 'Default Ledgers',
      href: PATHS.DEFAULTLEDGER,
      icon: <ViewListIcon />,
    },
    {
      title: 'Commission',
      href: PATHS.COMMISSIONS,
      icon: <AttachMoneyIcon />,
    },
    {
      title: 'All Commissions',
      href: PATHS.ALLCOMMISSIONS,
      icon: <AttachMoneyIcon />,
    },
    {
      title: 'All Payouts',
      href: PATHS.ALLPAYOUTS,
      icon: <PaymentIcon />,
    },
    {
      title: 'Successful Transactions',
      href: PATHS.SUCCSESSFULTRANSACTIONS,
      icon: <CheckIcon />,
    },
    {
      title: 'Failed Transactions',
      href: PATHS.FAILEDTRANSACTIONS,
      icon: <CloseIcon />,
    },
    {
      title: 'All Transactions',
      href: PATHS.ALLTRANSACTIONS,
      icon: <CheckIcon />,
    },
    {
      title: 'Stock-outs',
      href: PATHS.STOCKOUTS,
      icon: <CloseIcon />,
    },
    {
      title: 'News',
      href: PATHS.NEWS,
      icon: <AccountBoxIcon />,
    },
    {
      title: 'Blogs',
      href: PATHS.BLOGS,
      icon: <AccountBoxIcon />,
    },
    {
      title: 'Popups',
      href: PATHS.POPUP,
      icon: <AccountBoxIcon />,
    },
    {
      title: 'Leader Board',
      href: PATHS.LEADERBOARD,
      icon: <BarChartIcon />,
    },
    {
      title: 'Processed Invoices',
      href: PATHS.PROCESSEDINVOICE,
      icon: <CheckIcon />,
    },
    {
      title: 'Unprocessed Invoices',
      href: PATHS.UNPROCESSEDINVOICE,
      icon: <CloseIcon />,
    },
    {
      title: 'Burst SMS',
      href: PATHS.BURSTSMS,
      icon: <SmsIcon />,
    },
    {
      title: 'Referrals',
      href: PATHS.REFERRALS,
      icon: <ShareIcon />,
    },
    {
      title: 'CL Referrals',
      href: PATHS.CLREFERRALS,
      icon: <ShareIcon />,
    },
    {
      title: 'Carts',
      href: PATHS.CART,
      icon: <AddShoppingCartIcon />,
    },
    {
      title: 'VAT',
      href: PATHS.VAT,
      icon: <LocalAtmIcon />,
    },
    {
      title: 'Feedback',
      href: PATHS.FEEDBACK,
      icon: <FeedbackIcon />,
    },
    {
      title: 'CLFeedback',
      href: PATHS.CLFEEDBACK,
      icon: <FeedbackIcon />,
    },
    {
      title: 'CLOFeedback',
      href: PATHS.CLOFEEDBACK,
      icon: <FeedbackIcon />,
    },
    {
      title: 'Settings',
      href: PATHS.SETTINGS,
      icon: <SettingsIcon />,
    },
  ]

  if (isLegitimate) {
    pages.push({
      title: 'Admin Logs',
      href: PATHS.ADMINLOGS,
      icon: <LogsIcon />,
    })
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

export default Sidebar
