import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Auth } from '../../App'
import Users from '../../firebase/firestore/User'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FirebaseApp from '../../firebase/firebase'
import { convertDate } from '../../../utils/date_convert'

interface IProps {
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {},
  imageEdit: {
    display: 'block',
    maxWidth: '100%',
  },
  button: {
    padding: '20px',
  },
  textarea: {
    width: '100%',
  },
}))

const NewsArticle: React.FC<IProps> = ({ className, ...rest }) => {
  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  const storage = firebase.storage()
  let image: File

  const { user, authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const classes = useStyles()
  const [pending, setPending] = useState(false)
  const [values, setValues] = useState({
    newsID: 'NEWS' + Math.floor(100000000 + Math.random() * 900000000),
    imgUrl: '',
    ArticleHeader: '',
    Article: '',
  })

  const handleChange = (e: any) => {
    if (e.target.id === 'header') {
      setValues({
        ...values,
        ArticleHeader: e.target.value,
      })
    } else if (e.target.id === 'article') {
      setValues({
        ...values,
        Article: e.target.value,
      })
    } else if (e.target.files[0]) {
      image = e.target.files[0]
    }
  }

  const handleUploadImage = () => {
    const Reference = storage.ref(`NewsImages/${values.newsID}/${image.name}`)

    Reference.put(image).then(snapshot => {
      Reference.getDownloadURL().then(url => {
        const urlString = url.toString()

        setValues({
          ...values,
          imgUrl: urlString,
        })
      })
    })
  }

  const handleSave = async () => {
    const date = new Date()

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
      ':' +
      (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

    if (values.ArticleHeader === '') {
      alert('Please enter header')
    } else if (values.Article === '') {
      alert('Please enter article description')
    } else if (values.imgUrl === '') {
      alert('Please upload image')
    } else {
      firestore
        .collection('News')
        .doc(values.newsID)
        .set({
          Header: values.ArticleHeader,
          Article: values.Article,
          ImgUrl: values.imgUrl,
          Date: today,
        })
        .then(() => {
          const logRef =
            'LOG' + Math.floor(100000000 + Math.random() * 900000000)
          firestore
            .collection('AdminLogs')
            .doc(logRef)
            .set({
              LogId: logRef,
              userEmail: userEmail,
              activity: 'Addeed a news record',
              date_created: convertDate(new Date()),
              type: 'Update',
              record: {
                Header: values.ArticleHeader,
                Article: values.Article,
                ImgUrl: values.imgUrl,
                Date: today,
              },
            })
            .catch(err => console.log(err.message))
        })
        .then(() => {
          alert('News uploaded')

          setValues({
            ...values,
            Article: '',
            ArticleHeader: '',
            imgUrl: '',
          })
        })
    }
  }

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <h2>News</h2>
          <p>Upload news article here</p>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete='off' noValidate>
          <CardContent>
            <TextField
              autoFocus
              margin='dense'
              id='header'
              label='Article Header'
              type='text'
              fullWidth
              onChange={handleChange}
              value={values.ArticleHeader}
            />
            <p>
              <b>Select Article Image:</b>
            </p>
            <input
              type='file'
              className={classes.button}
              onChange={handleChange}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={handleUploadImage}
            >
              Upload Image
            </Button>
            <br />
            {/* eslint-disable-next-line*/}
            <img src={values.imgUrl} className={classes.imageEdit} />
            <br />
            <TextareaAutosize
              aria-label='empty textarea'
              name='article'
              id='article'
              className={classes.textarea}
              rowsMin={5}
              onChange={handleChange}
              placeholder='Article'
              value={values.Article}
            />
          </CardContent>
          <Divider />
          <CardActions>
            <Button color='primary' variant='contained' onClick={handleSave}>
              Upload News
            </Button>
          </CardActions>
        </form>
      </Card>
    </>
  )
}

export default NewsArticle
