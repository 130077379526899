import { makeStyles } from '@material-ui/styles'
import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { ITheme } from '../../theme'
import AdminLogTable from './AdminLogTable'

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(-3),
  },
}))

const AdminLogsList: React.FC<RouteComponentProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AdminLogTable />
      </div>
    </div>
  )
}

export default AdminLogsList
