import {
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/styles'
import {
  Link as RouterLink,
  navigate,
  Redirect,
  RouteComponentProps,
} from '@reach/router'
import firebase from 'firebase'
import { History } from 'history'
import React, { useEffect, useState } from 'react'
import validate from 'validate.js'
import { Auth } from '../../App'
import useSignInWith from '../../hooks/useSignIn'
import { ITheme } from '../../theme'
import {
  Facebook as FacebookIcon,
  Google as GoogleIcon,
} from '../../theme/icons'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

interface IOwnProps {
  className?: string
}

type IProps = IOwnProps & RouteComponentProps

declare global {
  interface Window {
    browserHistory: History
  }
}

export interface IFormState {
  isValid: boolean
  values: IFormProps
  touched: IFormProps
  errors: IFormProps
}
export interface IFormProps {
  [key: string]: boolean | string | undefined
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/tushop.png)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  person: {},
}))

const SignIn: React.FC<IProps> = () => {
  const history = window.browserHistory
  const { user, pending } = Auth.useContainer()
  const classes = useStyles()
  const signInWith = useSignInWith()
  const [formState, setFormState] = useState<IFormState>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values])

  if (pending) {
    return <LoadingSpinner />
  }

  // if (user) {
  //   return <Redirect from='' to='/' noThrow />
  // }

  const handleBack = () => {
    history.back()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    })
  }

  const handleSignIn = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    try {
      const emailToUse = formState.values.email
      if (
        emailToUse === 'ledgers@tushop.io' ||
        emailToUse === 'logistics@tushop.io'
      ) {
        console.log('----------> email should not be here')
        alert('Email provided does not exist')
        navigate('/sign-in')
      } else {
        console.log('----------> email is valid')
        await firebase
          .auth()
          .signInWithEmailAndPassword(
            String(formState.values['email']),
            String(formState.values['password'])
          )
        navigate('/')
      }
    } catch (error) {
      console.log(error.message)
      alert(error.message)
    }
  }

  const hasError = (field: string) =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant='h1'>
                Tushop Admin
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant='h2'>
                  Sign in
                </Typography>

                <Typography
                  align='center'
                  className={classes.sugestion}
                  color='textSecondary'
                  variant='body1'
                >
                  login with email address
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={hasError('email') ? formState.errors.email : null}
                  label='Email address'
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password : null
                  }
                  label='Password'
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <Button
                  className={classes.signInButton}
                  color='primary'
                  disabled={!formState.isValid}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Sign in now
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignIn
