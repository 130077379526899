import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ITheme } from '../../theme'
import FirebaseApp from '../../firebase/firebase'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../../../utils/Pagination'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { prettyPrintJson } from 'pretty-print-json'
import SearchBar from 'material-ui-search-bar'
import { JsonTable } from 'react-json-to-html'

interface IProps {
  className?: string
}

export interface IFormState {
  array: any[]
  refresh: number
  open: boolean
  openLogs: boolean
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  box: {
    padding: '16px',
    justifyContent: 'center',
  },
  tr: {
    background: '#D3D3D3',
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginRight: theme.spacing(1),
  },
  pager: {
    width: '100%',
    marginTop: theme.spacing(3),
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
}))

const AdminLogTable: React.FC<IProps> = () => {
  const classes = useStyles()
  const [searched, setSearched] = useState<any>('')
  const [isLoading, setIsloading] = useState<boolean>(true)
  const [record, setRecord] = useState<any>({})
  const [formState, setFormState] = useState<IFormState>({
    array: [],
    refresh: 0,
    open: false,
    openLogs: false,
  })

  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  //handle paginaion events

  const [page, setPage] = useState<number>(1)
  const PER_PAGE = 300

  const count = Math.ceil(formState.array.length / PER_PAGE)
  const _DATA = usePagination(formState.array, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  //end of handling page pagination

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true)
      const data = await firestore
        .collection('AdminLogs')
        .orderBy('date_created', 'desc')
        .get()

      const list: any = []

      data.forEach(doc => {
        const { activity, type, date_created, userEmail, record } = doc.data()
        list.push({
          key: doc.id,
          Type: type,
          Activity: activity,
          User: userEmail,
          Timestamp: date_created,
          Record: record,
        })
      })

      setFormState({
        ...formState,
        array: list,
      })
      setIsloading(false)
    }
    fetchData()
  }, [formState.refresh])

  //filter search request
  const requestSearch = (searchedVal: any) => {
    if (searchedVal !== '') {
      const filteredRows = formState.array.filter(row => {
        let keySearch: any = row.key
        let typeSearch: any = row.Type
        let activitySearch: any = row.Activity
        let userSearch: any = row.User
        let dateSearch: any = row.Timestamp

        let unkown: any
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(typeSearch) ||
          expression.test(activitySearch) ||
          expression.test(userSearch) ||
          expression.test(dateSearch) ||
          expression.test(unkown)
        )
      })
      setFormState({ ...formState, array: filteredRows })
    } else {
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setFormState({ ...formState, refresh: formState.refresh + 1 })
    }
  }
  const handleRefresh = () => {
    setFormState({
      ...formState,
      refresh: formState.refresh + 1,
    })
  }

  const handleDisplayRecord = id => {
    setRecord(formState.array.find(log => log.key === id))
    setFormState({ ...formState, openLogs: true })
  }
  const displayLogRecord = () => {
    const handleClose = () => {
      setFormState({
        ...formState,
        openLogs: false,
      })
    }

    return (
      <Dialog
        open={formState.openLogs}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <h1>Record Details</h1>
        </DialogTitle>
        <DialogContent>
          <JsonTable json={record.Record} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        {displayLogRecord()}
        <CardContent>
          <div className={classes.row}>
            <h1 className={classes.h1}>Log records</h1>
            <span className={classes.spacer} />
            <Button
              color='primary'
              variant='contained'
              onClick={handleRefresh}
              className={classes.refresh}
            >
              <RefreshIcon />
            </Button>
          </div>

          <SearchBar
            value={searched}
            onChange={searchVal => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{ marginBottom: '2em', marginTop: '1em' }}
          />

          <TableContainer component={Paper} style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow className={classes.tr}>
                  <TableCell>
                    <b>Log ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Activity</b>
                  </TableCell>
                  <TableCell>
                    <b>Type</b>
                  </TableCell>
                  <TableCell>
                    <b>User</b>
                  </TableCell>
                  <TableCell>
                    <b>Timestamp</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading === false ? (
                  _DATA.currentData().map(data => {
                    return (
                      <TableRow key={data.key}>
                        <TableCell>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={e => {
                              handleDisplayRecord(data.key)
                            }}
                          >
                            {data.key}
                          </Button>
                        </TableCell>
                        <TableCell>{data.Activity}</TableCell>
                        <TableCell>{data.Type}</TableCell>
                        <TableCell>{data.User}</TableCell>
                        <TableCell>{data.Timestamp}</TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      style={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={classes.pager}
            count={count}
            size='large'
            page={page}
            variant='outlined'
            shape='rounded'
            onChange={handlePageClickPagination}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default AdminLogTable
