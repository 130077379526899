import Card from '@material-ui/core/Card';
import { CardContent } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ITheme } from '../../theme';
import FirebaseApp from '../../firebase/firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CSVLink } from "react-csv";

interface IProps {
  className?: string;
}

export interface IFormState {
  array: any[];
  exportArray: any[];
  refresh: number;
  cartID: string;
  totalItems: number;
  cartItems: any[];
  arrayReferrer: any[];
  displayCart: boolean;
  displayReferrer: boolean;
  grandTotal: string;
  leaderIDSearch: string;
  displayNote: boolean;
  holdNote: string;
  ledgerID: string;
  starttime: string;
  endtime: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    padding: theme.spacing(4)
  },
  spacer: {
    flexGrow: 1
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  box: {
    padding: "16px",
    justifyContent: 'center',  
  },
  tr:{
    background: "#D3D3D3",
  },
  h1: {
    textAlign: 'center',
  },
  refresh: {
    marginLeft: "auto",
  },
  textarea: {
    width: "100%",
  }
  
}));

const OrdersTable: React.FC<IProps> = () => {
  const classes = useStyles();

  const [formState, setFormState] = useState<IFormState>({
    array: [{"Description": "Hello"}],
    exportArray: [],
    refresh: 0,
    totalItems: 0,
    cartID: "",
    cartItems: [],
    arrayReferrer: [],
    displayCart: false,
    displayReferrer: false,
    displayNote: false,
    grandTotal: "",
    leaderIDSearch: "",
    holdNote: "",
    ledgerID: "",
    starttime: "",
    endtime: ""
  });

  const firebase = FirebaseApp;

  const firestore = firebase.firestore();

  useEffect(() => {

    let carts : any = []
    let cartList : any = []        

    const fetchData = () => {


      firestore.collection("Orders").where("Delivered", "==", false)
                                    .where("Hold", "==", false)
                                    .where("Cancelled", "==", false)
                                    .where("DateOfOrder", ">", formState.starttime)
                                    .where("DateOfOrder", "<=", formState.endtime)
                                    .orderBy("DateOfOrder", "asc")
                                    .get().then((data) => {

        data.forEach((doc: any) => {
          const { CartID } = doc.data();
  
          carts.push(CartID)

          console.log("Order ID: ", doc.id)
      
        });


      }).then(() => {

        let count = 0

        console.log("LENGTH: ", carts.length)
        carts.forEach((CartID, index) => {

          const shoppingCartRef = firebase.database().ref('shoppingCarts/' + CartID);
          shoppingCartRef.on('value', (snapshot) => {

            if (snapshot.exists()) {

              snapshot.forEach(function(childSnapshot: any) {

                if(childSnapshot.key !== "grandTotal"){
                  const data = childSnapshot.val();

                  ++count

                  console.log("Count", count)

                  if(cartList[childSnapshot.key] === undefined){
                    cartList[childSnapshot.key] = parseInt(data.quantity)
                  }else{
                    cartList[childSnapshot.key] = cartList[childSnapshot.key] + parseInt(data.quantity)
                  }

                }

              })

              // Code for adding grandtotal to cart record

            } else {
              console.log("No data available");
              // this.setState({
              //   emptyCart: true
              // })
            }

            if(index === carts.length - 1){

              fetchSourcingList()

            }
          
          });
        })
      })

    }

    const fetchSourcingList = () => {

      const list : any = []

      for(var key in cartList) {
        console.log("KEY!", key)
        const quantity = cartList[key]

        firestore.collection("Products").doc(key).get().then((doc : any) => {
          const { Description } = doc.data()

          list.push({
            ProductID: key,
            Description: Description,
            Quantity: quantity
          })

        }).then(() => {
          const sorter = (a, b) => {
            return b.Quantity - a.Quantity;
         };
         const sortByQty = arr => {
            arr.sort(sorter);
         };

         sortByQty(list)
        }).then(() => {
          setFormState({
            ...formState,
            array: list
          })
        })
      }

      console.log("LIST! ", cartList)

    }

    // const getSourcingList = (cartList) => {


    // }
    fetchData()

  }, [formState.refresh]);

  const handleRefresh = () => {

    console.log("ARRRAAYYYYY")

    console.log(formState.exportArray)
    setFormState({
      ...formState,
      // totalItems: 0,
      refresh: formState.refresh+1
    })
  }

  const date = new Date();
  const today = date.getFullYear()+
                '-'+
                ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
                '-'+
                (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
                ' T '+
                (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
                ":"+ 
                (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

  const headers = [
    { label: "ProductID", key: "ProductID" },
    { label: "Description", key: "Description" },
    { label: "Quantity", key: "Quantity" }
  ];

  const csvReport = {
    data: formState.array,
    headers: headers,
    filename: 'SourcingList('+ today + ').csv'
  };

  const handleChange = (e : any) => {

    if(e.target.name === "starttime"){

      const date = new Date(e.target.value);

      const today = date.getFullYear()+
        '-'+
        ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
        '-'+
        (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
        ' T '+
        (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
        ":"+ 
        (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

      setFormState({
        ...formState,
        starttime: today
      })


      console.log("OYAA", today)
    }else if(e.target.name === "endtime"){

      const date = new Date(e.target.value);

      const today = date.getFullYear()+
        '-'+
        ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
        '-'+
        (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
        ' T '+
        (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
        ":"+ 
        (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

      setFormState({
        ...formState,
        endtime: today
      })

    }
  }

  const handleFilterDate = (e) => {
    if(formState.starttime === ""){
      alert("ENTER START DATE")
    }else if(formState.endtime === ""){
      alert("ENTER END DATE")
    }else{
      setFormState({
        ...formState,
        refresh: formState.refresh + 1
      })
    }
  }

  return (
      <div className={classes.root}>
        <Card className={classes.box}>
          <CardContent>
            <div className={classes.row}>
              <h1 className={classes.h1}>Sourcing List</h1>
            </div>
            <div className={classes.row}>
              <TextField
                  id="datetime-local"
                  onChange={handleChange}
                  name="starttime"
                  label="Start Date/Time"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
              <span className={classes.spacer} />
              <TextField
                  id="datetime-local"
                  onChange={handleChange}
                  name="endtime"
                  label="End Date/Time"
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
              />
              <span className={classes.spacer} />
              <Button color="primary" variant="contained" onClick={e => handleFilterDate(e)} className={classes.refresh}>FILTER</Button>
            </div> 
            <div className={classes.row}>
              <Button color="primary" variant="contained" className={classes.refresh}>
                <CSVLink {...csvReport}>Export to CSV</CSVLink>
              </Button>
              <span className={classes.spacer} />
              <Button color="primary" variant="contained" onClick={handleRefresh} className={classes.refresh}>
                <RefreshIcon/>
              </Button>
            </div>
            <TableContainer component={Paper} style={{maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.tr}>
                    <TableCell><b>Description</b></TableCell>
                    <TableCell><b>Quantity</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {formState.array.map(data =>
                <TableRow key={data.key}>
                  <TableCell>{data.Description}</TableCell>
                  <TableCell>{data.Quantity}</TableCell>
                </TableRow>
                )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
  );
};

export default OrdersTable;
