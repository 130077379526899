import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Auth } from '../../App'
import Users from '../../firebase/firestore/User'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FirebaseApp from '../../firebase/firebase'
import { convertDate } from '../../../utils/date_convert'

interface IProps {
  className?: string
}

const useStyles = makeStyles(() => ({
  root: {},
  imageEdit: {
    display: 'block',
    maxWidth: '100%',
  },
  button: {
    padding: '20px',
  },
  textarea: {
    width: '100%',
  },
}))

const NewsArticle: React.FC<IProps> = ({ className, ...rest }) => {
  const firebase = FirebaseApp

  const firestore = firebase.firestore()

  const storage = firebase.storage()
  let image: File

  const { user, authUser } = Auth.useContainer()
  const userEmail = authUser?.email
  const classes = useStyles()
  const [pending, setPending] = useState(false)
  const [values, setValues] = useState({
    popupID: 'POP' + Math.floor(100000000 + Math.random() * 900000000),
    imgUrl: '',
  })

  const handleChange = (e: any) => {
    if (e.target.files[0]) {
      image = e.target.files[0]
    }
  }

  const handleUploadImage = () => {
    const Reference = storage.ref(`PopUpImages/${values.popupID}/${image.name}`)

    Reference.put(image).then(snapshot => {
      Reference.getDownloadURL().then(url => {
        const urlString = url.toString()

        setValues({
          ...values,
          imgUrl: urlString,
        })
      })
    })
  }

  const handleSave = async () => {
    const date = new Date()

    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
      ':' +
      (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

    if (values.imgUrl === '') {
      alert('Please upload image')
    } else {
      firestore
        .collection('PopUps')
        .doc(values.popupID)
        .set({
          ImgUrl: values.imgUrl,
          Date: today
        })
        .then(() => {
          const logRef =
            'LOG' + Math.floor(100000000 + Math.random() * 900000000)
          firestore
            .collection('AdminLogs')
            .doc(logRef)
            .set({
              LogId: logRef,
              userEmail: userEmail,
              activity: 'Addeed a popup record',
              date_created: convertDate(new Date()),
              type: 'Update',
              record: {
                ImgUrl: values.imgUrl
              },
            })
            .catch(err => console.log(err.message))
        })
        .then(() => {
          alert('Pop up uploaded')

          setValues({
            ...values,
            imgUrl: '',
            popupID: 'POP' + Math.floor(100000000 + Math.random() * 900000000)
          })
        })
    }
  }

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <h2>Pop Ups</h2>
          <p>Upload Popups to display on wednesdays and the end of the month here</p>
        </CardContent>
      </Card>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete='off' noValidate>
          <CardContent>
            <p>
              <b>Select Pop up Image:</b>
            </p>
            <input
              type='file'
              className={classes.button}
              onChange={handleChange}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={handleUploadImage}
            >
              Upload Image
            </Button>
            <br />
            {/* eslint-disable-next-line*/}
            <img src={values.imgUrl} className={classes.imageEdit} />
          </CardContent>
          <Divider />
          <CardActions>
            <Button color='primary' variant='contained' onClick={handleSave}>
              Upload Pop up
            </Button>
          </CardActions>
        </form>
      </Card>
    </>
  )
}

export default NewsArticle
