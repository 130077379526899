import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  MenuItem,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { getInitials } from '../../../utils'
import { Auth } from '../../App'
import { ITheme } from '../../theme'
import TextField from '@material-ui/core/TextField'
import sha1 from 'crypto-js/sha1'
import Base64 from 'crypto-js/enc-base64'
import FirebaseApp from '../../firebase/firebase'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

interface IProps {
  className?: string
}

export interface IFormState {
  transID: string
  response: string
  shortCode: string
  checked: boolean
}

const useStyles = makeStyles((theme: ITheme) => ({
  details: {
    display: 'flex'
  }
}))

const CLOW: React.FC<IProps> = ({ className, ...rest }) => {
  const classes = useStyles()

  const firebase = FirebaseApp
  const firestore = firebase.firestore()

  const [values, setValues] = useState<IFormState>({
    transID: "",
    response: "",
    shortCode: "",
    checked: false
  })

  useEffect(() => {


  }, [])

  const handleChange = (e: any) => {

    if (e.target.id === "id") {

      setValues({
        ...values,
        transID: e.target.value
      })
    } else if (e.target.name === "shortCode") {

      setValues({
        ...values,
        shortCode: e.target.value
      })
    }

  }

  const handleCheck = (e: any) => {

    if (values.transID === "") {

      alert("Please input M-Pesa Transaction ID")

    } else {

      firestore.collection("MPesaSearch").doc(values.transID).set({
        SendRequest: true,
        ShortCode: values.shortCode
      }).then(() => {

        const url = "https://api.tushop.io/api/v1/SendQuikkSearch/" + values.transID

        fetch(url,
          {
            method: 'GET',
          }
        )
          .then(response => {
            if (response.status === 201) {
              return response.json()
            } else {

              console.log("first")
            }
          })
          .then(response => {
            console.debug(response)

            checkForResponse(response.id)

          }).catch(error => {
            console.error(error)
          })

      })
    }

  }

  const checkForResponse = (id: any) => {

    const checker = setInterval(() => {

      if (!values.checked) {

        firestore.collection("MPesaSearch").doc(id).get().then((doc: any) => {
          if (doc.exists) {
            const { status, detail } = doc.data()

            if (status === "Success") {


              alert("Transaction exists")

              clearInterval(checker)


            } else if (status === "FAIL") {


              alert(detail)

              clearInterval(checker)

            }
          }
        })

      }


    }, 2000)


    return checker

  }

  return (
    <>
      <Card>
        <CardContent>
          <h1>VERIFY QUIKK TRANSACTIONS</h1>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <h2>Please enter M-PESA transaction ID to verify</h2>
          <TextField
            autoFocus
            margin="dense"
            id="id"
            label="M-PESA ID"
            type="text"
            fullWidth
            onChange={handleChange}
            value={values.transID}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            onClick={handleCheck}
            variant="contained"
            color="primary" >
            Check
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default CLOW
